import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';
import Service from '@/services';

import { SuccessModel } from '@/models/classes/success';
import { MyReportModel, ReportDetailModel, ReportModel, SummaryReportModel } from '@/models/classes/weekly_report';
import { OptionModel } from '@/models/classes/report/option';

const service = new Service.WeeklyReportService();

// Interface
interface WeeklyReportState {
  summary: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SummaryReportModel;
    total?: number;
  };
  reports: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ReportModel[];
    total?: number;
  };
  region: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: OptionModel[];
    total?: number;
  };
  employee: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: OptionModel[];
    total?: number;
  };
  duration: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: OptionModel[];
    total?: number;
  };
  detail: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ReportDetailModel;
  };

  submitAcknowledge: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };

  submitSummary: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };

  draft: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: MyReportModel;
  };
}

// Initialize State
const initialState: WeeklyReportState = {
  summary: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  reports: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  region: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  employee: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  duration: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  detail: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  submitAcknowledge: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  submitSummary: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  draft: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'WeeklyReportState',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(onFetchListOfSummarySalesWeeklyReport.pending, (state) => {
      state.summary.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchListOfSummarySalesWeeklyReport.fulfilled, (state, action) => {
      state.summary.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.summary.data = action.payload.data;
        state.summary.total = action.payload.total;
      }
    });
    builder.addCase(onFetchListOfSummarySalesWeeklyReport.rejected, (state, action) => {
      state.summary.network.code = ServiceStatus.failed;
      state.summary.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchMoreListOfSummarySalesWeeklyReport.pending, (state) => {});
    builder.addCase(onFetchMoreListOfSummarySalesWeeklyReport.fulfilled, (state, action) => {
      if (action.payload != null && state.summary.data) {
        state.summary.data.reports.push(...action.payload.data.reports);
        if (action.payload != null && action.payload.total) {
          state.summary.total = action.payload.total;
        }
      }
    });
    builder.addCase(onFetchMoreListOfSummarySalesWeeklyReport.rejected, (state, action) => {});

    builder.addCase(onFetchGetMySalesWeeklyReport.pending, (state) => {
      state.draft.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetMySalesWeeklyReport.fulfilled, (state, action) => {
      state.draft.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.draft.data = action.payload;
      }
    });
    builder.addCase(onFetchGetMySalesWeeklyReport.rejected, (state, action) => {
      state.draft.network.code = ServiceStatus.failed;
      state.draft.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchListOfMySalesWeeklyReport.pending, (state) => {
      state.reports.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchListOfMySalesWeeklyReport.fulfilled, (state, action) => {
      state.reports.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.reports.data = action.payload.data;
        state.reports.total = action.payload.total;
      }
    });
    builder.addCase(onFetchListOfMySalesWeeklyReport.rejected, (state, action) => {
      state.reports.network.code = ServiceStatus.failed;
      state.reports.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchMoreListOfMySalesWeeklyReport.pending, (state) => {});
    builder.addCase(onFetchMoreListOfMySalesWeeklyReport.fulfilled, (state, action) => {
      if (action.payload != null && state.reports.data) {
        state.reports.data.push(...action.payload.data);
        if (action.payload != null && action.payload.total) {
          state.reports.total = action.payload.total;
        }
      }
    });
    builder.addCase(onFetchMoreListOfMySalesWeeklyReport.rejected, (state, action) => {});

    builder.addCase(onFetchGetReportDetail.pending, (state) => {
      state.detail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetReportDetail.fulfilled, (state, action) => {
      state.detail.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.detail.data = action.payload;
      }
    });
    builder.addCase(onFetchGetReportDetail.rejected, (state, action) => {
      state.detail.network.code = ServiceStatus.failed;
      state.detail.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchRegionFilter.pending, (state) => {
      state.region.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchRegionFilter.fulfilled, (state, action) => {
      state.region.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.region.data = action.payload.data;
        state.region.total = action.payload.total;
      }
    });
    builder.addCase(onFetchRegionFilter.rejected, (state, action) => {
      state.region.network.code = ServiceStatus.failed;
      state.region.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchEmployeeFilter.pending, (state) => {
      state.employee.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchEmployeeFilter.fulfilled, (state, action) => {
      state.employee.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.employee.data = action.payload.data;
        state.employee.total = action.payload.total;
      }
    });
    builder.addCase(onFetchEmployeeFilter.rejected, (state, action) => {
      state.employee.network.code = ServiceStatus.failed;
      state.employee.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchDurationFilter.pending, (state) => {
      state.duration.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchDurationFilter.fulfilled, (state, action) => {
      state.duration.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.duration.data = action.payload.data;
        state.duration.total = action.payload.total;
      }
    });
    builder.addCase(onFetchDurationFilter.rejected, (state, action) => {
      state.duration.network.code = ServiceStatus.failed;
      state.duration.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchPostSubmitAcknowledge.pending, (state) => {
      state.submitAcknowledge.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchPostSubmitAcknowledge.fulfilled, (state, action) => {
      state.submitAcknowledge.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.submitAcknowledge.data = action.payload;
      }
    });
    builder.addCase(onFetchPostSubmitAcknowledge.rejected, (state, action) => {
      state.submitAcknowledge.network.code = ServiceStatus.failed;
      state.submitAcknowledge.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchPostSubmitSummary.pending, (state) => {
      state.submitSummary.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchPostSubmitSummary.fulfilled, (state, action) => {
      state.submitSummary.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.submitSummary.data = action.payload;
      }
    });
    builder.addCase(onFetchPostSubmitSummary.rejected, (state, action) => {
      state.submitSummary.network.code = ServiceStatus.failed;
      state.submitSummary.network.error = action.error.message || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchListOfSummarySalesWeeklyReport = createAsyncThunk('weekly_report/summary', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchListOfSummarySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchMoreListOfSummarySalesWeeklyReport = createAsyncThunk('weekly_report/summary/more', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchListOfSummarySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchGetMySalesWeeklyReport = createAsyncThunk('weekly_report/report/draft', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetMySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchGetReportDetail = createAsyncThunk('weekly_report/report/detail', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetReportDetail(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchRegionFilter = createAsyncThunk('weekly_report/summary/filter/region', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchRegionFilter(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchEmployeeFilter = createAsyncThunk('weekly_report/summary/filter/employee', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchEmployeeFilter(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchDurationFilter = createAsyncThunk('weekly_report/summary/filter/duration', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchDurationFilter(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchPostSubmitAcknowledge = createAsyncThunk('weekly_report/submit/acknowledge', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostSubmitAcknowledgeSalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchPostSubmitSummary = createAsyncThunk('weekly_report/submit/summary', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostSubmitSummarySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchListOfMySalesWeeklyReport = createAsyncThunk('weekly_report/my_reports', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchListOfMySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchMoreListOfMySalesWeeklyReport = createAsyncThunk('weekly_report/my_reports/more', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchListOfMySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchPostSubmitMySalesWeeklyReport = createAsyncThunk('weekly_report/my_reports/submit', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostSubmitMySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchPostSendMySalesWeeklyReport = createAsyncThunk('weekly_report/my_reports/send', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostSendMySalesWeeklyReport(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
