import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { NotificationModel } from '@/models/classes/notification';
import { UnreadModel } from '@/models/classes/notification';

const service = new Service.NotificationService();

// Interface
interface NotificationState {
  noftify: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: NotificationModel[];
    total?: number;
  };
  unread: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: UnreadModel;
  };
}

// Initialize State
const initialState: NotificationState = {
  noftify: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  unread: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'Notification',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch Notify
    builder.addCase(fetchNotification.pending, (state) => {
      state.noftify.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchNotification.fulfilled, (state, action) => {
      state.noftify.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.noftify.data = action.payload.data;
        state.noftify.total = action.payload.total;
      }
    });
    builder.addCase(fetchNotification.rejected, (state, action) => {
      state.noftify.network.code = ServiceStatus.failed;
      state.noftify.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch Unread
    builder.addCase(onFetchGetNotficaitonReadStatus.pending, (state) => {
      state.unread.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetNotficaitonReadStatus.fulfilled, (state, action) => {
      state.unread.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.unread.data = action.payload;
      }
    });
    builder.addCase(onFetchGetNotficaitonReadStatus.rejected, (state, action) => {
      state.unread.network.code = ServiceStatus.failed;
      state.unread.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const fetchNotification = createAsyncThunk('notification/fetch', async (_, thunkAPI) => {
  try {
    const apiData = await service.fetchNotification();
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetNotficaitonReadStatus = createAsyncThunk('notification/check/unread', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getNotficaitonReadStatus();
    return apiData;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onUpdateReadStatus = createAsyncThunk('notification/update/status', async (params: any = {}, thunkAPI) => {
  try {
    let apiSubmit = await service.postUpdateReadStatus(params);

    if (apiSubmit) {
      return { apiSubmit };
    } else {
      return thunkAPI.rejectWithValue('Cannot store data.');
    }

    // return thunkAPI.rejectWithValue("params is undefined");
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
