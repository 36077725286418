import { Expose } from 'class-transformer';

export class ProfileModel {
  constructor() {
    this.contactId = 0;
    this.defaultRegionId = 0;
    this.employeeId = '';
    this.gender = '';
    this.prefix = '';
    this.firstName = '';
    this.lastName = '';
    this.position = '';
  }

  contactId: number;
  defaultRegionId: number;
  employeeId: string;

  @Expose({ name: 'genderCode' })
  gender: string;

  @Expose({ name: 'prefixCode' })
  prefix: string;

  @Expose({ name: 'localFirstName' })
  firstName: string;

  @Expose({ name: 'localLastName' })
  lastName: string;

  @Expose({ name: 'postNameThai' })
  position: string;

  @Expose({ name: 'fullName' })
  get fullName() {
    return this.prefix + this.firstName + ' ' + this.lastName;
  }
}
