import { LEAGUE_TYPE } from '@/stores/slices/league';
import axios from 'axios';

interface ParamsType {
  year?: string | number;
  month?: string | number;
  viewMode?: LEAGUE_TYPE;
}

export class LeagueAPI {
  getIncensive = async (params: ParamsType) => {
    let path = `api/User/GetIncentive?year=${params?.year}&month=${params?.month}&viewMode=${params?.viewMode}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
}
