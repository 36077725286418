export class MarketShareAcnModel {
  constructor() {
    // this.classic = 0;
    // this.coldbrew = 0;
    // this.leo = 0;
    // this.singha = 0;
    // this.other = 0;
    this.name = "";
    this.value = 0;
    this.color = "";
  }

  // classic: number;
  // coldbrew: number;
  // leo: number;
  // singha: number;
  // other: number;
  name: string;
  value: number;
  color: string;
}
