import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services/reports_power_bi';
const service = new Service();

export interface ReportsState {
  status: {
    code: ServiceStatus;
    error?: string;
  };
  value: number;
  data: {
    link: string;
  };
}

const initialState: any = {
  status: {
    code: ServiceStatus.idle,
    error: undefined,
  },
  value: 0,
  data: {
    link: '',
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  //basic state change
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    builder.addCase(onFechPowerBI.pending, (state, action) => {
      state.status.code = ServiceStatus.loading;
    });
    builder.addCase(onFechPowerBI.rejected, (state, action) => {
      state.status.error = action.error.message;
      state.status.code = ServiceStatus.failed;
    });
    builder.addCase(onFechPowerBI.fulfilled, (state, action) => {
      state.data.link = action.payload.apiData;
      state.status.code = ServiceStatus.succeeded;
    });
  },
});

export const onFechPowerBI = createAsyncThunk('report/power_bi', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getPowerBILink();
    return { apiData };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const { patch, reset } = reportsSlice.actions;

export default reportsSlice.reducer;
