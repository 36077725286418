import { formatThaiMonthOnly, getPreviousMonth } from '@/utils/date';

import { AnyMxRecord } from 'dns';

import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { ExpandListTwoColumnModel } from '@/models/classes/report/expand_list_two_column';
import { ExpandListThreeColumnModel } from '@/models/classes/report/expand_list_three_column';
import { ResponseModel } from '@/models/classes/response';
import { SalesPerformanceModel } from '@/models/classes/overview/sales_performance';
import { ReportDashboardSaleApi } from '../api/report/dashboard_sale';

const api = new ReportDashboardSaleApi();

//Services
export class ReportDashboardSaleService {
  getSalePerformance = async (type: string | any, searchId: number | any, regionId: number | AnyMxRecord) => {
    const resData: any = await api.getSalePerformance(type, searchId, regionId);
    const resModel = plainToClassFromExist(new ResponseModel<SalesPerformanceModel>(SalesPerformanceModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  getDashboardSaleReceiveMoney = async (params: any = {}) => {
    try {
      const resp: any = await api.getDashboardSaleReceiveMoney(params);

      if (resp !== undefined) {
        const currMonth = formatThaiMonthOnly(new Date());
        const title = {
          name: 'ยอดขาย',
          unit: '',
          month: currMonth,
          left: {
            name: 'ขายรับเงิน',
            unit: '',
            legend: 'ขายรับเงิน',
          },
          right: {
            name: 'ขายขน',
            unit: '',
            legend: 'ขายขน',
          },
        };
        let res = await this.formatListSwitch(resp?.data, title);
        return plainToClass(ExpandListTwoColumnModel, res);
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  getDashboardRemainCarry = async (type: string, searchId: number, regionId: number) => {
    try {
      const resp: any = await api.getDashboardRemainCarry(type, searchId, regionId);
      if (resp !== undefined) {
        const prevMonth = formatThaiMonthOnly(getPreviousMonth());
        const currMonth = formatThaiMonthOnly(new Date());
        let title = {
          name: 'ค้างขน',
          unit: '',
          month: '',
          left: {
            name: prevMonth,
            unit: '',
            legend: 'ค้างขน',
          },
          right: {
            name: currMonth,
            unit: '',
            legend: 'ยอดขนแล้ว/ยอดที่ต้องขน',
          },
        };
        let res = await this.formatListSwitch(resp?.data, title);
        return plainToClass(ExpandListTwoColumnModel, res);
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  getDashBoardWareHouse = async (type: string | any, searchId: number | any, regionId: number | any) => {
    try {
      const resp: any = await api.getDashBoardWareHouse(type, searchId, regionId);
      if (resp !== undefined) {
        let title = {
          name: 'พื้นที่คลัง',
          unit: '',
          month: '',
          left: {
            name: 'ลูกค้า',
            unit: '',
            legend: 'สต๊อกลูกค้า/พื้นที่คลังลูกค้า',
          },
          right: {
            name: 'TBL',
            unit: '',
            legend: 'สต๊อกฝาก TBL/ค้างขน',
          },
        };
        let res = await this.formatListSwitch(resp?.data, title);
        return plainToClass(ExpandListTwoColumnModel, res);
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  getDashboardSellOut = async (type: string | any, searchId: number | any, regionId: number | any) => {
    try {
      const resp: any = await api.getDashboardSellOut(type, searchId, regionId);
      if (resp?.data) {
        let data = await this.formatSellOut(resp?.data);
        let res = {
          name: 'Sell Out',
          unit: '(ลัง)',
          left: {
            legend: 'ปีที่แล้ว',
          },
          right: {
            legend: 'ปีนี้',
          },
          data: data,
        };
        return plainToClass(ExpandListThreeColumnModel, res);
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  formatSellOut = async (data: any) => {
    let result = new Array();
    if (data != undefined) {
      for (const element of data) {
        let item = element;
        result.push({
          name: item.productGroupName,
          data: [
            {
              name: '1-10',
              prev: {
                qty: item.prevSellOutQty1_10,
                percent: item.prevSellOutPercent1_10,
                unit: 'ลัง',
              },
              curr: {
                qty: item.currSellOutQty1_10,
                percent: item.currSellOutPercent1_10,
                unit: 'ลัง',
              },
            },
            {
              name: '1-20',
              prev: {
                qty: item.prevSellOutQty1_20,
                percent: item.prevSellOutPercent1_20,
                unit: 'ลัง',
              },
              curr: {
                qty: item.currSellOutQty1_20,
                percent: item.currSellOutPercent1_20,
                unit: 'ลัง',
              },
            },
            {
              name: '1-30',
              prev: {
                qty: item.prevSellOutQty1_30,
                percent: item.prevSellOutPercent1_30,
                unit: 'ลัง',
              },
              curr: {
                qty: item.currSellOutQty1_30,
                percent: item.currSellOutPercent1_30,
                unit: 'ลัง',
              },
            },
          ],
        });
      }
    }
    return result;
  };

  formatList = async (data: any, option: any) => {
    let res: any = {
      name: option.name,
      unit: option.unit,
      month: option.month,
      left: {
        legend: option.left.legend,
      },
      right: {
        legend: option.right.legend,
      },
      data: {
        order: 1,
        left: {
          name: option.left.name,
          unit: option.left.unit,
          actual: data[0].currActual,
          total: data[0].currTarget,
        },
        right: {
          name: option.right.name,
          unit: option.right.unit,
          actual: data[0].prevActual,
          total: data[0].prevTarget,
        },
        children: [],
      },
    };

    for (let i = 1; i < data.length; i++) {
      let item: any = {
        order: 1,
        left: {
          name: data[i].nodeName,
          unit: '',
          actual: data[i].currActual,
          total: data[i].currTarget,
        },
        right: {
          name: '',
          unit: '',
          actual: data[i].prevActual,
          total: data[i].prevTarget,
        },
        children: [],
      };
      for (const element of data[i].childs) {
        let itm: any = {
          order: 1,
          left: {
            name: element.nodeName,
            unit: '',
            actual: element.currActual,
            total: element.currTarget,
          },
          right: {
            name: '',
            unit: '',
            actual: element.prevActual,
            total: element.prevTarget,
          },
          children: [],
        };

        for (const element2 of element.childs) {
          let itm2: any = {
            order: 1,
            left: {
              name: element2.nodeName,
              unit: '',
              actual: element2.currActual,
              total: element2.currTarget,
            },
            right: {
              name: '',
              unit: '',
              actual: element2.prevActual,
              total: element2.prevTarget,
            },
            children: [],
          };
          itm.children.push(itm2);
        }
        item.children.push(itm);
      }
      res.data.children.push(item);
    }

    return res;
  };

  formatListSwitch = async (data: any, option: any) => {
    let res: any = {
      name: option.name,
      unit: option.unit,
      month: option.month,
      left: {
        legend: option.left.legend,
      },
      right: {
        legend: option.right.legend,
      },
      data: {
        order: 1,
        left: {
          name: option.left.name,
          unit: option.left.unit,
          actual: data[0].prevActual,
          total: data[0].prevTarget,
        },
        right: {
          name: option.right.name,
          unit: option.right.unit,
          actual: data[0].currActual,
          total: data[0].currTarget,
        },
        children: [],
      },
    };

    for (let i = 1; i < data.length; i++) {
      let item: any = {
        order: 1,
        left: {
          name: data[i].nodeName,
          unit: '',
          actual: data[i].prevActual,
          total: data[i].prevTarget,
        },
        right: {
          name: '',
          unit: '',
          actual: data[i].currActual,
          total: data[i].currTarget,
        },
        children: [],
      };
      for (const element of data[i].childs) {
        let itm: any = {
          order: 1,
          left: {
            name: element.nodeName,
            unit: '',
            actual: element.prevActual,
            total: element.prevTarget,
          },
          right: {
            name: '',
            unit: '',
            actual: element.currActual,
            total: element.currTarget,
          },
          children: [],
        };
        for (const element2 of element.childs) {
          let itm2: any = {
            order: 1,
            left: {
              name: element2.nodeName,
              unit: '',
              actual: element2.prevActual,
              total: element2.prevTarget,
            },
            right: {
              name: '',
              unit: '',
              actual: element2.currActual,
              total: element2.currTarget,
            },
            children: [],
          };
          itm.children.push(itm2);
        }
        item.children.push(itm);
      }
      res.data.children.push(item);
    }

    return res;
  };
}
