import { Expose, Type } from "class-transformer";

export class TierModel {
  constructor() {
    this.name = "";
    // this.type = "";
    this.subType = "";
    // this.attribute = "";
  }

  @Expose({ name: "buName" })
  name: string;

  // @Expose({ name: "shopType" })
  // type: string;

  @Expose({ name: "shopAttribute" })
  subType: string;

  // @Expose({ name: "shopAttribute" })
  // attribute: string;
}

export class SubAgentModel {
  constructor(name: string, customerName: string) {
    this.name = name;
    this.customerName = customerName;
  }

  @Expose({ name: "customerSubAgentName" })
  name: string;

  @Expose({ name: "customerName" })
  customerName: string;
}

export class MemberModel {
  constructor() {
    this.name = "";
    this.age = 0;
    this.phone = "";
  }

  @Expose({ name: "customerRelationName" })
  name: string;

  @Expose({ name: "customerRelationAge" })
  age: number;

  @Expose({ name: "customerRelationTel" })
  phone: string;
}

export class CustomerDetailModel {
  constructor() {
    this.customerTierListData = [];
    this.regionName = "";
    this.areaName = "";
    this.customerCode = "";
    this.customerName = "";
    this.taxNo = "";
    this.branchName = "";
    this.address = "";
    this.customerTel = "";
    this.ownerName = "";
    this.agentStartDate = new Date();
    this.ownerIdCardNo = "";
    this.ownerAddress = "";
    this.ownerMobileNo = "";
    this.ownerBirthDate = new Date();
    this.ownerAge = 0;
    this.ownerStudy = "";
    this.ownerStatus = "";
    this.noOfChildren = 0;
    this.customerPicName = "";
    this.ownerPicName = "";
    this.noOfSubAgent = 0;
    this.noOfRetailCustomer = 0;
    this.noOfEmployee = 0;
    this.noOfSaleMan = 0;
    this.noOfNoneSaleMan = 0;
    this.noOfVanSale = 0;
    this.noOfPickup = 0;
    this.noOf4Wheel = 0;
    this.noOf6Wheel = 0;
    this.noOfDeliveryTruck = 0;
    this.spiritsParentDate = new Date();
    this.beerParentDate = new Date();
    this.nonAlParentDate = new Date();
    this.spiritsType = "";
    this.spiritsSubType = "";
    this.spiritsTier = "";
    this.beerType = "";
    this.beerSubType = "";
    this.beerTier = "";
    this.nonAlType = "";
    this.nonAlSubType = "";
    this.nonAlTier = "";
    this.spiritsAgentName = "";
    this.beerAgentName = "";
    this.spiritsSubAgentName = "";
    this.beerSubAgentName = "";
    this.territoryName1 = "";
    this.territoryName2 = "";
    this.beerTargetPerYear = 0;
    this.beerSalesPerYear = 0;
    this.beerTargetPerMonth = 0;
    this.beerSalesPerMonth = 0;
    this.subAgentSalesProportion = 0;
    this.retailSalesProportion = 0;
    this.joinTBVProject = "";
    this.dealerMessage = "";
    this.totalArea = 0;
    this.totalBeerArea = 0;
    this.lstCustomerRelation = [];
    this.lstCustomerSubAgentData = [];
    this.customerLocationData = null;
    this.groupShopTypeName = "";
    this.shopTypeName = "";
    this.selectedCustomerTierList = []
    this.no = ""
    this.moo = ""
    this.soi = " "
    this.road = "รังสิต นครนายก"
    this.subDistrict = "คลองหก"
    this.district = "คลองหลวง"
    this.province = "ปทุมธานี"
    this.zipCode = ""
    this.village = ""
    this.building = ""
    this.floor = ""
    this.room = ""
    this.branchNo = ""
    this.shopTypeNameId = null;
    this.groupShopTypeId = null;
  }

  @Type(() => TierModel)
  customerTierListData: TierModel[];

  regionName: string;
  areaName: string;
  customerCode: string;
  customerName: string;
  taxNo: string;
  branchName: string;
  address: string;
  customerTel: string;
  ownerName: string;
  agentStartDate: Date;
  ownerIdCardNo: string;
  ownerAddress: string;
  ownerMobileNo: string;
  ownerBirthDate: Date;
  ownerAge: number;
  ownerStudy: string;
  ownerStatus: string;
  noOfChildren: number;
  customerPicName: string;
  ownerPicName: string;
  noOfSubAgent: number;
  noOfRetailCustomer: number;
  noOfEmployee: number;
  noOfSaleMan: number;
  noOfNoneSaleMan: number;
  noOfVanSale: number;
  noOfPickup: number;
  noOf4Wheel: number;
  noOf6Wheel: number;
  noOfDeliveryTruck: number;
  spiritsParentDate: Date;
  beerParentDate: Date;
  nonAlParentDate: Date;
  spiritsType: string;
  spiritsSubType: string;
  spiritsTier: string;
  beerType: string;
  beerSubType: string;
  beerTier: string;
  nonAlType: string;
  nonAlSubType: string;
  nonAlTier: string;
  spiritsAgentName: string;
  beerAgentName: string;
  spiritsSubAgentName: string;
  beerSubAgentName: string;
  territoryName1: string;
  territoryName2: string;
  beerTargetPerYear: number;
  beerSalesPerYear: number;
  beerTargetPerMonth: number;
  beerSalesPerMonth: number;
  subAgentSalesProportion: number;
  retailSalesProportion: number;
  joinTBVProject: string;
  dealerMessage: string;
  totalArea: number;
  totalBeerArea: number;
  customerLocationData: any | null;
  groupShopTypeName: string;
  shopTypeName: string;
  selectedCustomerTierList: any;
  no: string;
  moo: string;
  soi: string;
  road: string;
  subDistrict: string;
  district: string;
  province: string;
  zipCode: string;
  village: string;
  building: string;
  floor: string;
  room: string;
  branchNo: string | null;
  shopTypeNameId: number | null;
  groupShopTypeId: number | null;

  @Type(() => MemberModel)
  lstCustomerRelation: MemberModel[];

  @Type(() => SubAgentModel)
  lstCustomerSubAgentData: SubAgentModel[];

  @Expose()
  get detail() {
    let obj = {
      name: this.customerName,
      region: this.regionName,
      contract: this.ownerName,
      image: this.customerPicName,
      taxNo: this.taxNo,
      branch: this.branchName,
      thBevProducts: "",
      maker: "",
      address: this.address,
      phone: this.customerTel,
      groupShopTypeName: this.groupShopTypeName,
      shopTypeName: this.shopTypeName,
      no: this.no,
      moo: this.moo,
      soi: this.soi,
      road: this.road,
      subDistrict: this.subDistrict,
      district: this.district,
      province: this.province,
      zipCode: this.zipCode,
      village: this.village,
      building: this.building,
      floor: this.floor,
      room: this.room,
      branchNo: this.branchNo,
    };
    return obj;
  }

  @Expose()
  get owner() {
    let obj = {
      image: this.ownerPicName,
      name: this.ownerName,
      cardId: this.ownerIdCardNo,
      birthDate: this.ownerBirthDate,
      age: this.ownerAge,
      status: this.ownerStatus,
      agentStartDate: this.agentStartDate,
      ownerAddress: this.ownerAddress,
      education: this.ownerStudy,
      relations: this.lstCustomerRelation,
    };
    return obj;
  }

  @Expose()
  get facilities() {
    let obj = {
      name: this.ownerName,
      cardId: this.ownerIdCardNo,
      birthDate: this.ownerBirthDate,
      age: this.ownerAge,
      status: this.ownerStatus,
      agentStartDate: this.agentStartDate,
      address: this.ownerAddress,
      education: this.ownerStudy,
      relations: this.lstCustomerRelation,

      subAgent: this.noOfSubAgent,
      retailCustomer: this.noOfRetailCustomer,
      employee: this.noOfEmployee,
      saleMan: this.noOfSaleMan,
      noneSaleMan: this.noOfNoneSaleMan,
      vanSale: this.noOfVanSale,
      pickup: this.noOfPickup,
      car4Wheel: this.noOf4Wheel,
      car6Wheel: this.noOf6Wheel,
      deliveryTruck: this.noOfDeliveryTruck,

      totalArea: this.totalArea,
      beerArea: this.totalArea
    };
    return obj;
  }

  @Expose()
  get insights() {
    let obj = {
      tier: this.customerTierListData,
      agentSpirits: this.spiritsAgentName,
      agentBeers: this.beerAgentName,
      subAgentSpirits: this.spiritsSubAgentName,
      subAgentBeer: this.beerSubAgentName,
      territoryDistrict: this.territoryName1,
      territorySubDistrict: this.territoryName2,
      targetBeerPerYear: this.beerTargetPerYear,
      targetBeerPerMonth: this.beerTargetPerMonth,
      saleBeerPerYear: this.beerSalesPerYear,
      saleBeerPerMonth: this.beerSalesPerMonth,
      thBevProject: this.joinTBVProject,
      subAgents: this.lstCustomerSubAgentData,
      subAgentSalesProportion: this.subAgentSalesProportion,
      retailSalesProportion: this.retailSalesProportion,
      message: this.dealerMessage,
    };
    return obj;
  }

  @Expose()
  get location() {
    let obj = {
      id: this.customerLocationData?.companyId,
      name: this.customerLocationData?.companyName,
      locationId: this.customerLocationData?.customerLocationId,
      latitude: this.customerLocationData?.latitude,
      longtitude: this.customerLocationData?.longtitude
    };
    return obj;
  }


}
