import { Expose } from 'class-transformer';

export class SuccessModel {
  constructor() {
    this.isSuccess = false;
    this.result = undefined;
    this.id = undefined;
  }

  isSuccess?: boolean;
  result?: undefined;
  id?: number;
}
