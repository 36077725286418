import { OfflineIndex } from './index';
const offline = new OfflineIndex();
import { OfflineCustomer } from './customer';
const customer = new OfflineCustomer();

export class OfflineSurvey {
  async fetchSurvey(customerId: number) {
    let detail: any = await customer.getCustomerDetail(customerId);

    let data = await offline.getSurvey();
    let items: any = await offline.fetchWithOrgIn(data, 'saleOrgCriteriaId');

    let survey: any = [
      { type: 'common', lstSurvey: [] },
      { type: 'adhoc', lstSurvey: [] },
    ];

    await Promise.all(
      items?.map(async (item: any) => {
        if (item.customerShopTypeId == detail?.data?.customerShopTypeId) {
          let itm = await this.surveyItemBuild(item);
          if (item.type.toLowerCase() == 'common') {
            survey[0].lstSurvey.push(itm);
          } else {
            survey[1].lstSurvey.push(itm);
          }
        }
      })
    );

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: survey,
      error: null,
      xTotalCount: 0,
    };

    return result;
  }

  async storeSurveyLog(data: any) {
    let customerRefCode = await offline.uuidv4();
    let customerDetail: any = await customer.getCustomerDetail(data.customerId);

    let selectedOrg = await offline.get('offline_selectedOrg');

    let survey = await offline.getSurvey();
    let surveyDetail: any = null;
    await Promise.all(
      survey.map((item: any) => {
        if ((item.surveyId = data.surveyId)) {
          surveyDetail = item;
        }
      })
    );

    let payload = {
      surveyId: data.surveyId,
      shopCode: customerDetail?.data?.customerCode,
      status: 0,
      createDate: new Date().toISOString(),
      channel: selectedOrg.SelectedChannelName,
      saleOrgId: selectedOrg.SelectedSaleOrgCriteriaId,
      customerId: data.customerId,
      customerRefCode: customerRefCode,
    };

    let url = 'https://bptdev.thaibevapp.com/survey-management/surveyForm?';
    let surveyUrl = url + surveyDetail.surveyUrl + `&shopCode=${payload.shopCode}&channel=${payload.channel}&shopName=${customerDetail?.data?.customerName}&companyCode=${selectedOrg.SelectedCompanyId}&saleOrgId=${payload.saleOrgId}&customerId=${payload.customerId}&region=`;

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        surveyUrl: surveyUrl,
        surveyName: surveyDetail.surveyName,
        isSuccess: true,
        message: 'Success',
        surveyDate: null,
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async surveyItemBuild(data: any) {
    let item = {
      surveyId: data.surveyId,
      surveyName: data.surveyName,
      surveyUrl: data.surveyUrl,
      surveyFlag: data.surveyFlag,
      surveyDate: null,
      isEActivitySurvey: false,
    };
    return item;
  }
}
