import { IsNumber, IsString } from 'class-validator';
import { Expose, Transform, Type } from 'class-transformer';

export class OptionModel {
  constructor() {
    this.text = '';
    this.value = '';
    this.isSelected = false;
  }

  @Expose({ name: 'optionText' })
  text: string;

  @Expose({ name: 'optionValue' })
  value: string | number;


  @Expose({ name: 'isSelected' })
  isSelected?: boolean;
}
