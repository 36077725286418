import { Expose } from 'class-transformer';

export class SalesModel {
  constructor(text: string = '', value: number = 0) {
    this.text = text;
    this.value = value;
  }

  @Expose({ name: 'optionText' })
  text: string;

  @Expose({ name: 'optionValue' })
  value: number;
}
