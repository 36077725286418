import axios from 'axios';
import * as qs from 'qs';

export class ReportDashboardABLApi {
  getMarketShareACN = async (regionId: number | any, province: number | any) => {
    let params = {
      regionId: regionId,
      province: province,
    };

    let path = `/api/DashBoard/GetMarketShareACN`;
    const config = {
      method: 'get',
      url: path,
      params: params,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  };

  getMarketShareH2H = async (searchId: number | any, type: string | any) => {
    let params = {
      searchId: searchId,
      type: type,
    };

    let path = `/api/DashBoard/GetMarketShareH2H?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
    };

    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  };

  getABLSaleVsTarget = async (searchId: number | any, type: string | any) => {
    let params = {
      searchId: searchId,
      type: type,
    };

    let path = `/api/DashBoard/GetABLSaleVsTarget?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
    };

    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  };

  getABLDistribution = async (searchId: number | any, type: string | any) => {
    let params = {
      searchId: searchId,
      type: type,
    };

    let path = `/api/DashBoard/GetABLDistribution?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
    };

    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  };
}
