import { Stores } from '@/components/_models/enums/stores';
import { OfflineServices } from '@/utils/offline';

const offline = new OfflineServices();

export class OfflineToDo {
  //logic
  async getPlanByMonthAndYear(month: string | number | any, year: string | number | any) {
    let saleOrg = await offline.get(Stores.SelectedOrg, 'selectedOrg');
    let plans = await offline.get(Stores.PlanByMonthAndYear, saleOrg.SelectedSaleOrgCriteriaId);

    console.log('getPlanByMonthAndYear', plans);

    const res: any = {
      routePlanId: 0,
      month: month,
      year: year,
      plannedDate: [],
    };

    if (plans) {
      await Promise.all(
        plans?.map((item: any) => {
          if (item.month == month && item.year == year) {
            res.routePlanId = item.routePlanId;
            res.plannedDate = item.plannedDate;
          }
        })
      );
    }

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: res,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getGetToDoList(date: string | number, month: string | number, year: string | number) {
    let saleOrg = await offline.get(Stores.SelectedOrg, 'selectedOrg');
    let plans = await offline.get(Stores.PlanByMonthAndYear, saleOrg.SelectedSaleOrgCriteriaId);

    let res = new Array();

    if (plans) {
      await Promise.all(
        plans?.map((item: any) => {
          if (item.month == month && item.year == year) {
            let lst = item.customerPlan;
            lst.map((plan: any) => {
              if (plan.planDate == date) {
                res.push(plan);
              }
            });
          }
        })
      );
    }
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        customerCallPlan: res,
        morningTalk: [],
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }
}
