import { Exclude, Expose, Type } from 'class-transformer';

export class CustomerPlanVisitModel {
  constructor() {
    this.id = 0;
    this.prefix = '';
    this.name = '';
    this.code = '';
    this.owner = '';
    // this.phone = "";
    this.CustomerTel = '';
    this.customerTel = '';
    this.latitude = 0;
    this.longitude = 0;
    this.image = '';
    this.note = '';
    this.address = '';
    this.agentType = '';
    this.salesOrg = '';
    this.isDisableCheckIn = true;
    this.showCheckoutMenu = false;
    this.eActivityList = [];
    this.customerPlanId = 0;
    this.planID = undefined;
    this.routePlanId = undefined;
    this.isShowModalDistance = false;
  }

  @Expose({ name: 'customerId' })
  id: number;

  @Expose({ name: 'customerPrefix' })
  prefix: string;

  @Expose({ name: 'customerName' })
  name: string;

  @Expose({ name: 'customerCode' })
  code: string;

  @Expose({ name: 'ownerName' })
  owner: string;

  @Expose({ name: 'CustomerTel' })
  CustomerTel: string;

  @Expose({ name: 'customerTel' })
  customerTel: string;

  // @Expose({ name: "CustomerTel" })
  // phone: string;

  @Expose({ name: 'customerAddress' })
  address: string;

  @Expose({ name: 'latitude' })
  latitude: number | null;

  @Expose({ name: 'longitude' })
  longitude: number | null;

  @Expose({ name: 'customerPicName' })
  image: string;

  @Expose({ name: 'note' })
  note: string | null;

  @Expose({ name: 'agentType' })
  agentType: string;

  @Expose({ name: 'salesOrg' })
  salesOrg: string;

  @Expose({ name: 'isDisableCheckIn' })
  isDisableCheckIn: boolean;

  @Expose({ name: 'isShowModalDistance' })
  isShowModalDistance: boolean;

  @Expose({ name: 'showCheckoutMenu' })
  showCheckoutMenu: boolean;

  @Expose({ name: 'eActivityList' })
  eActivityList: [];

  @Expose({ name: 'customerPlanId' })
  customerPlanId: number | string | null;

  @Expose({ name: 'planID' })
  planID?: number | string | null;

  @Expose({ name: 'routePlanId' })
  routePlanId?: number | string | null;

  get phone() {
    let value = '';
    if (this.CustomerTel) {
      value = this.CustomerTel;
    } else if (this.customerTel) {
      value = this.customerTel;
    }
    return value;
  }
}
export class OutRangeVisitItemModel {
  constructor() {
    this.reasonType = 'OTHER';
    this.reason = '';
  }

  @Expose({ name: 'reasonType' })
  reasonType: 'TEXT' | 'OTHER';
  @Expose({ name: 'reason' })
  reason: string;
}

export class OutRangeVisitReasonModel {
  constructor() {
    this.data = [] as OutRangeVisitItemModel[];
  }
  @Expose({ name: 'data' })
  data: OutRangeVisitItemModel[];
}

export class EmployeePreVisitModel {
  constructor() {
    this.customerId = 0;
    this.targetAmt = 0;
    this.targetBaseAmt = 0;
    this.targetNetAmt = 0;
    this.estimateAmt1 = 0;
    this.estimateAmt2 = 0;
    this.estimateAmt3 = 0;
    this.estimateAmt4 = 0;
    this.estimateNetAmt = 0;
    this.estimateTsgamt = 0;
    this.estimateBaseAmt = 0;
    this.cusTargetAmt = 0;
    this.cusExportAmt = 0;
    this.cusProfitTradeAmt = 0;
    this.cusBeerAmt = 0;
    this.cusProfSaleNetAmt = 0;
    this.cusProfFreeNetAmt = 0;
    this.cusGBAwdNetAmt = 0;
    this.cusDedicateNetAmt = 0;
    this.cusOther = '';
    this.cusReason = '';
    this.stockRemainEarlyAmt = 0;
    this.stockEarlyAmt = 0;
    this.stockBuyNetAmt = 0;
    this.stockCarryAmt = 0;
    this.stockExportAmt = 0;
    this.stockRemainEndAmt = 0;
    this.stockEndNetAmt = 0;
    this.stockPercentAmt = 0;
    this.employeePreVisitId = 0;
  }

  @Expose({ name: 'customerId' })
  customerId: number;

  @Expose({ name: 'targetAmt' })
  targetAmt: number;

  @Expose({ name: 'targetBaseAmt' })
  targetBaseAmt: number;

  @Expose({ name: 'targetNetAmt' })
  targetNetAmt: number;

  @Expose({ name: 'estimateAmt1' })
  estimateAmt1: number;

  @Expose({ name: 'estimateAmt2' })
  estimateAmt2: number;

  @Expose({ name: 'estimateAmt3' })
  estimateAmt3: number;

  @Expose({ name: 'estimateAmt4' })
  estimateAmt4: number;

  @Expose({ name: 'estimateNetAmt' })
  estimateNetAmt: number;

  @Expose({ name: 'targetAmt' })
  estimateTsgamt: number;

  @Expose({ name: 'estimateBaseAmt' })
  estimateBaseAmt: number;

  @Expose({ name: 'cusTargetAmt' })
  cusTargetAmt: number;
  @Expose({ name: 'cusExportAmt' })
  cusExportAmt: number;

  @Expose({ name: 'cusProfitTradeAmt' })
  cusProfitTradeAmt: number;
  @Expose({ name: 'cusBeerAmt' })
  cusBeerAmt: number;
  @Expose({ name: 'cusProfSaleNetAmt' })
  cusProfSaleNetAmt: number;
  @Expose({ name: 'cusProfFreeNetAmt' })
  cusProfFreeNetAmt: number;
  @Expose({ name: 'cusGBAwdNetAmt' })
  cusGBAwdNetAmt: number;
  @Expose({ name: 'cusDedicateNetAmt' })
  cusDedicateNetAmt: number;
  @Expose({ name: 'cusOther' })
  cusOther: string;
  @Expose({ name: 'cusReason' })
  cusReason: string;

  @Expose({ name: 'stockRemainEarlyAmt' })
  stockRemainEarlyAmt: number;
  @Expose({ name: 'stockEarlyAmt' })
  stockEarlyAmt: number;
  @Expose({ name: 'stockBuyNetAmt' })
  stockBuyNetAmt: number;
  @Expose({ name: 'stockCarryAmt' })
  stockCarryAmt: number;
  @Expose({ name: 'stockExportAmt' })
  stockExportAmt: number;
  @Expose({ name: 'stockRemainEndAmt' })
  stockRemainEndAmt: number;
  @Expose({ name: 'stockEndNetAmt' })
  stockEndNetAmt: number;
  @Expose({ name: 'stockPercentAmt' })
  stockPercentAmt: number;
  @Expose({ name: 'employeePreVisitId' })
  employeePreVisitId: number;
}

export class SuccessPreVisitModel {
  constructor() {
    this.isSuccess = false;
    this.employeePreVisitId = 0;
  }

  isSuccess: boolean;
  employeePreVisitId: number;
}

export class SurveyItemModel {
  constructor() {
    this.id = 0;
    this.name = '';
    this.url = '';
    this.flag = '';
    this.date = '';
    this.isActivity = false;
  }

  @Expose({ name: 'surveyId' })
  id: number;
  @Expose({ name: 'surveyName' })
  name: string;
  @Expose({ name: 'surveyUrl' })
  url: string;
  @Expose({ name: 'surveyFlag' })
  flag: string;
  @Expose({ name: 'surveyDate' })
  date: string;
  @Expose({ name: 'isEActivitySurvey' })
  isActivity: boolean;
}

export class SurveyListModel {
  constructor() {
    this.type = '';
    this.list = [];
  }

  @Expose({ name: 'type' })
  type: string;
  @Expose({ name: 'lstSurvey' })
  list: SurveyItemModel[];
}

export class SuccessSurveyModel {
  constructor() {
    this.isEActivity = false;
    this.isSuccess = false;
    this.url = '';
    this.name = '';
    this.message = '';
    this.date = '';
  }

  @Expose({ name: 'isSuccess' })
  isSuccess: boolean;

  @Expose({ name: 'surveyUrl' })
  url: string;

  @Expose({ name: 'surveyName' })
  name: string;

  @Expose({ name: 'message' })
  message: string;

  @Expose({ name: 'surveyDate' })
  date: string;

  @Expose({ name: 'isEActivitySurvey' })
  isEActivity: boolean;
}

export class MenuCallPlanItem {

  constructor() {
    this.menuId = undefined;
    this.menuName = "";
    this.menuGroup = "";
    this.menuHandler = "";
    this.menuIconURL = "";
    this.canAccess = false;
    this.canUpdate = false;
    this.canDelete = false;
  }

  menuId: number | undefined;
  menuName: string;
  menuGroup: string;
  menuHandler: string;
  menuIconURL: string;
  canAccess: boolean;
  canUpdate: boolean;
  canDelete: boolean;
}