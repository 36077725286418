import axios from 'axios';

export class MorningTalkApi {
  async getListTodayMeeting(params: any = {}) {
    let path = `/api/MorningTalk/ListTodayMeeting`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getSearchHistory(params: any = {}) {
    let path = `/api/MorningTalk/SearchHistory?SearchDate=${params.searchDate}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getViewTodayMeetingRoom(params: any = {}) {
    let path = `/api/MorningTalk/ViewTodayMeetingRoom?MorningTalkId=${params.morningTalkId}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getViewHistoryMeeting(params: any = {}) {
    let path = `/api/MorningTalk/ViewHistoryMeeting?MorningTalkId=${params.morningTalkId}&SearchDate=${params.searchDate}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async submitJoinMeeting(data: any) {
    try {
      const res = await axios.post(`/api/MorningTalk/SubmitJoinMeeting?morningTalkId=${data.morningTalkId}`, {});
      return res.data;
    } catch (err) {
      return err;
    }
  }

  async submitNoteMeeting(data: any) {
    try {
      const res = await axios.post(`/api/MorningTalk/SubmitNoteMeeting?morningTalkId=${data.morningTalkId}`, data);
      return res.data;
    } catch (err) {
      return err;
    }
  }
}
