import { Exclude, Expose, Type } from 'class-transformer';
import { NumberTransform } from './_transform/number_transform';

export class ResponseModel<T> {
  constructor(type: Function) {
    this.type = type;
    this.status = 400;
    this.source = '';
    this.message = '';
    this.total = undefined;
    this.result = false;
    this.error = null;
  }

  @Exclude()
  private type: Function;

  @Type((options) => {
    return (options?.newObject as ResponseModel<T>)?.type;
  })
  data?: T;

  @Expose({ name: 'responseCode' })
  @NumberTransform({ toClassOnly: true })
  status: number;

  @Expose({ name: 'responseDataSource' })
  source: string;

  @Expose({ name: 'responseMessage' })
  message: string;

  @Expose({ name: 'xTotalCount' })
  total?: number;

  result: boolean;
  error: any;
}
