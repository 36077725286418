import { Expose } from 'class-transformer';

export class RoleModel {
  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
  }

  @Expose({ name: 'roleId' })
  id: number;
  @Expose({ name: 'roleName' })
  name: string;
  description: string;
}
