import { Expose, Type } from "class-transformer";

export class OptionGroupShopTypeModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerGroupShopTypeName" })
  text: string;

  @Expose({ name: "customerGroupShopTypeId" })
  value: string;
}

export class OptionCustomerTypeModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerLegalPersonName" })
  text: string;

  @Expose({ name: "customerLegalPersonCode" })
  value: string;
}

export class OptionShopTypeModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerShopTypeName" })
  text: string;

  @Expose({ name: "customerShopTypeId" })
  value: string;
}



export class OptionBUTypeModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerBuName" })
  text: string;

  @Expose({ name: "customerBuId" })
  value: string;
}

export class OptionSubShopTypeModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerSubShopTypeName" })
  text: string;

  @Expose({ name: "customerSubShopTypeId" })
  value: string;
}

export class OptionAttributeLv1Model {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerAttributeLv1Name" })
  text: string;

  @Expose({ name: "customerAttributeLv1Id" })
  value: string;
}

export class OptionAttributeLv2Model {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerAttributeLv2Name" })
  text: string;

  @Expose({ name: "customerAttributeLv2Id" })
  value: string;
}

export class OptionAttributeLv3Model {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "customerAttributeLv3Name" })
  text: string;

  @Expose({ name: "customerAttributeLv3Id" })
  value: string;
}

export class BUTypeModel {
  constructor() {
    this.BUType = undefined;
    this.subShopType = undefined;
    this.attributeLv1 = undefined;
    this.attributeLv2 = undefined;
    this.attributeLv3 = undefined;
  }

  @Type(() => OptionBUTypeModel)
  @Expose({ name: "customerBUType" })
  BUType?: OptionBUTypeModel[];

  @Type(() => OptionSubShopTypeModel)
  @Expose({ name: "customerSubShopType" })
  subShopType?: OptionSubShopTypeModel[];

  @Type(() => OptionAttributeLv1Model)
  @Expose({ name: "customerAttributeLv1" })
  attributeLv1?: OptionAttributeLv1Model[];

  @Type(() => OptionAttributeLv2Model)
  @Expose({ name: "customerAttributeLv2" })
  attributeLv2?: OptionAttributeLv2Model[];

  @Type(() => OptionAttributeLv3Model)
  @Expose({ name: "customerAttributeLv3" })
  attributeLv3?: OptionAttributeLv3Model[];
}


export class CustomerTypeModel {
  constructor() {
    this.customerType = undefined;
  }

  @Type(() => OptionCustomerTypeModel)
  @Expose({ name: "custLegalPerson" })
  customerType?: OptionCustomerTypeModel[];
}


export class GroupShopTypeModel {
  constructor() {
    this.groupType = undefined;
    this.shopType = undefined;
  }

  @Type(() => OptionGroupShopTypeModel)
  @Expose({ name: "custGroupShopType" })
  groupType?: OptionGroupShopTypeModel[];

  @Type(() => OptionShopTypeModel)
  @Expose({ name: "custShopType" })
  shopType?: OptionShopTypeModel[];
}