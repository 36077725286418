import axios from 'axios';
import * as qs from 'qs';

export class ReportFilterApi {
  async postSaleDashboardFilter(payload: string) {
    try {
      const path = `/api/DashBoard/GetInitialDashboardFilter`;

      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.post(path, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postABLDashboardFilter(payload: string) {
    try {
      const path = `/api/DashBoard/GetInitialABLDashboardFilter`;
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.post(path, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postAssistantDashboardFilter(payload: string) {
    try {
      const path = `/api/DashBoard/GetInitialAssistantDashboardFilter`;
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.post(path, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postMSKDashboardFilter(payload: string) {
    try {
      const path = `/api/DashBoard/GetInitialMSKSubAgentFilter`;
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.post(path, payload);
        return res.data;
      }
    } catch (err) {
      return err;
    }
  }

  async postCallPlanDashboardFilter(params: any = {}) {
    try {
      let path = `/api/DashBoard/GetCallPlanFilter?${qs.stringify(params)}`;

      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (err) {
      return err;
    }
  }

  async postPowerBIDashboardFilter(payload: string) {
    try {
      let path = `/api/DashBoard/GetPowerBIFilter`;

      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.post(path, payload);
        return res.data;
      }
    } catch (err) {
      throw err;
    }
  }
  async getOntradeDashboardFilter(payload: string) {
    try {
      const path = `/api/DashBoard/GetOntradeFilter`;
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.post(path, payload);
        return res.data;
      }
    } catch (err) {
      throw err;
    }
  }
}
