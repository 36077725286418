import { Type, Expose } from 'class-transformer';
import { IsNumber,IsString } from 'class-validator';

class Summary {
  constructor() {
    this.transCreator = 0;
    this.transCreatorName = "";
    this.positionName = "";
    this.target = 0;
    this.plan = 0;
    this.actual = 0;
  }

  @IsNumber()
  @Expose({ name: 'transCreator' })
  transCreator: number;
  
  @IsString()
  @Expose({ name: 'transCreatorName' })
  transCreatorName: string;

  @IsString()
  @Expose({ name: 'positionName' })
  positionName: string;

  @IsNumber()
  @Expose({ name: 'targetQty' })
  target: number;

  @IsNumber()
  @Expose({ name: 'planQty' })
  plan: number;

  @IsNumber()
  @Expose({ name: 'actualQty' })
  actual: number;
}

export default Summary;
