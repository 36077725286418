import { IsNumber, IsString } from 'class-validator';
import { Expose, Transform, Type } from 'class-transformer';

export class Item {
  constructor() {
    this.companyBrand = '';
    this.companyColorCode = '';
    this.otherBrand = '';
    this.otherColorCode = '';
    this.percentOfBrand = 0.0;
  }

  @IsString()
  @Expose({ name: 'companyBrand' })
  companyBrand: string;

  @IsString()
  @Expose({ name: 'companyColorCode' })
  companyColorCode: string;

  @IsString()
  @Expose({ name: 'otherBrand' })
  otherBrand: string;

  @IsString()
  @Expose({ name: 'otherColorCode' })
  otherColorCode: string;

  @IsNumber()
  @Expose({ name: 'percentOfBrand' })
  percentOfBrand: number;
}

export class MarketSharedH2HModel {
  constructor() {
    this.data = [];
  }

  @Type(() => Item)
  @Expose()
  data: Item[];
}