import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { ResponsesModel } from '@/models/classes/responses';
import { ResponseModel } from '@/models/classes/response';
import { CustomerPlanVisitModel, EmployeePreVisitModel, OutRangeVisitItemModel, OutRangeVisitReasonModel, SuccessPreVisitModel, SuccessSurveyModel, SurveyItemModel, SurveyListModel } from '@/models/classes/plan/visit';
import { SuccessModel } from '@/models/classes/success';
import { VisitApi } from '@/services/api/plan/visit';
import APIFile from '@/services/api/file';

const api = new VisitApi();
const fileApi = new APIFile();

export class VisitService {
  fetchGetVisitCustomer = async (params: any = {}) => {
    const resData = await api.getVisitCustomer(params);
    const resModel = plainToClassFromExist(new ResponseModel<CustomerPlanVisitModel>(CustomerPlanVisitModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetVisitCustomerOffPlan = async (params: any = {}) => {
    const resData = await api.getVisitCustomerOffPlan(params);
    const resModel = plainToClassFromExist(new ResponseModel<CustomerPlanVisitModel>(CustomerPlanVisitModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetOutRangeVisitReason = async (params: any = {}) => {
    //params.channelId require
    // params['channelId'] = "TT"
    const resData = await api.getOutRangeVisitReason(params);
    const resModel = plainToClassFromExist(new ResponseModel<OutRangeVisitItemModel[]>(OutRangeVisitItemModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return await resModel.data
    }
    return undefined;
  }

  fetchPostCheckInEmployeeVisit = async (params: any = {}) => {
    let payload: any = {
      customerId: params.customerId,
      customerPlanId: params.customerPlanId,
      latitude: params.latitude,
      longitude: params.longitude,
      visitPic: null,
      checkInNoted: params.checkInNoted,
      //for offline
      planID: params?.planID,
      routePlanId: params?.routePlanId,
    };

    if (window.isOffline) {
      payload.visitPic = params.imageBase64;
    } else {
      let file = await fileApi.upload(params.imageBase64);
      if (file.folderFileId != null) {
        payload.visitPic = file.folderFileId + '';
      }
    }

    const resData = await api.postCheckInEmployeeVisit(payload);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchPostCheckInEmployeeVisitOffPlan = async (params: any = {}) => {
    let payload: any = {
      customerId: params.customerId,
      latitude: params.latitude,
      longitude: params.longitude,
      visitPic: null,
      checkInNoted: params.checkInNoted,
    };

    if (window.isOffline) {
      payload.visitPic = params.imageBase64;
    } else {
      let file = await fileApi.upload(params.imageBase64);
      if (file.folderFileId != null) {
        payload.visitPic = file.folderFileId + '';
      }
    }

    const resData = await api.postCheckInEmployeeVisitOffPlan(payload);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchPostCheckOutEmployeeVisit = async (params: any = {}) => {
    let payload: any = {
      customerId: params.customerId,
      customerPlanId: params.customerPlanId,
      latitude: params.latitude,
      longitude: params.longitude,
      note: params.note,
    };
    const resData = await api.postCheckOutEmployeeVisit(payload);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetEmployeePreVisit = async (params: any = {}) => {
    const resData = await api.getEmployeePreVisit(params);
    const resModel = plainToClassFromExist(new ResponseModel<EmployeePreVisitModel>(EmployeePreVisitModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchAddEmployeePreVisit = async (params: any = {}) => {
    const resData = await api.postAddEmployeePreVisit(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessPreVisitModel>(SuccessPreVisitModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchUpdateEmployeePreVisit = async (params: any = {}) => {
    const resData = await api.postUpdateEmployeePreVisit(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessPreVisitModel>(SuccessPreVisitModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetListSurvey = async (params: any = {}) => {
    const resData = await api.getListSurvey(params);
    const resModel = plainToClassFromExist(new ResponsesModel<SurveyListModel>(SurveyListModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      let common = new Array();
      let adhoc = new Array();
      let activity = new Array();

      // let data = resModel.data;

      await Promise.all(
        resModel.data.map(async (item: any) => {
          let lst = new Array();

          await Promise.all(
            item?.list?.map(async (itm: any) => {
              let row = plainToClass(SurveyItemModel, itm);
              lst.push(row);
            })
          );

          if (item.type == 'common') {
            // data.common = lst;
            await Promise.all(
              lst?.map(async (itm: SurveyItemModel) => {
                if (itm.isActivity) {
                  activity.push(itm);
                } else {
                  common.push(itm);
                }
              })
            );
          } else {
            // data.adhoc = lst;
            await Promise.all(
              lst?.map(async (itm: SurveyItemModel) => {
                if (itm.isActivity) {
                  activity.push(itm);
                } else {
                  adhoc.push(itm);
                }
              })
            );
          }
        })
      );

      activity = activity?.sort((a, b) => a.id - b.id);

      let data: any = {
        common: [...common],
        adhoc: [...adhoc],
        activity: [...activity],
      };

      return { data: data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchPostSurveyManagement = async (params: any = {}) => {
    const resData = await api.postSurveyManagement(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessSurveyModel>(SuccessSurveyModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

}
