import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mergeObjects } from '@/utils/utils';

import { MarketSharedH2HModel } from '@/models/classes/report/market_shared_h2h';
import { ExpandListOneColumnModel } from '@/models/classes/report/expand_list_one_column';
import Service from '@/services';
const service = new Service.ReportDashboardABLService();

// Interface
interface DashboardAblState {
  fetch: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: {
      marketSharedACN: any;
      marketSharedH2H?: MarketSharedH2HModel[];
      ABLSaleVsTarget?: ExpandListOneColumnModel;
      ABLDistribution?: ExpandListOneColumnModel;
    };
  };
}

// Initialize State
const initialState: DashboardAblState = {
  fetch: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: {
      marketSharedACN: {},
      marketSharedH2H: undefined,
      ABLSaleVsTarget: undefined,
      ABLDistribution: undefined,
    },
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const dashboardABLSlice = createSlice({
  name: 'reports',
  initialState,
  //basic state change
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    builder.addCase(onFetchMarketSharedACN.pending, (state, action) => {
      state.fetch.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchMarketSharedACN.rejected, (state, action) => {
      state.fetch.network.error = action.error.message;
      state.fetch.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchMarketSharedACN.fulfilled, (state, action) => {
      if (action.payload && action.payload != null) {
        state.fetch.data.marketSharedACN = action.payload;
      } else {
        state.fetch.data.marketSharedACN = undefined;
      }
      state.fetch.network.code = ServiceStatus.succeeded;
    });

    builder.addCase(onFetchMarketSharedH2H.pending, (state, action) => {
      state.fetch.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchMarketSharedH2H.rejected, (state, action) => {
      state.fetch.network.error = action.error.message;
      state.fetch.network.code = ServiceStatus.failed;
    });

    builder.addCase(onFetchMarketSharedH2H.fulfilled, (state, action) => {
      state.fetch.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.fetch.data.marketSharedH2H = action.payload;
      } else {
        state.fetch.data.marketSharedH2H = undefined;
      }
    });

    builder.addCase(onFetchGetABLSaleVsTarget.pending, (state, action) => {
      state.fetch.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetABLSaleVsTarget.rejected, (state, action) => {
      state.fetch.network.error = action.error.message;
      state.fetch.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchGetABLSaleVsTarget.fulfilled, (state, action) => {
      if (action.payload && action.payload != null) {
        state.fetch.data.ABLSaleVsTarget = action.payload;
      } else {
        state.fetch.data.ABLSaleVsTarget = undefined;
      }
      state.fetch.network.code = ServiceStatus.succeeded;
    });

    builder.addCase(onFetchGetABLDistribution.pending, (state, action) => {
      state.fetch.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetABLDistribution.rejected, (state, action) => {
      state.fetch.network.error = action.error.message;
      state.fetch.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchGetABLDistribution.fulfilled, (state, action) => {
      if (action.payload && action.payload != null) {
        state.fetch.data.ABLDistribution = action.payload;
      } else {
        state.fetch.data.ABLDistribution = undefined;
      }
      state.fetch.network.code = ServiceStatus.succeeded;
    });
  },
});

export const onFetchMarketSharedACN = createAsyncThunk('dashboard/abl/market_shared_acn', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getMarketShareACN(param?.regionId, param?.province);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchMarketSharedH2H = createAsyncThunk('dashboard/abl/market_shared_h2h', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getMarketShareH2H(param?.searchId, param?.type);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetABLSaleVsTarget = createAsyncThunk('dashboard/abl/sale_vs_target', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getABLSaleVsTarget(param?.searchId, param?.type);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetABLDistribution = createAsyncThunk('dashboard/abl/distribution', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getABLDistribution(param?.searchId, param?.type);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const { reset, patch } = dashboardABLSlice.actions;

export default dashboardABLSlice.reducer;
