import axios from 'axios';
import { OfflineCustomer } from '@/services/offline/customer';
const customer = new OfflineCustomer();

export class OffPlanFilterApi {
  async getFilter() {
    let path = `/api/CallPlan/ListCustomerOffPlanFilter`;
    try {
      if (window.isOffline) {
        return await customer.getCustomerOffPlanFilter();
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getCustomer(filter: number, latitude: number, longitude: number, radius: number) {
    let path = `/api/CallPlan/ListOfCustomerOffPlan?Latitude=${latitude}&Longitude=${longitude}&radius=${radius}&filter=${filter}`;
    try {
      if (window.isOffline) {
        return await customer.getCustomer(filter);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getSearchCustomerOffplan(searchText: string, limit: number, offset: number, filter: number) {
    let path = `/api/CallPlan/SearchCustomerOffPlan?searchText=${searchText}&limit=${limit}&offset=${offset}&filter=${filter}`;

    try {
      if (window.isOffline) {
        return await customer.getSearchCustomer(searchText, filter);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }
}
