import { OfflineIndex } from './index';
const offline = new OfflineIndex();

export class OfflineOrganization {
  async chainFetch(company?: string | null, channel?: string | null) {
    let selected: any = undefined;
    let data = await offline.getOrganization();
    let companyOptions = new Array();
    let channelOptions = new Array();
    let saleOrgOptions = new Array();
    let selectedIndex = {
      company: 0,
      channel: 0,
      saleOrg: 0,
    };
    let selectedValue: any = {
      company: '',
      channel: '',
      saleOrg: '',
    };

    data.company.map((item: any, index: number) => {
      companyOptions.push({
        optionValue: item.companyId,
        optionText: item.companyName,
      });
      if (selected && selected.SelectedCompanyId == item.companyId) {
        selectedIndex.company = index;
        selectedValue.company = item.companyId;
      }
    });

    let channelIdx = 0;
    data.channel.map((item: any) => {
      if ((!company && item.companyId == companyOptions[0].optionValue) || item.companyId == company) {
        channelOptions.push({
          optionValue: item.channelId,
          optionText: item.channelName,
        });
        if (selected && selected.SelectedChannelId == item.channelId) {
          selectedIndex.channel = channelIdx;
        }
        channelIdx++;
      }
      if (selected && selected.SelectedChannelId == item.channelId) {
        selectedValue.channel = item.channelId;
      }
    });

    let saleOrgIdx = 0;
    data.saleOrg.map((item: any) => {
      if ((!channel && item.channelId == channelOptions[0].optionValue) || item.channelId == channel) {
        saleOrgOptions.push({
          optionValue: item.saleOrgCriteriaId,
          optionText: item.saleOrgCriteriaName,
        });
        if (selected && selected.SelectedSaleOrgCriteriaId == item.saleOrgCriteriaId) {
          selectedIndex.saleOrg = saleOrgIdx;
        }
        saleOrgIdx++;
      }
      if (selected && selected.SelectedSaleOrgCriteriaId == item.saleOrgCriteriaId) {
        selectedValue.saleOrg = item.saleOrgCriteriaId;
      }
    });

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        ShowPopUp: true,
        companyOptions: companyOptions,
        channelOptions: channelOptions,
        saleOrgOptions: saleOrgOptions,
      },
      selectedIndex: selectedIndex,
      selectedValue: selectedValue,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async setOrg(company: any, channel: any, saleOrg: any) {
    let data = offline.get('offline_selectedOrg');
    let opts = await offline.getOrganization();
    let companyOption: any = {};
    let channelOption: any = {};
    let saleOrgOption: any = {};

    opts.company.map((item: any) => {
      if (item.companyId == company) {
        companyOption = {
          optionValue: item.companyId,
          optionText: item.companyName,
        };
      }
    });

    opts.channel.map((item: any) => {
      if (item.channelId == channel) {
        channelOption = {
          optionValue: item.channelId,
          optionText: item.channelName,
        };
      }
    });

    opts.saleOrg.map((item: any) => {
      if (item.saleOrgCriteriaId == saleOrg) {
        saleOrgOption = {
          optionValue: item.saleOrgCriteriaId,
          optionText: item.saleOrgCriteriaName,
        };
      }
    });

    let payload: any = {
      ...data,
    };

    payload.SelectedCompanyName = companyOption.optionText;
    payload.SelectedCompanyId = companyOption.optionValue;
    payload.SelectedChannelName = channelOption.optionText;
    payload.SelectedChannelId = channelOption.optionValue;
    payload.SelectedSaleOrgCriteriaName = saleOrgOption.optionText;
    payload.SelectedSaleOrgCriteriaId = saleOrgOption.optionValue;

    await offline.store('offline_selectedOrg', JSON.stringify(payload));

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        isSuccess: true,
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }
}
