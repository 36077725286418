import { ApproveCallPlanApi } from "./../../api/plan/approve";
import { plainToClassFromExist } from "class-transformer";
import { ResponsesModel } from "@/models/classes/responses";
import { ResponseModel } from "@/models/classes/response";
import { PlanModel } from "@/models/classes/plan/approve";
import { SuccessModel } from "@/models/classes/success";

const api = new ApproveCallPlanApi();

export class ApproveCallPlanService {
  fetchGetListApproveCallPlan = async (params: any = {}) => {
    const resData = await api.getListApproveCallPlan();
    const resModel = plainToClassFromExist(
      new ResponsesModel<PlanModel>(PlanModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  updateApproveListApproveCallPlan = async (params: any = {}) => {
    const resData = await api.updateApproveListApproveCallPlan(params);
    const resModel = plainToClassFromExist(
      new ResponseModel<SuccessModel>(SuccessModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return resModel.data;
    }
    return undefined;
  };

  updateRejectListApproveCallPlan = async (params: any = {}) => {
    const resData = await api.updateRejectListApproveCallPlan(params);
    const resModel = plainToClassFromExist(
      new ResponseModel<SuccessModel>(SuccessModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return resModel.data;
    }
    return undefined;
  };
}
