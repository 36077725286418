import { Exclude, Expose, Type } from "class-transformer";

export class PlanModel {
  constructor() {
    this.id = 0;
    this.criteriaId = 0;
    this.prefix = "";
    this.firstName = "";
    this.lastName = "";
    this.fullName = "";
    this.month = "";
    this.year = 0;
    this.plans = 0;
    this.description = "";
    this.contactId = 0;
    this.checked = false;
    this.status = 0;
  }

  @Expose({ name: "routePlanId" })
  id: number;

  @Expose({ name: "saleOrgCriteriaId" })
  criteriaId: number;

  @Expose({ name: "prefixCode" })
  prefix: string;

  @Expose({ name: "localFirstName" })
  firstName: string;

  @Expose({ name: "localLastName" })
  lastName: string;

  @Expose({ name: "localFullName" })
  fullName: string;

  @Expose({ name: "monthNameTH" })
  month: string;

  @Expose({ name: "routePlanYear" })
  year: number;

  @Expose({ name: "cntPlan" })
  plans: number;

  @Expose({ name: "description" })
  description: string;

  @Expose({ name: "contactId" })
  contactId: number;

  @Expose({ name: "status" })
  status: number;

  // @Expose({ name: "routePlanId" })
  checked: boolean;
}
