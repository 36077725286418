import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { ResponsesModel } from '@/models/classes/responses';
import { ResponseModel } from '@/models/classes/response';
import { AddListCustomerModel, CallPlanModel, CheckHasChangeRoutePlanModel, CopyPlanModel, MorningTalkModel, OffPlanCustomersModel, PlanCalendarModel, PlanCustomersModel, PlanDraftModel, PlanSummaryModel, PlanTierModel, SendPlanModel, ToDoListModel, UpdateListCustomerModel } from '@/models/classes/plan/on_plan';
import { OnPlanApi } from '@/services/api/plan/on_plan';

const api = new OnPlanApi();

export class OnPlanService {
  fetchGetPlanSummaryData = async () => {
    const resData = await api.getPlanSummaryData();
    const resModel = plainToClassFromExist(new ResponsesModel<CallPlanModel>(CallPlanModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchGetPlanByRoutePlanId = async (params: any = {}) => {
    const resData = await api.getPlanByRoutePlanId(params);
    const resModel = plainToClassFromExist(new ResponseModel<PlanCalendarModel>(PlanCalendarModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchGetCustomerByPlanDate = async (params: any = {}) => {
    const resData = await api.getCustomerByPlanDate(params);
    const resModel = plainToClassFromExist(new ResponsesModel<PlanCustomersModel>(PlanCustomersModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchGetCloneRoutePlan = async (params: any = {}) => {
    const resData = await api.getCloneRoutePlan(params);
    const resModel = plainToClassFromExist(new ResponseModel<PlanDraftModel>(PlanDraftModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchListOfCustomerOffPlan = async (params: any = {}) => {
    const resData = await api.getListOfCustomerOffPlan(params);
    const resModel = plainToClassFromExist(new ResponsesModel<OffPlanCustomersModel>(OffPlanCustomersModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchGetSummaryRoutePlan = async (params: any = {}) => {
    const resData: any = await api.getSummaryRoutePlan(params);

    await Promise.all(
      resData?.data?.map((item: any) => {
        if (item.actual == 0) {
          item.status = 0;
        } else if (item.actual == item.goal) {
          item.status = 2;
        } else if (item.actual < item.goal) {
          item.status = 1;
        }
      })
    );

    console.log('resDataresDataresDataresDataresDataresData', resData);

    const resModel = plainToClassFromExist(new ResponsesModel<PlanSummaryModel>(PlanSummaryModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchGetSummaryRoutePlanByTier = async (params: any = {}) => {
    const resData = await api.getSummaryRoutePlanByTier(params);
    const resModel = plainToClassFromExist(new ResponseModel<PlanTierModel>(PlanTierModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchGetPlanByMonthAndYear = async (params: any = {}) => {
    const resData = await api.getPlanByMonthAndYear(params);
    const resModel = plainToClassFromExist(new ResponseModel<PlanCalendarModel>(PlanCalendarModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  submitCopyPlan = async (params: any = {}) => {
    let destination = new Array();

    await Promise.all(
      params.destination.map((item: any) => {
        let d = item.split('-');
        destination.push({
          date: parseInt(d[2]),
          month: parseInt(d[1]),
          year: parseInt(d[0]),
        });
      })
    );

    let payload = {
      sourcePlanId: params.plan.id,
      sourceMonth: params.date.month,
      sourceYear: params.date.year,
      destination: [...destination],
    };

    const resData = await api.submitCopyPlan(payload);

    const resModel = plainToClassFromExist(new ResponseModel<CopyPlanModel>(CopyPlanModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  submitDraftRoutePlan = async (params: any = {}) => {
    const resData = await api.submitDraftRoutePlan(params);

    const resModel = plainToClassFromExist(new ResponseModel<AddListCustomerModel>(AddListCustomerModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  checkHasChangeRoutePlan = async (params: any = {}) => {
    const resData = await api.checkHasChangeRoutePlan(params);

    const resModel = plainToClassFromExist(new ResponseModel<CheckHasChangeRoutePlanModel>(CheckHasChangeRoutePlanModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  addListCustomer = async (params: any = {}) => {
    let lst = new Array();

    await Promise.all(
      params.listCustomers.map((item: any, index: number) => {
        lst.push({
          customerId: item.id,
          orderRank: index + 1,
        });
      })
    );

    let d = params.planDate.split('/');
    let m: number | string = parseInt(d[1]);
    let y = parseInt(d[0]);
    let dy: number | string = parseInt(d[2]);

    m = m < 10 ? '0' + m : m;
    dy = dy < 10 ? '0' + dy : dy;

    let payload = {
      listCustomer: lst,
      planDate: y + '/' + m + '/' + dy,
      routePlanId: params.routePlanId,
      routePlanMonth: d[1],
      routePlanYear: d[0],
    };

    const resData = await api.addListCustomer(payload);

    const resModel = plainToClassFromExist(new ResponseModel<AddListCustomerModel>(AddListCustomerModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  updateListCustomer = async (params: any = {}) => {
    // mode Edit / Update
    let lst = new Array();
    //add new list
    for (let i = 0; i < params.listCustomers.length; i++) {
      let item = {
        customerId: params.listCustomers[i].id,
        customerPlanId: 0, //แสดง ID รายการที่เคยถูกบันทึกในระบบ ถ้าไม่มีให้ใช่ 0 คือรายการใหม่
        orderRank: i + 1,
        delCustomer: 0,
      };
      lst.push(item);
    }
    //add plans
    for (let i = 0; i < params.plans.length; i++) {
      let plan: any = params.plans[i];
      let exist: boolean = false;
      for (let j = 0; j < lst.length; j++) {
        //มีในแผนเดิมไม่มีในรายการที่เลือกไว้
        if (plan.customerId == lst[j].customerId) {
          exist = true;
          lst[j].customerPlanId = plan.customerPlanId;
        }
      }

      if (!exist) {
        lst.push({
          customerId: plan.customerId,
          customerPlanId: plan.customerPlanId,
          orderRank: plan.order,
          delCustomer: 1,
        });
      }
    }

    let d = params.planDate.split('/');
    let payload: any = {
      planId: params.plans[0].id,
      routePlanMonth: d[1],
      routePlanYear: d[0],
      lstCustomer: [...lst],
    };

    console.log('params', params);
    console.log('payload', payload);

    const resData = await api.updateListCustomer(payload);

    const resModel = plainToClassFromExist(new ResponseModel<UpdateListCustomerModel>(UpdateListCustomerModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  resetRoutePlan = async (params: any = {}) => {
    const resData = await api.resetRoutePlan(params);
    const resModel = plainToClassFromExist(new ResponseModel<PlanCalendarModel>(PlanCalendarModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data };
    }
    return undefined;
  };

  sendRoutePlan = async (params: any = {}) => {
    const resData = await api.sendRoutePlan(params);

    const resModel = plainToClassFromExist(new ResponseModel<SendPlanModel>(SendPlanModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetToDoList = async (params: any = {}) => {
    const resData = await api.getToDoList(params);
    const resModel = plainToClassFromExist(new ResponseModel<ToDoListModel>(ToDoListModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      if (resModel.data.customers) {
        let lst = new Array();
        resModel.data.customers.map((item: any) => {
          let itm = plainToClass(PlanCustomersModel, item);
          lst.push(itm);
        });
        resModel.data.customers = lst;
      }

      if (resModel.data.morningTalk) {
        let lst = new Array();
        resModel.data.morningTalk.map((item: any) => {
          let itm = plainToClass(MorningTalkModel, item);
          lst.push(itm);
        });
        resModel.data.morningTalk = lst;
      }

      return resModel.data;
    }
    return undefined;
  };
}
