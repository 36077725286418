import { OffPlanFilterApi } from "./../../api/plan/off_plan/filter";
import { OffPlanApi } from "./../../api/plan/off_plan/index";
import { plainToClassFromExist } from "class-transformer";
import { ResponseModel } from "@/models/classes/response";
import { ResponsesModel } from "@/models/classes/responses";
import { OffPlanFilterModel } from "@/models/classes/plan/off_plan/filter";
import { OffPlanCustomerModel } from "@/models/classes/plan/off_plan/customer";
import { VerifyOffPlanModel } from "@/models/classes/plan/off_plan";

const apiFilter = new OffPlanFilterApi();
const api = new OffPlanApi();

export class OffPlanService {
  fetchFilter = async () => {
    const resData = await apiFilter.getFilter();
    const resModel = plainToClassFromExist(
      new ResponsesModel<OffPlanFilterModel>(OffPlanFilterModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchCustomer = async (
    filter: number,
    latitude: number,
    longitude: number,
    radius: number
  ) => {
    const resData = await apiFilter.getCustomer(
      filter,
      latitude,
      longitude,
      radius
    );
    const resModel = plainToClassFromExist(
      new ResponsesModel<OffPlanCustomerModel>(OffPlanCustomerModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchCustomerSearch = async (
    searchText: string,
    limit: number,
    offset: number,
    filter: number
  ) => {
    const resData = await apiFilter.getSearchCustomerOffplan(
      searchText,
      limit,
      offset,
      filter
    );
    const resModel = plainToClassFromExist(
      new ResponsesModel<OffPlanCustomerModel>(OffPlanCustomerModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchVerifyOffPlan = async (params: any = {}) => {
    const resData = await api.verifyOffPlan(params);

    const resModel = plainToClassFromExist(
      new ResponseModel<VerifyOffPlanModel>(VerifyOffPlanModel),
      resData
    );

    if (resModel.status == 200 && typeof resModel.data == "object") {
      return resModel.data;
    }
    return undefined;
  };
}
