import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import BusinessReview from '@/services/business_review';
import Customer from '@/models/classes/business_review/customer';
import { mergeObjects } from '@/utils/utils';
import { SuccessModel } from '@/models/classes/success';
import BusinessReviewItem from '@/models/classes/business_review/item';
import { ReviewBySalesEmployeeModel } from '@/models/classes/business_review';

export interface Participant {
  name: string;
  position: string;
}

const BS = new BusinessReview();
// Interface
interface InterfaceState {
  status: {
    code: ServiceStatus;
    error?: string;
  };
  send: {
    code: ServiceStatus;
    error?: string;
  };
  data: {
    year: any[];
    quarter: any[];
    categories: any[];
    customers: Customer[];

    detail: {
      id: any;
      target: any;
      plan: any;
      actual: any;
      businessReviewDate: any;
      businessReviewConfig: any[];
      customer?: Customer;
    };

    owner: {
      summary: {
        total: number;
        target: number;
        plan: number;
        actual: number;
      };
      quarter: any[];
      categories: any[];
      reviews: any[];
    };

    employees: {
      quarter: any[];
      reviews: any[];
    };

    employee: {
      summary: {
        transCreator: number;
        transCreatorName: string;
        positionName: string;
        target: number;
        plan: number;
        actual: number;
      };
      quarter: any[];
      reviews: any[];
    };

    manage: {
      reviews: any;
    };
  };
  form: {
    mode: number;
    init: boolean;
    category: any;
    target: {
      date: number;
      year: string;
      customer?: Customer;
      quarter: any[];
    };
    plan: object;
    actual: {
      date: string;
      customer?: Customer;
      growth?: number;
      support: string;
      idea: string;
      images: string[];
      file?: File;
      participants: Array<Participant>;
      meet: string;
    };
  };
  value: number;
  submit: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  manage: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: BusinessReviewItem[];
    total: number;
  };
  reviews: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ReviewBySalesEmployeeModel;
    total: number;
  };
  detail: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
}

// Initialize State
const initialState: InterfaceState = {
  status: {
    code: ServiceStatus.idle,
    error: undefined,
  },
  // submit: {
  //   code: ServiceStatus.idle,
  //   error: undefined,
  // },
  submit: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  manage: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  reviews: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  detail: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  send: {
    code: ServiceStatus.idle,
    error: undefined,
  },

  data: {
    year: [],
    quarter: [],
    categories: [],
    customers: [],

    detail: {
      id: null,
      target: null,
      plan: null,
      actual: null,
      businessReviewConfig: [],
      customer: undefined,
      businessReviewDate: null,
    },

    owner: {
      quarter: [],
      summary: {
        total: 0,
        target: 0,
        plan: 0,
        actual: 0,
      },
      categories: [],
      reviews: [],
    },
    employees: {
      quarter: [],
      reviews: [],
    },
    employee: {
      summary: {
        transCreator: 0,
        transCreatorName: '',
        positionName: '',
        target: 0,
        plan: 0,
        actual: 0,
      },
      quarter: [],
      reviews: [],
    },
    manage: {
      reviews: [],
    },
  },
  form: {
    mode: 0, //0=create, 1=edit
    init: false,
    category: undefined,
    target: {
      date: Date.now(),
      year: '',
      customer: undefined,
      quarter: ['', '', '', ''],
    },
    plan: {},
    actual: {
      date: '',
      customer: undefined,
      growth: undefined,
      support: '',
      idea: '',
      images: ['', '', '', ''],
      file: undefined,
      participants: [{ name: '', position: '' }],
      meet: '',
    },
  },
  value: 0,
};

// Reducer
export const infoSlice = createSlice({
  name: 'business_review',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
    resetWithKey: (state, action) => {
      if (action.payload === 'form') {
        state.form = { ...initialState.form };
      } else if (action.payload === 'data') {
        state.data.detail = { ...initialState.data.detail };
      }
    },
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    reset_submit: (state) => {
      // state.submit = {
      //   code: ServiceStatus.idle,
      //   error: undefined,
      // };
    },
    reset_send: (state) => {
      state.send = {
        code: ServiceStatus.idle,
        error: undefined,
      };
    },
    set_mode_edit: (state) => {
      state.form.mode = 1;
    },
    set_mode_create: (state) => {
      state.form.mode = 0;
    },
    set_init_true: (state) => {
      state.form.init = true;
    },
    set_init_false: (state) => {
      state.form.init = false;
    },
    remove_form_image_index: (state, index) => {
      state.form.actual.images[index.payload] = '';
    },
  },
  extraReducers: (builder) => {
    // Fetch Branchs
    builder.addCase(onFetchBusreview.pending, (state) => {
      state.status.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchBusreview.fulfilled, (state, action) => {
      state.data.year = action.payload.year;
      state.data.customers = action.payload.customers;
      state.status.code = ServiceStatus.succeeded;
    });

    builder.addCase(onFetchBusreview.rejected, (state, action) => {
      state.status.code = ServiceStatus.failed;
      state.status.error = action.error.message;
    });

    builder.addCase(onFetchBusinessReviewYear.pending, (state) => {
      state.status.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchBusinessReviewYear.fulfilled, (state, action) => {
      state.data.year = action.payload.year;
      state.status.code = ServiceStatus.succeeded;
    });

    builder.addCase(onFetchBusinessReviewCustomers.rejected, (state, action) => {
      state.status.code = ServiceStatus.failed;
      state.status.error = action.error.message;
    });

    builder.addCase(onFetchBusinessReviewCustomers.pending, (state) => {
      state.status.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchBusinessReviewCustomers.fulfilled, (state, action) => {
      state.data.customers = action.payload.customers;
      state.status.code = ServiceStatus.succeeded;
    });

    builder.addCase(onFetchBusinessReviewYear.rejected, (state, action) => {
      state.status.code = ServiceStatus.failed;
      state.status.error = action.error.message;
    });

    // builder.addCase(onFetchBusinessReviewManageList.pending, (state) => {
    //   state.status.code = ServiceStatus.loading;
    // });
    // builder.addCase(
    //   onFetchBusinessReviewManageList.fulfilled,
    //   (state, action) => {
    //     state.data.manage.reviews = action.payload.reviews;
    //     state.status.code = ServiceStatus.succeeded;
    //   }
    // );
    // builder.addCase(
    //   onFetchBusinessReviewManageList.rejected,
    //   (state, action) => {
    //     state.status.code = ServiceStatus.failed;
    //     state.status.error = action.error.message;
    //   }
    // );

    builder.addCase(onFetchBusinessReviewManageList.pending, (state) => {
      state.manage.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchBusinessReviewManageList.fulfilled, (state, action) => {
      state.manage.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.manage.data = action.payload.data;
        state.manage.total = action.payload.total;
      }
    });
    builder.addCase(onFetchBusinessReviewManageList.rejected, (state, action) => {
      state.manage.network.code = ServiceStatus.failed;
      state.manage.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchOwnerBusinessReview.pending, (state) => {
      state.status.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchOwnerBusinessReview.fulfilled, (state, action) => {
      state.data.quarter = action.payload.quarter;
      if (!state.data.quarter) state.data.quarter = action.payload.quarter;

      state.data.categories = action.payload.categories.filter((item: any) => item.value != 0);
      if (state.data.owner.quarter) state.data.owner.quarter = action.payload.quarter;

      state.data.owner.categories = action.payload.categories;
      state.data.owner.reviews = action.payload.reviews;
      state.data.owner.summary = action.payload.summary;
      state.status.code = ServiceStatus.succeeded;
    });

    builder.addCase(onFetchOwnerBusinessReview.rejected, (state, action) => {
      state.status.code = ServiceStatus.failed;
      state.status.error = action.error.message;
    });

    builder.addCase(onFetchSaleOrgBusinessReview.pending, (state) => {
      state.status.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSaleOrgBusinessReview.fulfilled, (state, action) => {
      if (state.data.employees.quarter == null) {
        state.data.employees.quarter = action.payload.quarter;
      }
      state.data.employees.reviews = action.payload.reviews;
      state.status.code = ServiceStatus.succeeded;
    });

    builder.addCase(onFetchSaleOrgBusinessReview.rejected, (state, action) => {
      state.status.code = ServiceStatus.failed;
      state.status.error = action.error.message;
    });

    // builder.addCase(onFetchSaleEmpBusinessReview.pending, (state) => {
    //   state.status.code = ServiceStatus.loading;
    // });
    // builder.addCase(onFetchSaleEmpBusinessReview.fulfilled, (state, action) => {
    //   state.data.employee.quarter = action.payload.quarter;
    //   state.data.employee.summary = action.payload.summary;
    //   state.data.employee.reviews = action.payload.reviews;
    //   state.status.code = ServiceStatus.succeeded;
    // });

    // builder.addCase(onFetchSaleEmpBusinessReview.rejected, (state, action) => {
    //   state.status.code = ServiceStatus.failed;
    //   state.status.error = action.error.message;
    // });

    builder.addCase(onFetchSaleEmpBusinessReview.pending, (state) => {
      state.reviews.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSaleEmpBusinessReview.fulfilled, (state, action) => {
      state.reviews.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        // state.reviews.data = action.payload;
        if (state.reviews.data == undefined) {
          state.reviews.data = action.payload;
        } else {
          state.reviews.data.reviews = action.payload.reviews;
          state.reviews.data.summary = action.payload.summary;
          if (state.reviews.data.quarter == undefined) {
            state.reviews.data.quarter = action.payload.quarter;
          }
        }
      }
    });
    builder.addCase(onFetchSaleEmpBusinessReview.rejected, (state, action) => {
      state.reviews.network.code = ServiceStatus.failed;
      state.reviews.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchBusinessReviewDetail.pending, (state) => {
      state.detail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchBusinessReviewDetail.fulfilled, (state, action) => {
      state.detail.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.detail.data = action.payload;
      }
    });
    builder.addCase(onFetchBusinessReviewDetail.rejected, (state, action) => {
      state.detail.network.code = ServiceStatus.failed;
      state.detail.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onSubmitBusreview.pending, (state) => {
      state.submit.network.code = ServiceStatus.loading;
    });
    builder.addCase(onSubmitBusreview.fulfilled, (state, action) => {
      state.submit.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.submit.data = action.payload;
      }
    });
    builder.addCase(onSubmitBusreview.rejected, (state, action) => {
      state.submit.network.code = ServiceStatus.failed;
      state.submit.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Send
    builder.addCase(onSubmitSendToManager.pending, (state) => {
      state.send.code = ServiceStatus.loading;
    });
    builder.addCase(onSubmitSendToManager.fulfilled, (state, action) => {
      state.send.code = ServiceStatus.succeeded;
    });
    builder.addCase(onSubmitSendToManager.rejected, (state, action) => {
      state.send.code = ServiceStatus.failed;
      state.send.error = action.error.message;
    });
  },
});

// Service
// Init Global Data
export const onFetchBusreview = createAsyncThunk('busreview/data', async (params: any = {}, thunkAPI) => {
  try {
    let apiDataCustomer = await BS.getSearchCustomer('');
    const customers = apiDataCustomer.customer;

    // let apiDataSendToManager = await BS.getListSendToManager();
    // const reviewList = new Array();
    // apiDataSendToManager.lstSendToManager.map((item: any) => {
    //   item["checked"] = false;
    //   reviewList.push(item);
    // });

    const year = [
      {
        name: '2023',
        value: '2023',
      },
      {
        name: '2022',
        value: '2022',
      },
    ];

    return {
      // quarter,
      // categories,
      // categoriesAll,
      // reviewsOfOwner,
      // reviewCategories,
      year,
      // employee,
      customers,
      // reviewList,
    };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchBusinessReviewYear = createAsyncThunk('busreview/data/year', async (params: any = {}, thunkAPI) => {
  try {
    const year = [
      {
        name: '2023',
        value: '2023',
      },
      {
        name: '2022',
        value: '2022',
      },
    ];

    return {
      year,
    };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchBusinessReviewCustomers = createAsyncThunk('busreview/data/customers', async (params: any = {}, thunkAPI) => {
  try {
    let apiDataCustomer = await BS.getSearchCustomer('');
    const customers = apiDataCustomer.customer;
    return {
      customers,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

//Get Business manage list
export const onFetchBusinessReviewManageList = createAsyncThunk('busreview/manage', async (params: any = {}, thunkAPI) => {
  // try {
  //   const reviews = await BS.getListSendToManager();
  //   return {
  //     reviews,
  //   };
  // } catch (error: any) {
  //   // Handle any errors and return an error action
  //   return thunkAPI.rejectWithValue(error.message);
  // }
  try {
    const apiData = await BS.getListSendToManager();
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

//Get Owner Data
export const onFetchOwnerBusinessReview = createAsyncThunk('busreview/data/owner', async (params: any = {}, thunkAPI) => {
  try {
    let qt = params.quarter ?? 'Q1';
    let cat = params.category ?? 0;
    let data: any = await BS.getListBusinessReviewOfOwner(qt, cat);
    const quarter = data.quarter;
    const summary = data.summary;
    const categories = data.category;
    const reviews = data.reviews;
    return {
      quarter,
      summary,
      categories,
      reviews,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

//Get Sale Org Data
export const onFetchSaleOrgBusinessReview = createAsyncThunk('busreview/data/sales', async (params: any = {}, thunkAPI) => {
  try {
    let qt = params.quarter ?? 'Q1';
    let data: any = await BS.getListBusinessReviewBySalesOrg(qt);
    const quarter = data.quarter;
    const reviews = data.reviews;
    return {
      quarter,
      reviews,
    };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

//Get Employee Data
export const onFetchSaleEmpBusinessReview = createAsyncThunk('busreview/data/emp', async (params: any = {}, thunkAPI) => {
  // try {
  //   // const res = await axios.get(`users/${payload}`);

  //   let searchText = params.searchText ?? "Q1";
  //   let subContactId = params.subContactId ?? null;

  //   let quarter;
  //   let summary;
  //   let reviews;

  //   if (searchText != null && subContactId != null) {
  //     let data: any = await BS.getListBusinessReviewBySalesEmployee(
  //       searchText,
  //       subContactId
  //     );
  //     summary = data.summary;
  //     quarter = data.quarter;
  //     reviews = data.reviews;
  //   }

  //   return {
  //     summary,
  //     quarter,
  //     reviews,
  //   };
  // } catch (error: any) {
  //   // Handle any errors and return an error action
  //   return thunkAPI.rejectWithValue(error.message);
  // }
  try {
    const apiData = await BS.getListBusinessReviewBySalesEmployee(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

//Get Business review Detail
export const onFetchBusinessReviewDetail = createAsyncThunk('busreview/data/detail', async (params: any = {}, thunkAPI) => {
  // try {
  //   let id = params.id;

  //   let detail = await BS.getBusinessReviewDetail(id);
  //   return {
  //     detail,
  //   };
  // } catch (error: any) {
  //   // Handle any errors and return an error action
  //   return thunkAPI.rejectWithValue(error.message);
  // }

  try {
    const apiData = await BS.getBusinessReviewDetail(params.id);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

// POST (สร้าง service สำหรับเรียกกลับมาจาก UI submit formdata)
// V2
export const onSubmitBusreview = createAsyncThunk('busreview/submit', async (params: any, thunkAPI) => {
  try {
    let apiSubmit: any = undefined;

    if (params.category == 'Target') {
      apiSubmit = await BS.postCreateBusinessReviewTarget(params);
    } else if (params.category == 'Plan') {
      apiSubmit = await BS.postCreateBusinessReviewPlan(params);
    } else if (params.category === 'Actual') {
      apiSubmit = await BS.postCreateBusinessReviewActual(params);
    }

    if (apiSubmit) {
      return apiSubmit;
    } else {
      return thunkAPI.rejectWithValue('Cannot store data.');
    }

    // return thunkAPI.rejectWithValue("params is undefined");
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onSubmitSendToManager = createAsyncThunk('busreview/send', async (params: any, thunkAPI) => {
  // try {
  //   if (params !== undefined) {
  //     let apiSend = await BS.postSubmitSendToManager(params);
  //     if (apiSend) {
  //       return apiSend;
  //     } else {
  //       return thunkAPI.rejectWithValue("Cannot store data.");
  //     }
  //   }

  //   return thunkAPI.rejectWithValue("params is undefined");
  // } catch (error: any) {
  //   // Handle any errors and return an error action
  //   return thunkAPI.rejectWithValue(error.message);
  // }
  try {
    const apiData = await BS.postSubmitSendToManager(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

// Actions
export const { patch, reset, resetWithKey, increment, decrement, reset_submit, reset_send, set_mode_edit, set_mode_create, set_init_true, set_init_false, remove_form_image_index } = infoSlice.actions;

// Export
export default infoSlice.reducer;
