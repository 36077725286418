import { Type, Expose } from 'class-transformer';
import { IsNumber,IsString } from 'class-validator';

class Customer {
  constructor() {
    this.id = 0;
    this.code = "";
    this.name = "";
  }

  @IsNumber()
  @Expose({ name: 'customerId' })
  id: number;
  
  @IsString()
  @Expose({ name: 'customerCode' })
  code: string;

  @IsNumber()
  @Expose({ name: 'customerName' })
  name: string;
}

export default Customer;
