import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { SuccessModel } from '@/models/classes/success';
import { MorningTalkDetailModel, MorningTalkModel } from '@/models/classes/morning_talk';

const service = new Service.MorningTalkService();

// Interface
interface MorningTalkState {
  meetings: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: MorningTalkModel[];
    total?: number;
  };
  history: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: MorningTalkModel[];
    total?: number;
  };
  detail: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: MorningTalkDetailModel;
  };
  submit: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  join: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
}

// Initialize State
const initialState: MorningTalkState = {
  meetings: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  history: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  detail: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  submit: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  join: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'MorningTalk',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(onFetchGetListTodayMeeting.pending, (state) => {
      state.meetings.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetListTodayMeeting.fulfilled, (state, action) => {
      state.meetings.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.meetings.data = action.payload.data;
        state.meetings.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetListTodayMeeting.rejected, (state, action) => {
      state.meetings.network.code = ServiceStatus.failed;
      state.meetings.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetSearchHistory.pending, (state) => {
      state.history.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetSearchHistory.fulfilled, (state, action) => {
      state.history.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.history.data = action.payload.data;
        state.history.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetSearchHistory.rejected, (state, action) => {
      state.history.network.code = ServiceStatus.failed;
      state.history.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchViewTodayMeetingRoom.pending, (state) => {
      state.detail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchViewTodayMeetingRoom.fulfilled, (state, action) => {
      state.detail.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.detail.data = action.payload;
      }
    });
    builder.addCase(onFetchViewTodayMeetingRoom.rejected, (state, action) => {
      state.detail.network.code = ServiceStatus.failed;
      state.detail.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchSubmitJoinMeeting.pending, (state) => {
      state.join.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSubmitJoinMeeting.fulfilled, (state, action) => {
      state.join.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.join.data = action.payload;
      }
    });
    builder.addCase(onFetchSubmitJoinMeeting.rejected, (state, action) => {
      state.join.network.code = ServiceStatus.failed;
      state.join.network.error = action.error.message || 'Failed to fetch data';
    });

    builder.addCase(onFetchViewHistoryMeeting.pending, (state) => {
      state.detail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchViewHistoryMeeting.fulfilled, (state, action) => {
      state.detail.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.detail.data = action.payload;
      }
    });
    builder.addCase(onFetchViewHistoryMeeting.rejected, (state, action) => {
      state.detail.network.code = ServiceStatus.failed;
      state.detail.network.error = action.error.message || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchGetListTodayMeeting = createAsyncThunk('morning_talk/meetings', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetListTodayMeeting(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchGetSearchHistory = createAsyncThunk('morning_talk/history', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetSearchHistory(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchViewTodayMeetingRoom = createAsyncThunk('morning_talk/today/view', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchViewTodayMeetingRoom(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchViewHistoryMeeting = createAsyncThunk('morning_talk/history/view', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchViewHistoryMeeting(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchSubmitJoinMeeting = createAsyncThunk('morning_talk/submit/่join', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchSubmitJoinMeeting(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchSubmitNoteMeeting = createAsyncThunk('morning_talk/submit/note', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchSubmitNoteMeeting(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
