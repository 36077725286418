import axios from 'axios';
import * as qs from 'qs';

//Services
export class ReportDashboardSaleApi {
  getSalePerformance = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };
    let path = `/api/DashBoard/GetSalePerformance?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashboardSaleReceiveMoney = async (params: any = {}) => {
    let path = `/api/DashBoard/GetDashboardSaleReceiveMoney?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashboardRemainCarry = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };

    let path = `/api/DashBoard/GetDashboarRemainCarry?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashBoardWareHouse = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };

    let path = `/api/DashBoard/GetDashBoardWareHouse?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashboardSellOut = async (type: string | any, searchId: number | any, regionId: number | any) => {
    let params = {
      type: type,
      searchId: searchId,
      regionId: regionId,
    };
    let path = `/api/DashBoard/GetDashboardSellOut?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
}
