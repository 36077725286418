import { Expose } from 'class-transformer';

export class CompanyModel {
  constructor(text: string = '', value: string = '') {
    this.text = text;
    this.value = value;
  }

  @Expose({ name: 'optionText' })
  text: string;

  @Expose({ name: 'optionValue' })
  value: string;
}
