import axios, { AxiosRequestConfig } from 'axios';

import { OfflineOrganization } from './../offline/organization';
const organization = new OfflineOrganization();

import { OfflineIndex } from './../offline';
const offline = new OfflineIndex();

export class AuthApi {
  async getUser() {
    let path = `api/user/GetUserLogin`;

    try {
      if (window.isOffline) {
        return await offline.getUserLogin();
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error get user login`, error);
      throw error;
    }
  }

  async getCurrentSaleOrg() {
    let path = `api/User/GetCurrentSaleOrg`;

    try {
      if (window.isOffline) {
        return await offline.getSaleOrgs();
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error get user login`, error);
      throw error;
    }
  }

  async fetchOrganize(company?: string | null, channel?: string | null) {
    let path = `api/user/ShowPopUpSelectCompany`;
    if (window.isOffline) {
      let data = await organization.chainFetch(company, channel);
      return data;
    } else {
      let config: AxiosRequestConfig = {
        params: {},
      };
      if (company) {
        config.params['companySelected'] = company;
      }
      if (channel) {
        config.params['channelSelected'] = channel;
      }

      try {
        const response = await axios.get(path, config);
        return response.data;
      } catch (error) {
        console.error(`Error fetch organize`, error);
        throw error;
      }
    }
  }

  async updateOrganize(company: string, channel: string, sale: number) {
    if (window.isOffline) {
      let data = await organization.setOrg(company, channel, sale);
      return data;
    } else {
      try {
        const response = await axios.post(`/api/DashBoard/SelectOrgForViewReport?channelSelected=${channel}&companySelected=${company}&saleOrgId=${sale}`);
        return response.data;
      } catch (error) {
        console.error(`Error update organize`, error);
        throw error;
      }
    }
  }

  async getMenuCallPlan(params: any = {}) {
    try {
      if (window.isOffline) {
        console.log("Offline not support for getMenuCallPlan pls implement")
        // let store = await survey.storeSurveyLog();
        // return store;
      } else {
        const url = `/api/Menu/CallPlan`;
        const res = await axios.get(url);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }
}
