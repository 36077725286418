import { plainToClass } from 'class-transformer';

import { plainToClassFromExist } from 'class-transformer';
import { ResponsesModel } from '@/models/classes/responses';
import { ReportDashboardABLApi } from '../api/report/dashboard_abl';
import { MarketSharedH2HModel } from '@/models/classes/report/market_shared_h2h';
import { ExpandListOneColumnModel } from '@/models/classes/report/expand_list_one_column';

const api = new ReportDashboardABLApi();

//Services
export class ReportDashboardABLService {
  getMarketShareACN = async (regionId: number | any, province: string | any) => {
    let data: any = new Array();

    let color: any = {
      classic: '#456643',
      coldbrew: '#59834d',
      leo: '#9e2418',
      other: '#bababa',
      singha: '#bc9736',
    };

    try {
      const resp: any = await api.getMarketShareACN(regionId, province);

      if (resp?.data !== undefined) {
        let arr = Object.entries(resp?.data);
        arr?.map((item) => {
          let k = item[0];
          if (k != 'updatedDate') {
            data.push({
              name: k,
              value: item[1],
              color: color[k],
            });
          }
        });
        return { data: data, updatedDate: resp?.data?.updatedDate };
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  getMarketShareH2H = async (searchId: number | any, type: string | any) => {
    const resp = await api.getMarketShareH2H(searchId, type);
    if (resp) {
      const resModel = plainToClassFromExist(new ResponsesModel<MarketSharedH2HModel>(MarketSharedH2HModel), resp);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return resModel.data;
      }
    }
    return undefined;
  };

  getABLSaleVsTarget = async (searchId: number | any, type: string | any) => {
    let saleVsTarget = {
      name: 'Sale vs Target',
      unit: '(ลัง/%)',
      amount: null,
      amount_unit: null,
      actual: {
        legend: 'ยอดขาย',
      },
      target: {
        legend: 'เป้าหมาย',
      },
      data: <any>[],
    };

    try {
      const resp = await api.getABLSaleVsTarget(searchId, type);

      if (resp) {
        //get groups by brand
        let brand = new Array();
        let brandArr = new Array();
        resp?.data.map((item: any) => {
          if (!brand.includes(item.brand)) {
            brand.push(item.brand);
            brandArr.push({
              name: item.brand,
              unit: '(รวมทั้งหมด)',
              order: 1,
              actual: 0,
              target: 0,
              label: null,
              total: null,
              total_unit: null,
              children: [],
            });
          }
        });

        resp?.data.map((item: any) => {
          brandArr.map((brand: any) => {
            if (item.brand == brand.name) {
              brand.actual += item.actualQty;
              brand.target += item.targetQty;
              brand.children.push({
                name: item.shortName,
                unit: null,
                order: 1,
                actual: item.actualQty,
                target: item.targetQty,
                percent: item.percentOfAct,
              });
            }
          });
        });

        saleVsTarget.data = brandArr;

        let model: ExpandListOneColumnModel = plainToClass(ExpandListOneColumnModel, saleVsTarget);
        return model;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  getABLDistribution = async (searchId: number | any, type: string | any) => {
    let distribution = {
      name: 'Distribution',
      unit: '(จำนวนร้าน)',
      amount: null,
      amount_unit: 'ร้านค้า',
      actual: {
        legend: 'การกระจาย',
      },
      target: {
        legend: 'เป้าหมาย',
      },
      data: [
        {
          name: 'ร้านหน้ากว้าง',
          unit: '(ND)',
          order: 1,
          actual: null,
          target: null,
          label: 'สินค้าในร้าน',
          total: null,
          total_unit: 'ร้านค้า',
          children: <any>[],
        },
        {
          name: 'ร้านคุณภาพ',
          unit: '(WD)',
          order: 2,
          actual: null,
          target: null,
          label: 'สินค้าในร้าน',
          total: null,
          total_unit: 'ร้านค้า',
          children: <any>[],
        },
      ],
    };

    try {
      const resp = await api.getABLDistribution(searchId, type);

      if (resp) {
        await Promise.all(
          resp?.data?.width?.map(async (item: any, index: number) => {
            if (index == 0) {
              distribution.data[0].actual = item.actualQty;
              distribution.data[0].target = item.targetQty;
              distribution.data[0].total = item.targetQty;
              distribution.amount += item.targetQty;
            } else {
              let childs: any = new Array();

              if (item?.skuItems) {
                await Promise.all(
                  item?.skuItems?.map((sku: any) => {
                    childs.push({
                      name: sku.skuCode + ' : ' + sku.skuName,
                      unit: null,
                      order: 0,
                      actual: null,
                      target: null,
                      children: [],
                    });
                  })
                );
              }

              distribution.data[0].children.push({
                name: item.brand,
                unit: null,
                order: 0,
                actual: item.actualQty,
                target: item.targetQty,
                children: childs,
              });
            }
          })
        );
        await Promise.all(
          resp?.data?.qualityShop?.map(async (item: any, index: number) => {
            if (index == 0) {
              distribution.data[1].actual = item.actualQty;
              distribution.data[1].target = item.targetQty;
              distribution.data[1].total = item.targetQty;
              distribution.amount += item.targetQty;
            } else {
              let childs: any = new Array();

              if (item?.skuItems) {
                await Promise.all(
                  item?.skuItems?.map((sku: any) => {
                    childs.push({
                      name: sku.skuCode + ' : ' + sku.skuName,
                      unit: null,
                      order: 0,
                      actual: null,
                      target: null,
                      children: [],
                    });
                  })
                );
              }

              distribution.data[1].children.push({
                name: item.brand,
                unit: null,
                order: 0,
                actual: item.actualQty,
                target: item.targetQty,
                children: childs,
              });
            }
          })
        );

        let model: ExpandListOneColumnModel = plainToClass(ExpandListOneColumnModel, distribution);

        console.log('model-ExpandListOneColumnModel', model);

        return model;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };
}
