import { WeeklyReportApi } from './api/weekly_report';
import { plainToClassFromExist } from 'class-transformer';
import { ResponsesModel } from '@/models/classes/responses';
import { SuccessModel } from '@/models/classes/success';

import { ResponseModel } from '@/models/classes/response';
import { MyReportModel, ReportDetailModel, ReportModel, SummaryReportModel } from '@/models/classes/weekly_report';
import { OptionModel } from '@/models/classes/report/option';

const api = new WeeklyReportApi();

export class WeeklyReportService {
  fetchListOfSummarySalesWeeklyReport = async (params: any = {}) => {
    const resData = await api.getListOfSummarySalesWeeklyReport(params);
    const resModel = plainToClassFromExist(new ResponseModel<SummaryReportModel>(SummaryReportModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchGetMySalesWeeklyReport = async (params: any = {}) => {
    const resData = await api.getMySalesWeeklyReport(params);
    const resModel = plainToClassFromExist(new ResponseModel<MyReportModel>(MyReportModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetReportDetail = async (params: any = {}) => {
    const resData = await api.getReportDetail(params);
    const resModel = plainToClassFromExist(new ResponseModel<ReportDetailModel>(ReportDetailModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchRegionFilter = async (params: any = {}) => {
    const resData = await api.getRegionFilter(params);
    const resModel = plainToClassFromExist(new ResponsesModel<OptionModel>(OptionModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchEmployeeFilter = async (params: any = {}) => {
    const resData = await api.getEmployeeFilter(params);
    const resModel = plainToClassFromExist(new ResponsesModel<OptionModel>(OptionModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchDurationFilter = async (params: any = {}) => {
    const resData = await api.getDurationFilter(params);
    const resModel = plainToClassFromExist(new ResponsesModel<OptionModel>(OptionModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchPostSubmitAcknowledgeSalesWeeklyReport = async (params: any = {}) => {
    const resData = await api.postSubmitAcknowledgeSalesWeeklyReport(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchPostSubmitSummarySalesWeeklyReport = async (params: any = {}) => {
    const resData = await api.postSubmitSummarySalesWeeklyReport(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchListOfMySalesWeeklyReport = async (params: any = {}) => {
    const resData = await api.getListOfMySalesWeeklyReport(params);
    const resModel = plainToClassFromExist(new ResponsesModel<ReportModel>(ReportModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchPostSubmitMySalesWeeklyReport = async (params: any = {}) => {
    const resData = await api.postSubmitMySalesWeeklyReport(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchPostSendMySalesWeeklyReport = async (params: any = {}) => {
    const resData = await api.postSendMySalesWeeklyReport(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };
}
