import { OfflineServices } from './../../utils/offline';
const offline = new OfflineServices();

import { OfflineIndex } from './index';
const offlineIndex = new OfflineIndex();

import { OfflinePlan } from './plan';
const offlinePlan = new OfflinePlan();

import { OfflineVisit } from './visit';
const offlineVisit = new OfflineVisit();

import { Stores } from './../../components/_models/enums/stores';

export class OfflineCustomer {
  async getCustomerOffPlanFilter() {
    let customer = await offlineIndex.getCustomer();
    console.log('xxxxxxxxxxxxxxxxxxx', customer);
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: customer?.listCustomerOffPlanFilter?.data,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getCustomer(filter: number = 1) {
    let customer = await offlineIndex.getCustomer();
    let data = await offlineIndex.fetchWithOrg(customer?.listOfCustomer);
    let lst = new Array();
    data.map((item: any) => {
      if (item.type == filter || filter == 0) {
        lst.push(item);
      }
    });
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: lst,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getSearchCustomer(searchText: string = '', filter: number = 1) {
    let customer = await offlineIndex.getCustomer();
    let data = await offlineIndex.fetchWithOrg(customer?.listOfCustomer);
    let lst = new Array();
    data.map((item: any) => {
      if ((item.type == filter || filter == 0) && item.customerName.includes(searchText)) {
        lst.push(item);
      }
    });
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: lst,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getCustomerDetail(id: number | string) {
    let customer = await offlineIndex.getCustomer();
    let data = await offlineIndex.fetchList(customer?.customerDetail, 'customerId', id);

    //check visit
    let checkIn = await offlineVisit.findVisit(id, 'in');
    let checkOut = await offlineVisit.findVisit(id, 'out');

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: data,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getCustomerDetailOnPlan(id: number | string) {
    let dt = new Date();
    let d = dt.getDate();
    let m = dt.getMonth() + 1;
    let y = dt.getFullYear();

    let plans = await offlinePlan.getPlanByMonthAndYear(m, y);

    //get customerPlan
    let customerPlan: any = null;
    await Promise.all(
      plans?.data?.customerPlan?.map((item: any) => {
        if (item.customerPlanId == id) {
          customerPlan = item;
        }
      })
    );

    let customer = await offlineIndex.getCustomer();
    let data: any = await offlineIndex.fetchList(customer?.customerDetail, 'customerId', customerPlan.customerId);

    data.planID = customerPlan.planID;
    data.routePlanId = customerPlan.routePlanId;

    //check visit
    let checkIn = await offlineVisit.findVisit(data.customerId, 'in');

    if (checkIn) {
      data.showCheckoutMenu = true;
      data.isDisableCheckIn = true;

      let checkOut = await offlineVisit.findVisit(data.customerId, 'out');

      if (checkOut) {
        data.showCheckoutMenu = false;
        data.isDisableCheckIn = true;
        data.note = checkOut.note;
      }
    }

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: data,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  //------------------------ Old -------------------------

  async getCustLegalPerson() {
    let options = await offline.get(Stores.CustLegalPerson, 'all');
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        custLegalPerson: options,
      },
      error: null,
      xTotalCount: options?.length ?? 0,
    };
    return result;
  }

  async getCustomerProfileDropdown(params: any = {}) {
    let options = await offline.get(Stores.CustomerShopType, 'customerShopType');

    let custGroupShopType = new Array();
    let custShopType = new Array();
    if (params.selectedType == 0) {
      custGroupShopType = options.custGroupShopType;
      custShopType = [];
    } else {
      options?.custShopType?.map((item: any) => {
        if (item.customerGroupShopTypeId == params.selectedValue) {
          custShopType.push(item);
        }
      });
    }

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'db',
      isCalled: false,
      data: {
        custGroupShopType: custGroupShopType,
        custShopType: custShopType,
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getCustomerTierDropdown(params: any = {}) {
    let options = await offline.get(Stores.CustomerAttribute, 'customerAttribute');

    console.log('getCustomerTierDropdown', params);

    let customerBUType = new Array();
    let customerSubShopType = new Array();
    let customerAttributeLv1 = new Array();
    let customerAttributeLv2 = new Array();
    let customerAttributeLv3 = new Array();
    if (params.selectedType == 0) {
      customerBUType = options.customerBUType;
      customerSubShopType = [];
      customerAttributeLv1 = [];
      customerAttributeLv2 = [];
      customerAttributeLv3 = [];
    } else {
      if (params.selectedType == 1) {
        options?.customerSubShopType?.map((item: any) => {
          if (item.customerBuId == params.selectedValue) {
            customerSubShopType.push(item);
          }
        });
      } else if (params.selectedType == 2) {
        options?.customerAttributeLv1?.map((item: any) => {
          if (item.customerSubShopTypeId == params.selectedValue) {
            customerAttributeLv1.push(item);
          }
        });
      } else if (params.selectedType == 3) {
        options?.customerAttributeLv2?.map((item: any) => {
          if (item.customerAttributeLv1Id == params.selectedValue) {
            customerAttributeLv2.push(item);
          }
        });
      } else if (params.selectedType == 4) {
        options?.customerAttributeLv3?.map((item: any) => {
          if (item.customerAttributeLv2Id == params.selectedValue) {
            customerAttributeLv3.push(item);
          }
        });
      }
    }

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'db',
      isCalled: false,
      data: {
        notSelectedWord: '-',
        customerBUType: customerBUType,
        customerSubShopType: customerSubShopType,
        customerAttributeLv1: customerAttributeLv1,
        customerAttributeLv2: customerAttributeLv2,
        customerAttributeLv3: customerAttributeLv3,
      },
      error: null,
      xTotalCount: 0,
    };

    console.log('getCustomerTierDropdown', result);

    return result;
  }

  async findCustomer(customerPlanId: number) {
    let saleOrg = await offline.get(Stores.SelectedOrg, 'selectedOrg');
    //get all from plan month year
    let plans = await offline.get(Stores.PlanByMonthAndYear, saleOrg.SelectedSaleOrgCriteriaId);

    let customers = new Array();

    await Promise.all(
      plans.map((item: any) => {
        item?.customerPlan.map((itm: any) => {
          customers.push(itm);
        });
      })
    );

    let plan: any = null;
    await Promise.all(
      customers.map((item: any) => {
        if (item.customerPlanId == customerPlanId) {
          plan = item;
        }
      })
    );

    let detail: any = null;

    if (plan) {
      let cust = await offline.get(Stores.CustomerDetail, plan.customerId);
      if (cust) {
        detail = {
          ...cust,
          note: null,
        };

        console.log('detaildetaildetail', detail);

        let note = await offline.get(Stores.CustomerNote, saleOrg.SelectedChannelId + '_' + plan.customerId);

        if (note) {
          detail.note = note?.note;
        }

        let location = await offline.get(Stores.CustomerLocationByCompany, saleOrg.SelectedChannelId + '_' + plan.customerId);
        if (location) {
          detail.latitude = location?.latitude;
          detail.longitude = location?.longitude;
          detail.showCheckoutMenu = false;
          detail.isDisableCheckIn = false;
        }
      }
    }

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: detail,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async findCustomerPrevisit(customerId: number) {
    let saleOrg = await offline.get(Stores.SelectedOrg, 'selectedOrg');
    let key = saleOrg.SelectedSaleOrgCriteriaId + '_' + customerId;
    let preVisit = await offline.get(Stores.PreVisit, key);

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: preVisit,
      error: null,
      xTotalCount: 0,
    };

    return result;
  }
}
