import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type} from "class-transformer";

class OptionTextValueObj {
  constructor() {
    this.label = "";
    this.value = "";
  }
  
  @IsString()
  @Expose({ name: "label" })
  label: string;

  // @IsString()
  @Expose({ name: "value" })
  value: any;
}

export default OptionTextValueObj;