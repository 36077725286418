import { plainToClassFromExist } from 'class-transformer';
import { ResponseModel } from '@/models/classes/response';
import { AssitantPerformanceModel } from '@/models/classes/overview/assitant_performance';
import { ReportDashboardAssistantApi } from '../api/report/dashboard_assistant';

const api = new ReportDashboardAssistantApi();

//Services
export class ReportDashboardAssistantService {
  getAssistantSalePerformance = async (type: string | any, searchId: number | any, unit: string | any) => {
    const resData: any = await api.getAssistantSalePerformance(type, searchId, unit);
    if (resData) {
      const resModel = plainToClassFromExist(new ResponseModel<AssitantPerformanceModel>(AssitantPerformanceModel), resData);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return resModel.data;
      }
    }

    return undefined;
  };

  getAssistantDashBoardStock = async (type: string | any, searchId: number | any, period: number | any, unit: string | any) => {
    let data: any = {
      name: 'สต๊อก',
      unit: '(ลัง)',
      month: '',
      left: {
        legend: 'สต๊อกลูกค้า',
      },
      right: {
        legend: 'เป้าหมาย',
      },
      data: undefined,
    };

    try {
      const resp: any = await api.getAssistantDashBoardStock(type, searchId, period, unit);
      if (resp !== undefined) {
        let res = await this.formatListSwitch(resp.data, data);
        return res;
      } else {
        return data;
      }
    } catch (error) {
      return data;
    }
  };

  getAssistantDashBoardPurchase = async (type: string | any, searchId: number | any, unit: string | any) => {
    let data: any = {
      name: ['ยอดซื้อซับ', 'เป้าซับเอเย่นต์'],
      unit: ['(Survey)', '(HAS)'],
      month: '',
      left: {
        legend: 'ยอดซื้อซับ',
      },
      right: {
        legend: 'เป้าซับเอเย่นต์',
      },
      data: null,
    };
    try {
      const resp: any = await api.getAssistantDashBoardPurchase(type, searchId, unit);
      if (resp !== undefined) {
        let res = await this.formatListSwitch(resp.data, data);
        return res;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  getAssistantDashboardSellOut = async (type: string | any, searchId: number | any, unit: string | any) => {
    let data: any = {
      name: 'Sell Out',
      unit: '(ลัง)',
      left: {
        legend: 'ปีที่แล้ว',
      },
      right: {
        legend: 'ปีนี้',
      },
      data: [],
    };
    try {
      const resp: any = await api.getAssistantDashboardSellOut(type, searchId, unit);
      if (resp?.data) {
        data = {
          name: 'Sell Out',
          unit: '(ลัง)',
          left: {
            legend: 'ปีที่แล้ว',
          },
          right: {
            legend: 'ปีนี้',
          },
          data: await this.formatSellOut(resp?.data),
        };

        return data;
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  };

  formatListSwitch = async (data: any, option: any) => {
    let res: any = {
      name: option.name,
      unit: option.unit,
      month: option.month,
      left: {
        legend: option.left.legend,
      },
      right: {
        legend: option.right.legend,
      },
      data: {
        order: 1,
        data: {
          name: data[0].nodeName,
          unit: '',
          actual: data[0].currActual,
          total: data[0].currTarget,
        },
        children: [],
      },
    };

    for (let i = 1; i < data.length; i++) {
      let item: any = {
        order: 1,
        data: {
          name: data[i].nodeName,
          unit: '',
          actual: data[i].currActual,
          total: data[i].currTarget,
        },
        children: [],
      };

      for (let j = 0; j < data[i].childs.length; j++) {
        let itm: any = {
          order: 1,
          data: {
            name: data[i]?.childs[j]?.nodeName,
            unit: '',
            actual: data[i]?.childs[j]?.currActual,
            total: data[i]?.childs[j]?.currTarget,
          },
          children: [],
        };

        for (let k = 0; k < data[i]?.childs[j]?.childs?.length; k++) {
          let itm2: any = {
            order: 1,
            data: {
              name: data[i]?.childs[j]?.childs[k]?.nodeName,
              unit: '',
              actual: data[i]?.childs[j]?.childs[k]?.currActual,
              total: data[i]?.childs[j]?.childs[k]?.currTarget,
            },
            children: [],
          };
          itm.children.push(itm2);
        }

        item.children.push(itm);
      }

      res.data.children.push(item);
    }
    return res;
  };

  formatSellOut = async (data: any) => {
    let result = new Array();
    if (data != undefined) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        result.push({
          name: item.productGroupName,
          data: [
            {
              name: '1-10',
              prev: {
                qty: item.prevSellOutQty1_10,
                percent: item.prevSellOutPercent1_10,
                unit: 'ลัง',
              },
              curr: {
                qty: item.currSellOutQty1_10,
                percent: item.currSellOutPercent1_10,
                unit: 'ลัง',
              },
            },
            {
              name: '1-20',
              prev: {
                qty: item.prevSellOutQty1_20,
                percent: item.prevSellOutPercent1_20,
                unit: 'ลัง',
              },
              curr: {
                qty: item.currSellOutQty1_20,
                percent: item.currSellOutPercent1_20,
                unit: 'ลัง',
              },
            },
            {
              name: '1-30',
              prev: {
                qty: item.prevSellOutQty1_30,
                percent: item.prevSellOutPercent1_30,
                unit: 'ลัง',
              },
              curr: {
                qty: item.currSellOutQty1_30,
                percent: item.currSellOutPercent1_30,
                unit: 'ลัง',
              },
            },
          ],
        });
      }
    }
    return result;
  };
}
