import axios from 'axios';
import qs from 'qs';

import { OfflineToDo } from './../../../offline/todo';
const ToDo = new OfflineToDo();

const useMock = false;

export class OnPlanApi {
  async getPlanSummaryData() {
    let path = `/api/CallPlan/GetPlanSummaryData`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getPlanByRoutePlanId(params: any) {
    let path = `/api/CallPlan/GetPlanByRoutePlanId?routePlanId=${params.routePlanId}&planSentFlag=${params.planSentFlag}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getCustomerByPlanDate(params: any) {
    let path = `/api/CallPlan/GetCustomerByPlanDate?date=${params.date}&month=${params.month}&year=${params.year}&rountPlanID=${params.rountPlanID}&callplan=${params.callplan}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getCloneRoutePlan(params: any) {
    let config = {
      method: 'get',
      url: `/api/CallPlan/CloneRoutePlan?routePlanId=${params.routePlanId}&statusType=${params.statusType}`,
    };

    try {
      const res = await axios(config);
      if (!useMock) {
        return res.data;
      } else {
        return await this.getMock('GetPlanSummaryData');
      }
    } catch (error) {
      throw error;
    }
  }

  async getListOfCustomerOffPlan(params: any) {
    let path = `/api/CallPlan/ListOfCustomerOffPlan?Latitude=${params.latitude}&Longitude=${params.longitude}&radius=${params.radius}&filter=${params.filter}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async addListCustomer(data: any) {
    try {
      const res = await axios.post(`/api/CallPlan/AddListCustomer`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async updateListCustomer(data: any) {
    try {
      const res = await axios.post(`/api/CallPlan/UpdateListCustomer`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async getSummaryRoutePlan(params: any) {
    let path = `/api/CallPlan/GetSummaryRoutePlan?RoutePlanId=${params.routePlanId}&PlanSentFlag=${params.planSentFlag}`;

    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getPlanByMonthAndYear(params: any) {
    let path = `/api/CallPlan/GetPlanByMonthAndYear?month=${params.month}&year=${params.year}`;

    try {
      if (window.isOffline) {
        return await ToDo.getPlanByMonthAndYear(params.month, params.year);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async calculateDistanceAndTime(params: any) {
    params.key = '5e3612dcbfa88a77bf9cc6773e5a1545';
    try {
      var requestOptions: RequestInit = {
        method: 'GET',
        redirect: 'follow',
      };
      const res = await fetch(`https://api.longdo.com/RouteService/json/route/matrix?${qs.stringify(params)}`, requestOptions);
      return res.json();
    } catch (error) {
      throw error;
    }
  }

  async resetRoutePlan(params: any) {
    try {
      const res = await axios.put(`/api/CallPlan/ResetRoutePlan?month=${params.month}&year=${params.year}`);
      return res?.data;
    } catch (error) {
      throw error;
    }
  }

  async submitDraftRoutePlan(params: any) {
    try {
      const res = await axios.post(`/api/CallPlan/SubmitDraftRoutePlan?routePlanId=${params.routePlanId}`);
      return res?.data;
    } catch (error) {
      throw error;
    }
  }

  async checkHasChangeRoutePlan(params: any) {
    try {
      const res = await axios.get(`/api/CallPlan/CheckHasChangeRoutePlan?routePlanId=${params.routePlanId}`);
      return res?.data;
    } catch (error) {
      throw error;
    }
  }

  async submitCopyPlan(data: any) {
    try {
      const res = await axios.post(`/api/CallPlan/CopyPlan`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async getSummaryRoutePlanByTier(params: any = {}) {
    let path = `/api/CallPlan/GetSummaryRoutePlanByTier?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async sendRoutePlan(params: any) {
    try {
      const res = await axios.post(`/api/CallPlan/SendRoutePlan?RoutePlanId=${params.routePlanId}`, {});
      return res?.data;
    } catch (error) {
      throw error;
    }
  }

  async getToDoList(params: any) {
    let path = `/api/CallPlan/GetToDoList?date=${params.date}&month=${params.month}&year=${params.year}`;

    try {
      if (window.isOffline) {
        return await ToDo.getGetToDoList(params.date, params.month, params.year);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  getMock = async (index: string) => {
    let GetPlanSummaryData = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'db',
      isCalled: false,
      data: {
        lstNotifyData: [
          {
            notifyPic1: '200055',
            notifyPic2: null,
            notifyPic3: null,
            notifyPic4: null,
            notifyHistoryId: 440,
            notifyHistoryDate: '2023-09-14T14:40:06.85',
            notifyId: 252,
            notifyTitle: 'ddd',
            notifyContent: 'ss',
            iscommonMessage: false,
            destinationPage: null,
            saleOrgData: null,
          },
        ],
        lstNotifyFilterType: [
          {
            usageInfoId: 0,
            usageInfoName: 'ทั้งหมด',
          },
          {
            usageInfoId: 49,
            usageInfoName: 'แนวทาง',
          },
          {
            usageInfoId: 50,
            usageInfoName: 'ข่าวสาร',
          },
          {
            usageInfoId: 51,
            usageInfoName: 'ทั่วไป',
          },
          {
            usageInfoId: 52,
            usageInfoName: 'กิจกรรม',
          },
        ],
        isNotify: false,
      },
      error: null,
      xTotalCount: 1,
    };

    if (index == 'GetPlanSummaryData') {
      return GetPlanSummaryData;
    } else if (index == 'dd') {
      return null;
    }
  };
}
