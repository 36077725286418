import axios from 'axios';
import * as qs from 'qs';

//Services
export class ReportDashboardSumCallPlanApi {
  getDashboardSumCallPlanFilter = async (params: any = {}) => {
    let path = `/api/CallPlan/GetSummaryCallPlanFilter?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashboardSumCallPlan = async (params: any = {}) => {
    let path = `/api/CallPlan/GetSummaryCallPlan?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
  
  getDashboardSumCallPlanDashboard = async (params: any = {}) => {
    let path = `/api/DashBoard/GetSummaryCallPlanDashboard?${qs.stringify(params)}`;
    const config = {
      method: 'post',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashboardSumCallPlanDashboardDetail = async (params: any = {}) => {
    let path = `/api/DashBoard/GetSummaryCallPlanDashboardDetail?${qs.stringify(params)}`;
    const config = {
      method: 'post',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashboardSumCallPlanDashboardDetailEmployee = async (params: any = {}) => {
    let path = `/api/DashBoard/GetSummaryCallPlanDashboardDetailByEmployee?${qs.stringify(params)}`;
    const config = {
      method: 'post',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
}
