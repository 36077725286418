import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type } from "class-transformer";

export class TypeFilterModel {
  constructor() {
    this.name = "";
    this.value = "";
    this.unit = "";
    this.type = 0;
  }

  name: string;
  value: string;
  unit: string;
  
  @Expose({ name: "callPlanType" })
  type: number;
}

export class CallPlanOverviewModel {
  constructor() {
    this.name = "";
    this.employee = "";
    this.searchPeriod = "";
    this.typeFilter = [];
    this.updatedDate = undefined;
  }

  @IsString()
  @Expose({ name: "employeeDetail" })
  employee: string;
  
  @IsString()
  @Expose({ name: "name" })
  name: string;

  @IsString()
  @Expose({ name: "searchPeriod" })
  searchPeriod: string;
  
  @Expose({ name: 'updatetedDate' })
  updatedDate?: Date;

  @Type(() => TypeFilterModel)
  @Expose({ name: "callPlanTypeFilters" })
  typeFilter: TypeFilterModel[];
  
}