import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type } from "class-transformer";
import { OptionModel } from "./option";

export class SumCallPlanModel {
  constructor() {
    this.contactId = 0;
    this.saleOrgCriteriaId = 0;
    this.saleOrgCriteriaName = undefined;
    this.order = 0;
    this.image = "";
    this.name = "";
    this.percent = 0;
    this.sumDate = 0;
    this.sumMonth = 0;
    this.onPlan = 0;
    this.offPlan = 0;
  }

  @Expose({ name: "contactId" })
  contactId: number;

  @Expose({ name: "saleOrgCriteriaId" })
  saleOrgCriteriaId: number;
  
  @Expose({ name: "saleOrgCriteriaName" })
  saleOrgCriteriaName?: string | null | undefined;

  @Expose({ name: "orderRank" })
  order: number;

  @Expose({ name: "picture" })
  image: string;

  @Expose({ name: "employeeName" })
  name: string;

  @Expose({ name: "totalPercen" })
  percent: number;

  @Expose({ name: "sumCurrentDate" })
  sumDate: number;

  @Expose({ name: "sumCurrentMonth" })
  sumMonth: number;


  @Expose({ name: "sumInPlan" })
  onPlan: number;


  @Expose({ name: "sumOffPlan" })
  offPlan: number;

}

export class SumCallPlanListModel {
  constructor() {
    this.list = [];
    this.updatedDate = "";
  }

  @Type(() => SumCallPlanModel)
  @Expose({ name: "result" })
  list?: SumCallPlanModel[];

  @Expose({ name: "syncDatetime" })
  updatedDate?: string;
}



export class SumCallPlanDashboardModel {
  constructor() {
    this.name = "";
    this.saleOrgMasterCenterId = 0;
    this.total = 0;
    this.callPlan = 0;
    this.visitOffPlan = 0;
    this.visitOnPlan = 0;
  }

  @Expose({ name: "centerName" })
  name:string | null | undefined;

  @Expose({ name: "saleOrgMasterCenterId" })
  saleOrgMasterCenterId: number;
  
  @Expose({ name: "total" })
  total: number;

  @Expose({ name: "totalCallplan" })
  callPlan: number;
  
  @Expose({ name: "totalVisitOffPlan" })
  visitOffPlan: number;

  @Expose({ name: "totalVisitPlan" })
  visitOnPlan: number;

}

export class SumCallPlanDashboardListModel {
  constructor() {
    this.list = [];
    this.updatedDate = "";
  }

  @Type(() => SumCallPlanDashboardModel)
  @Expose({ name: "summaryCallPlan" })
  list?: SumCallPlanDashboardModel[];

  @Expose({ name: "syncDatetime" })
  updatedDate?: string;
}



export class SumCallPlanDashboardDetailModel {
  constructor() {
    this.name = "";
    this.positionName = "";
    this.image = "";
    this.saleOrgCriteriaId = 0;
    this.showExpand = false;
    this.total = 0;
    this.callPlan = 0;
    this.visitOffPlan = 0;
    this.visitOnPlan = 0;
  }

  @Expose({ name: "employeeName" })
  name:string | null | undefined;
  
  @Expose({ name: "imageUrl" })
  image:string | null | undefined;
  
  @Expose({ name: "positionName" })
  positionName:string | null | undefined;

  @Expose({ name: "saleOrgCriteriaId" })
  saleOrgCriteriaId: number;

  @Expose({ name: "showExpand" })
  showExpand: boolean;
  
  @Expose({ name: "total" })
  total: number;

  @Expose({ name: "totalCallplan" })
  callPlan: number;
  
  @Expose({ name: "totalVisitOffPlan" })
  visitOffPlan: number;

  @Expose({ name: "totalVisitPlan" })
  visitOnPlan: number;

}

export class SumCallPlanDashboardDetailListModel {
  constructor() {
    this.list = [];
    this.updatedDate = "";
  }

  @Type(() => SumCallPlanDashboardDetailModel)
  @Expose({ name: "summaryCallPlan" })
  list?: SumCallPlanDashboardDetailModel[];

  @Expose({ name: "syncDatetime" })
  updatedDate?: string;
}


export class SumCallPlanFilterModel {
  constructor() {
    // this.month = [];
    // this.year = undefined;
    // this.rank = [];
    this.currentMonth = "";
    this.getMonth = [];
    this.getYear = [];
    this.rank = [];
  }

  @Expose({ name: "currentMonth" })
  currentMonth?: string;

  @Expose({ name: "getMonth" })
  getMonth?: any[];

  @Expose({ name: "getYear" })
  getYear: any;

  @Expose({ name: "rank" })
  rank: any;


  @Expose({ name: 'getMonth' })
  get monthOptions() {
    let lst = new Array();
    this.getMonth?.map((item: any) => {
      let itm = new OptionModel
      itm.text = item.monthDesc;
      itm.value = item.monthId;
      itm.isSelected = item.isSelected;
      lst.push(itm)
    })
    return lst;
  }


  @Expose({ name: 'getYear' })
  get yearOptions() {
    let lst = new Array();
    this.getYear?.map((item: any) => {
      let itm = new OptionModel
      itm.text = item.year;
      itm.value = item.year;
      itm.isSelected = item.isSelected;
      lst.push(itm)
    })
    return lst;
  }


  @Expose({ name: 'rank' })
  get rankOptions() {
    let lst = new Array();
    this.rank?.map((item: any) => {
      let itm = new OptionModel
      itm.text = item.orderByName;
      itm.value = item.orderByCode;
      itm.isSelected = item.isSelected;
      lst.push(itm)
    })
    return lst;
  }


}