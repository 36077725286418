import { Expose, Type } from "class-transformer";
import {
  TypeOptionModel,
  StatusOptionModel,
  NotifyOptionModel,
  UserGroupOptionModel,
} from "./option";
import { NotificationModel } from "./../notification";

export class SearchNotifyModel {
  constructor() {
    this.isNotify = false;
    this.notify = [];
    this.notifyType = [];
  }

  isNotify: boolean;

  @Type(() => NotificationModel)
  @Expose({ name: "lstNotifyData" })
  notify: NotificationModel[];

  @Type(() => TypeOptionModel)
  @Expose({ name: "lstNotifyFilterType" })
  notifyType: TypeOptionModel[];
}

export class HistoryNotifyModel {
  constructor() {
    this.isNotifier = false;
    this.notify = [];
    this.notifyStatus = [];
  }

  @Expose({ name: "isNotifier" })
  isNotifier: boolean;

  @Type(() => NotificationModel)
  @Expose({ name: "notifyHistoryList" })
  notify: NotificationModel[];

  @Type(() => StatusOptionModel)
  @Expose({ name: "notifyHistoryStatus" })
  notifyStatus: StatusOptionModel[];
}

export class NotifyOptionsModel {
  constructor() {
    this.type = [];
    this.group = [];
  }

  @Type(() => NotifyOptionModel)
  @Expose({ name: "notiType" })
  type: NotifyOptionModel[];

  @Type(() => UserGroupOptionModel)
  @Expose({ name: "notiUserGroup" })
  group: UserGroupOptionModel[];
}

export class NotifyDraftModel {
  constructor() {
    this.isSuccess = false;
    this.notifyId = undefined;
  }

  @Expose({ name: "isSuccess" })
  isSuccess: boolean;

  @Expose({ name: "notifyId" })
  notifyId: number | undefined;
}