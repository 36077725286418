import { Expose } from 'class-transformer';

export class IncentiveModel {
  constructor() {
    this.rank = 8;
    this.total = 0;
    this.sellIn = 0;
    this.marketShare = 0;
    this.distribution = 0;
    this.centerName = '';
    this.supervisorPosition = '';
    this.isOwnTeam = true;
  }

  @Expose({ name: 'currentRank' })
  rank: number;

  @Expose({ name: 'totalPercentage' })
  total: number;

  @Expose({ name: 'sellInPercentage' })
  sellIn: number;

  @Expose({ name: 'marketSharePercentage' })
  marketShare: number;

  @Expose({ name: 'distributionPercentage' })
  distribution: number;

  centerName: string;
  supervisorPosition: string;
  isOwnTeam: boolean;
}
