import { OfflineIndex } from './index';
const offline = new OfflineIndex();

import { OfflineVisit } from './visit';
const offlineVisit = new OfflineVisit();

export class OfflineTodayActivity {
  async fetchTodayActivity() {
    let data = await offline.getTodayActivity();
    let items: any = await offline.fetchWithOrg(data);
    console.log('fetchTodayActivity', items);

    await Promise.all(
      items?.map(async (item: any) => {
        let checkIn = await offlineVisit.findVisit(item.customerId, 'in');
        if (checkIn) {
          item.checkInData = await this.formatVisitTime(checkIn?.visitDate);
          let checkOut = await offlineVisit.findVisit(item.customerId, 'out');
          if (checkOut) {
            item.checkOutData = await this.formatVisitTime(checkOut?.visitDate);
          }
        }
      })
    );

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        incentive: null,
        salesPerformance: null,
        sumAssitantSalePerformanceData: null,
        marketShareACN: null,
        mskSubAgent: null,
        MSKSubAgentFilter: null,
        todayActivityItems: items ?? [],
        todayActivityMenu: [],
        eActivityItems: [],
        updatetedDate: null,
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async formatVisitTime(data: string) {
    let d = new Date(data);
    let h = d.getHours();
    let m = d.getMinutes();
    return 'เข้า: ' + h + ':' + m;
  }
}
