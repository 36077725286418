import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { CustomerPlanVisitModel, EmployeePreVisitModel, OutRangeVisitItemModel, OutRangeVisitReasonModel, SuccessPreVisitModel, SuccessSurveyModel, SurveyItemModel } from '@/models/classes/plan/visit';
import { SuccessModel } from '@/models/classes/success';

const service = new Service.VisitService();

// Interface
interface VisitState {
  customer: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: CustomerPlanVisitModel;
    total: number;
  };
  checkIn: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  checkOut: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  employee: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: EmployeePreVisitModel;
  };
  create: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessPreVisitModel;
  };
  update: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessPreVisitModel;
  };
  survey: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: {
      common: SurveyItemModel[];
      adhoc: SurveyItemModel[];
      activity: SurveyItemModel[];
    };
    total?: number;
  };
  surveyManage: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessSurveyModel;
  };
  outRangeReason: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: OutRangeVisitItemModel[];
  },
  // menu: {
  //   network: {
  //     code: ServiceStatus;
  //     error?: string;
  //   };
  //   data?: MenuCallPlan
  // },
}

// Initialize State
const initialState: VisitState = {
  customer: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  checkIn: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  checkOut: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  employee: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  create: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  update: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  survey: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: {
      common: [],
      adhoc: [],
      activity: [],
    },
    total: undefined,
  },
  surveyManage: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  outRangeReason: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined
  },
  // menu: {
  //   network: {
  //     code: ServiceStatus.idle,
  //     error: undefined
  //   },
  //   data: undefined
  // },
};

// Reducer
export const slice = createSlice({
  name: 'CallPlan',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch
    //MARK: On plan
    builder.addCase(onFetchGetVisitCustomer.pending, (state) => {
      state.customer.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetVisitCustomer.fulfilled, (state, action) => {
      state.customer.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.customer.data = action.payload;
      }
    });
    builder.addCase(onFetchGetVisitCustomer.rejected, (state, action) => {
      state.customer.network.code = ServiceStatus.failed;
      state.customer.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: Offplan
    builder.addCase(onFetchGetVisitCustomerOffPlan.pending, (state) => {
      state.customer.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetVisitCustomerOffPlan.fulfilled, (state, action) => {
      state.customer.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.customer.data = action.payload;
      }
    });
    builder.addCase(onFetchGetVisitCustomerOffPlan.rejected, (state, action) => {
      state.customer.network.code = ServiceStatus.failed;
      state.customer.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: Out range reason
    builder.addCase(fetchGetOutRangeVisitReason.pending, (state) => {
      state.outRangeReason.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchGetOutRangeVisitReason.fulfilled, (state, action) => {
      state.outRangeReason.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        // console.log('*** state.outRangeReason.data', action.payload)
        state.outRangeReason.data = action.payload;
      }
    });
    builder.addCase(fetchGetOutRangeVisitReason.rejected, (state, action) => {
      state.outRangeReason.network.code = ServiceStatus.failed;
      state.outRangeReason.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: PostCheckIn Visit
    builder.addCase(onFetchPostCheckInEmployeeVisit.pending, (state) => {
      state.checkIn.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchPostCheckInEmployeeVisit.fulfilled, (state, action) => {
      state.checkIn.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.checkIn.data = action.payload;
      }
    });
    builder.addCase(onFetchPostCheckInEmployeeVisit.rejected, (state, action) => {
      state.checkIn.network.code = ServiceStatus.failed;
      state.checkIn.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: PostCheckIn offplan
    builder.addCase(onFetchPostCheckInEmployeeVisitOffPlan.pending, (state) => {
      state.checkIn.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchPostCheckInEmployeeVisitOffPlan.fulfilled, (state, action) => {
      state.checkIn.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.checkIn.data = action.payload;
      }
    });
    builder.addCase(onFetchPostCheckInEmployeeVisitOffPlan.rejected, (state, action) => {
      state.checkIn.network.code = ServiceStatus.failed;
      state.checkIn.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: checkout visit
    builder.addCase(onFetchPostCheckOutEmployeeVisit.pending, (state) => {
      state.checkOut.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchPostCheckOutEmployeeVisit.fulfilled, (state, action) => {
      state.checkOut.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.checkOut.data = action.payload;
      }
    });
    builder.addCase(onFetchPostCheckOutEmployeeVisit.rejected, (state, action) => {
      state.checkOut.network.code = ServiceStatus.failed;
      state.checkOut.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: previsit
    builder.addCase(onFetchGetEmployeePreVisit.pending, (state) => {
      state.employee.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetEmployeePreVisit.fulfilled, (state, action) => {
      state.employee.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.employee.data = action.payload;
      }
    });
    builder.addCase(onFetchGetEmployeePreVisit.rejected, (state, action) => {
      state.employee.network.code = ServiceStatus.failed;
      state.employee.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: previsit - add
    builder.addCase(onFetchAddEmployeePreVisit.pending, (state) => {
      state.create.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchAddEmployeePreVisit.fulfilled, (state, action) => {
      state.create.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.create.data = action.payload;
      }
    });
    builder.addCase(onFetchAddEmployeePreVisit.rejected, (state, action) => {
      state.create.network.code = ServiceStatus.failed;
      state.create.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: previsit - update
    builder.addCase(onFetchUpdateEmployeePreVisit.pending, (state) => {
      state.update.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchUpdateEmployeePreVisit.fulfilled, (state, action) => {
      state.update.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.update.data = action.payload;
      }
    });
    builder.addCase(onFetchUpdateEmployeePreVisit.rejected, (state, action) => {
      state.update.network.code = ServiceStatus.failed;
      state.update.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: survey
    builder.addCase(onFetchGetListSurvey.pending, (state) => {
      state.survey.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetListSurvey.fulfilled, (state, action) => {
      state.survey.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.survey.data = action.payload.data;
        state.survey.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetListSurvey.rejected, (state, action) => {
      state.survey.network.code = ServiceStatus.failed;
      state.survey.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    //MARK: survey management
    builder.addCase(onFetchPostSurveyManagement.pending, (state) => {
      state.surveyManage.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchPostSurveyManagement.fulfilled, (state, action) => {
      state.surveyManage.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.surveyManage.data = action.payload;
      }
    });
    builder.addCase(onFetchPostSurveyManagement.rejected, (state, action) => {
      state.surveyManage.network.code = ServiceStatus.failed;
      state.surveyManage.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchGetVisitCustomer = createAsyncThunk('visit/customer', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetVisitCustomer(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetVisitCustomerOffPlan = createAsyncThunk('visit/customer/offplan', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetVisitCustomerOffPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});
export const fetchGetOutRangeVisitReason = createAsyncThunk('visit/customer/outrange', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetOutRangeVisitReason(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});
export const onFetchPostCheckInEmployeeVisit = createAsyncThunk('visit/customer/checkin', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostCheckInEmployeeVisit(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchPostCheckInEmployeeVisitOffPlan = createAsyncThunk('visit/customer/checkin/offPlan', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostCheckInEmployeeVisitOffPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchPostCheckOutEmployeeVisit = createAsyncThunk('visit/customer/checkout', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostCheckOutEmployeeVisit(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetEmployeePreVisit = createAsyncThunk('pre-visit/employee', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetEmployeePreVisit(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchAddEmployeePreVisit = createAsyncThunk('pre-visit/employee/add', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchAddEmployeePreVisit(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchUpdateEmployeePreVisit = createAsyncThunk('pre-visit/employee/update', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchUpdateEmployeePreVisit(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetListSurvey = createAsyncThunk('survey/list', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetListSurvey(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchPostSurveyManagement = createAsyncThunk('survey/post', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchPostSurveyManagement(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
