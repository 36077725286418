import { Expose, Type } from "class-transformer";

export class ActivityItemModel {
  constructor() {
    this.planId = 0;
    this.id = 0;
    this.name = "";
    // this.backgroudColor = '';
    // this.fontColor = '';
    // this.borderColor = '';
    this.isOffPlan = false;
    this.isInCallPlan = false;
    this.isEActivity = false;
    this.date = undefined;
  }

  @Expose({ name: "customerPlanId" })
  planId: number;

  @Expose({ name: "customerId" })
  id: number;

  @Expose({ name: "customerName" })
  name: string;

  @Expose({ name: "checkInData" })
  checkIn?: string;

  @Expose({ name: "checkOutData" })
  checkOut?: string;

  @Expose({ name: "eActivityDisplayDate" })
  date?: string;
  
  @Expose({ name: "dateStartEnd" })
  duration?: string;

  // backgroudColor: string;
  // fontColor: string;
  // borderColor: string;
  
  @Expose({ name: "isOffPlan" })
  isOffPlan?: boolean;

  isEActivity: boolean;
  isInCallPlan: boolean;

  get isChecked() {
    return this.checkIn!=null ? true:false;
  }
}
