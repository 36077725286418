import axios from 'axios';
import * as qs from 'qs';

//Services
class APIBusinessReview {
  async getListBusinessReviewBySalesOrg(selectQuarter: string | any) {
    let params: any = {};

    if (selectQuarter != null) {
      params['selectQuarter'] = selectQuarter;
    }

    let path = `/api/BusinessReview/ListBusinessReviewBySalesOrg?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getListBusinessReviewOfOwner(selectQuarter: string | any, SelectCategory: string | any) {
    let params: any = {};

    if (selectQuarter != null) {
      params['selectQuarter'] = selectQuarter;
    }
    if (SelectCategory != null) {
      params['selectCategory'] = SelectCategory;
    }

    let path = `/api/BusinessReview/ListBusinessReviewOfOwner?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getListSendToManager() {
    let path = `/api/BusinessReview/ListSendToManager`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getSearchCustomer(searchText: string | any) {
    let params: any = {};

    if (searchText != null) {
      params['searchText'] = searchText;
    }

    let path = `/api/BusinessReview/SearchCustomer?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getListBusinessReviewBySalesEmployee(params: any = {}) {
    let path = `/api/BusinessReview/ListBusinessReviewBySalesEmployee?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getBusinessReviewDetail(businessReviewId: number | any) {
    let params: any = {};

    if (businessReviewId != null) {
      params['businessReviewId'] = businessReviewId;
    }

    let path = `/api/BusinessReview/GetBusinessReview?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  //--------------------- POST ----------------------------
  async postSubmitSendToManager(data: any) {
    try {
      const res = await axios.post(`/api/BusinessReview/SubmitSendToManager`, data);
      return res.data;
    } catch (err) {
      return err;
    }
  }

  async postCreateBusinessReviewPlan(data: any) {
    try {
      const res = await axios.post(`/api/BusinessReview/CreateBusinessReviewPlan`, data);
      return res.data;
    } catch (err) {
      return err;
    }
  }

  async postCreateBusinessReviewActual(data: any) {
    try {
      const res = await axios.post(`/api/BusinessReview/CreateBusinessReviewActual`, data);
      return res.data;
    } catch (err) {
      return err;
    }
  }

  async postCreateBusinessReviewTarget(data: any) {
    try {
      const res = await axios.post(`/api/BusinessReview/CreateBusinessReviewTarget`, data);
      return res.data;
    } catch (err) {
      return err;
    }
  }
}

export default APIBusinessReview;
