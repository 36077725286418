import { OfflineServices } from '@/utils/offline';
import { OfflineIndex } from './index';
import { Stores } from '@/components/_models/enums/stores';

const offlineIndex = new OfflineIndex();
const offline = new OfflineServices();

export class OfflineVisit {
  async storeCheckIn(data: any, isOnPlan: boolean) {
    let customerRefCode = await offlineIndex.uuidv4();
    let selectedOrg = await offlineIndex.get('offline_selectedOrg');
    let syncData = await offlineIndex.get('offline_syncData');

    let key = selectedOrg.SelectedSaleOrgCriteriaId + '_' + data.customerId + '_in';

    if (isOnPlan) {
      let payload = {
        saleOrgCriteriaId: selectedOrg.SelectedSaleOrgCriteriaId,
        appVersion: syncData.appVersion,
        customerPlanID: data.customerPlanId,
        customerRefCode: customerRefCode,
        visitDate: new Date().toISOString(),
        latitude: data.latitude,
        longitude: data.longitude,
        visitPic: data.visitPic,
      };

      let OffSv = new OfflineServices();
      await OffSv.store(Stores.Visit, payload, key);

      let result = {
        result: true,
        responseCode: '200',
        responseMessage: 'Success',
        responseDataSource: 'offline',
        isCalled: false,
        data: {
          planId: data?.planID,
          customerPlanId: data?.customerPlanId,
          routePlanId: data?.routePlanId,
          result: true,
          isSuccess: true,
          message: 'SUCCESS',
        },
        error: null,
        xTotalCount: 0,
      };
      return result;
    } else {
      let payload = {
        saleOrgCriteriaId: selectedOrg.SelectedSaleOrgCriteriaId,
        appVersion: syncData.appVersion,
        customerId: data.customerId,
        customerRefCode: customerRefCode,
        visitDate: new Date().toISOString(),
        latitude: data.latitude,
        longitude: data.longitude,
        visitPic: data.visitPic,
      };

      let result = {
        result: true,
        responseCode: '200',
        responseMessage: 'Success',
        responseDataSource: 'db',
        isCalled: false,
        data: {
          planId: 0,
          customerPlanId: 0,
          routePlanId: 0,
          result: true,
          isSuccess: true,
          message: 'SUCCESS',
        },
        error: null,
        xTotalCount: 0,
      };
      return result;
    }
  }

  async storeCheckOut(data: any, isOnPlan: boolean) {
    let customerRefCode = await offlineIndex.uuidv4();
    let selectedOrg = await offlineIndex.get('offline_selectedOrg');
    let syncData = await offlineIndex.get('offline_syncData');

    let key = selectedOrg.SelectedSaleOrgCriteriaId + '_' + data.customerId + '_out';

    if (isOnPlan) {
      let payload = {
        saleOrgCriteriaId: selectedOrg.SelectedSaleOrgCriteriaId,
        appVersion: syncData.appVersion,
        customerPlanID: data.customerPlanId,
        customerRefCode: customerRefCode,
        visitDate: new Date().toISOString(),
        latitude: data.latitude,
        longitude: data.longitude,
        note: data.note,
      };

      let OffSv = new OfflineServices();
      await OffSv.store(Stores.Visit, payload, key);

      let result = {
        result: true,
        responseCode: '200',
        responseMessage: 'Success',
        responseDataSource: 'offline',
        isCalled: false,
        data: {
          planId: data?.planID,
          customerPlanId: data?.customerPlanId,
          routePlanId: data?.routePlanId,
          result: true,
          isSuccess: true,
          message: 'SUCCESS',
        },
        error: null,
        xTotalCount: 0,
      };
      return result;
    } else {
      return undefined;
    }
  }

  async storePrevisit(data: any) {
    let customerRefCode = await offlineIndex.uuidv4();
    let selectedOrg = await offlineIndex.get('offline_selectedOrg');
    let syncData = await offlineIndex.get('offline_syncData');

    let key = selectedOrg.SelectedSaleOrgCriteriaId + '_' + data.customerId;

    let payload = {
      saleOrgCriteriaId: selectedOrg.SelectedSaleOrgCriteriaId,
      appVersion: syncData.appVersion,
      customerId: data.customerId,
      customerRefCode: customerRefCode,
      ...data,
    };

    let OffSv = new OfflineServices();
    await OffSv.store(Stores.PreVisit, payload, key);

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        result: true,
        isSuccess: true,
        message: 'SUCCESS',
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async updatePrevisit(data: any) {
    let customerRefCode = await offlineIndex.uuidv4();
    let selectedOrg = await offlineIndex.get('offline_selectedOrg');
    let syncData = await offlineIndex.get('offline_syncData');

    let key = selectedOrg.SelectedSaleOrgCriteriaId + '_' + data.customerId;

    let payload = {
      saleOrgCriteriaId: selectedOrg.SelectedSaleOrgCriteriaId,
      appVersion: syncData.appVersion,
      customerId: data.customerId,
      customerRefCode: customerRefCode,
      ...data,
    };

    let OffSv = new OfflineServices();
    await OffSv.update(Stores.PreVisit, payload, key);

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        result: true,
        isSuccess: true,
        message: 'SUCCESS',
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async findVisit(id: number | string, type: string) {
    let selectedOrg = await offlineIndex.get('offline_selectedOrg');
    let key = selectedOrg.SelectedSaleOrgCriteriaId + '_' + id + '_' + type;
    return await offline.get(Stores.Visit, key);
  }

  async findPreVisit(id: number | String) {
    let selectedOrg = await offlineIndex.get('offline_selectedOrg');
    let key = selectedOrg.SelectedSaleOrgCriteriaId + '_' + id;
    let data = await offline.get(Stores.PreVisit, key);
    if (data) {
      let result = {
        result: true,
        responseCode: '200',
        responseMessage: 'Success',
        responseDataSource: 'offline',
        isCalled: false,
        data: data,
        error: null,
        xTotalCount: 0,
      };
      return result;
    } else {
      let result = {
        result: true,
        responseCode: '200',
        responseMessage: 'Success',
        responseDataSource: 'offline',
        isCalled: false,
        data: null,
        error: null,
        xTotalCount: 0,
      };
      return result;
    }
  }
}
