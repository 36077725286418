import { ActivityItemModel } from './activity_items';
import { ActivityMenuModel } from './activity_menu';
import { Expose, Type } from 'class-transformer';

export class TodayActivityModel {
  constructor() {
    this.activityItems = [];
    this.activityMenu = [];
  }

  @Expose({ name: 'todayActivityItems' })
  @Type(() => ActivityItemModel)
  activityItems: ActivityItemModel[];

  @Expose({ name: 'todayActivityMenu' })
  @Type(() => ActivityMenuModel)
  activityMenu: ActivityMenuModel[];
}
