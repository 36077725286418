import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type } from "class-transformer";

export class TypeOptionModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "usageInfoName" })
  text: string;

  @Expose({ name: "usageInfoId" })
  value: string | number;
}

export class StatusOptionModel {
  constructor() {
    this.text = "";
    this.value = "";
    this.order = 0;
  }

  @Expose({ name: "statusName" })
  text: string;

  @Expose({ name: "statusCode" })
  value: string | number;

  @Expose({ name: "orderRank" })
  order: number;
}

export class NotifyOptionModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "notifyTypeName" })
  text: string;

  @Expose({ name: "notifyTypeId" })
  value: string | number;
}


export class UserGroupOptionModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "notifyUserGroupName" })
  text: string;

  @Expose({ name: "notifyUserGroupId" })
  value: string | number;
}
