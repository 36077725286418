import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Service from '@/services';
import { mergeObjects } from '@/utils/utils';
import { SumCallPlanDashboardListModel, SumCallPlanModel } from '@/models/classes/report/sum_call_plan';
const service = new Service.ReportDashboardSumCallPlanService();

// Interface
interface DashboardSummaryCallPlanState {
  callPlan: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SumCallPlanModel[];
    updatedDate?: string;
    total?: number;
  };
  filter: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  callPlanDashboard: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    // data?: SumCallPlanDashboardListModel[];
    data?: any;
    updatedDate?: string;
    total?: number;
  };
  callPlanDashboardDetail: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    // data?: SumCallPlanDashboardListModel[];
    data?: any;
    updatedDate?: string;
    total?: number;
    all?: any;
  };
}

// Initialize State
const initialState: DashboardSummaryCallPlanState = {
  callPlan: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    updatedDate: undefined,
    total: 0,
  },
  filter: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  callPlanDashboard: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    updatedDate: undefined,
    total: 0,
  },
  callPlanDashboardDetail: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    updatedDate: undefined,
    all: undefined,
    total: 0,
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const dashboardCallPlanSlice = createSlice({
  name: 'dashboard_sum_call_plan',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    // Fetch SaleReceiveMoney
    builder.addCase(onFetchSummaryCallPlanFilter.pending, (state, action) => {
      state.filter.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSummaryCallPlanFilter.fulfilled, (state, action) => {
      state.filter.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.filter.data = action.payload;
      } else {
        state.filter.data = undefined;
      }
    });
    builder.addCase(onFetchSummaryCallPlanFilter.rejected, (state, action) => {
      state.filter.network.code = ServiceStatus.failed;
      state.filter.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSummaryCallPlan.pending, (state, action) => {
      state.callPlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSummaryCallPlan.fulfilled, (state, action) => {
      state.callPlan.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null && action.payload.data.list) {
        state.callPlan.data = action.payload.data.list;
        state.callPlan.updatedDate = action.payload.data.updatedDate;
        state.callPlan.total = action.payload.total;
      } else {
        state.callPlan.data = undefined;
      }
    });
    builder.addCase(onFetchSummaryCallPlan.rejected, (state, action) => {
      state.callPlan.network.code = ServiceStatus.failed;
      state.callPlan.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchMoreSummaryCallPlan.pending, (state, action) => {
      state.callPlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchMoreSummaryCallPlan.fulfilled, (state, action) => {
      state.callPlan.network.code = ServiceStatus.succeeded;
      let oldData = new Array();
      if (state.callPlan.data) {
        oldData = [...state.callPlan.data];
      }
      console.log(oldData);
      if (action.payload && action.payload != null && action.payload.data.list) {
        state.callPlan.data = [...oldData, ...action.payload.data.list];
        console.log(state.callPlan.data);
        state.callPlan.total = action.payload.total;
      } else {
        state.callPlan.data = undefined;
      }
    });
    builder.addCase(onFetchMoreSummaryCallPlan.rejected, (state, action) => {
      state.callPlan.network.code = ServiceStatus.failed;
      state.callPlan.network.error = (action.payload as string) || 'Failed to fetch data';
    });
    builder.addCase(onFetchSummaryCallPlanDashboard.pending, (state, action) => {
      state.callPlanDashboard.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSummaryCallPlanDashboard.fulfilled, (state, action) => {
      state.callPlanDashboard.network.code = ServiceStatus.succeeded;
      // let oldData = new Array();
      // if (state.callPlanDashboard.data) {
      //   oldData = [...state.callPlanDashboard.data];
      // }
      // console.log(oldData);
      if (action.payload && action.payload != null && action.payload.data.list) {
        state.callPlanDashboard.data = [...action.payload.data.list];
        state.callPlanDashboard.updatedDate = action.payload.data.updatedDate;
        state.callPlanDashboard.total = action.payload.total;
      } else {
        state.callPlanDashboard.data = undefined;
      }
    });
    builder.addCase(onFetchSummaryCallPlanDashboard.rejected, (state, action) => {
      state.callPlanDashboard.network.code = ServiceStatus.failed;
      state.callPlanDashboard.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSummaryCallPlanDashboardMore.pending, (state, action) => {
      state.callPlanDashboard.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSummaryCallPlanDashboardMore.fulfilled, (state, action) => {
      state.callPlanDashboard.network.code = ServiceStatus.succeeded;
      let oldData = new Array();
      if (state.callPlanDashboard.data) {
        oldData = [...state.callPlanDashboard.data];
      }
      console.log(oldData);
      if (action.payload && action.payload != null && action.payload.data.list) {
        state.callPlanDashboard.data = [...oldData, ...action.payload.data.list];
        state.callPlanDashboard.updatedDate = action.payload.data.updatedDate;
        state.callPlanDashboard.total = action.payload.total;
      } else {
        state.callPlanDashboard.data = undefined;
      }
    });
    builder.addCase(onFetchSummaryCallPlanDashboardMore.rejected, (state, action) => {
      state.callPlanDashboard.network.code = ServiceStatus.failed;
      state.callPlanDashboard.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSummaryCallPlanDashboardDetail.pending, (state, action) => {
      state.callPlanDashboardDetail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSummaryCallPlanDashboardDetail.fulfilled, (state, action) => {
      state.callPlanDashboardDetail.network.code = ServiceStatus.succeeded;
      // console.log('payload', action.payload)
      if (action.payload && action.payload != null && action.payload.data.list) {
        state.callPlanDashboardDetail.data = [...action.payload.data.list];
        state.callPlanDashboardDetail.total = action.payload.total;
        state.callPlanDashboardDetail.all = action.payload.data;
      } else {
        state.callPlanDashboardDetail.data = undefined;
      }
    });
    builder.addCase(onFetchSummaryCallPlanDashboardDetail.rejected, (state, action) => {
      state.callPlanDashboardDetail.network.code = ServiceStatus.failed;
      state.callPlanDashboardDetail.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

export const onFetchSummaryCallPlanFilter = createAsyncThunk('dashboard/summary/call_plan/filter', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSumCallPlanFilter(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSummaryCallPlan = createAsyncThunk('dashboard/summary/call_plan', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSumCallPlan(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchMoreSummaryCallPlan = createAsyncThunk('dashboard/summary/call_plan/more', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSumCallPlan(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

//--------------------- New Callplan dashboard
export const onFetchSummaryCallPlanDashboard = createAsyncThunk('dashboard/summary/call_plan_dashboard', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSumCallPlanDashboard(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSummaryCallPlanDashboardMore = createAsyncThunk('dashboard/summary/call_plan_dashboard_more', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSumCallPlanDashboard(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSummaryCallPlanDashboardDetail = createAsyncThunk('dashboard/summary/call_plan_dashboard_detail', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSumCallPlanDashboardDetail(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const { reset, patch } = dashboardCallPlanSlice.actions;

export default dashboardCallPlanSlice.reducer;
