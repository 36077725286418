import { Expose, Type } from "class-transformer";

export class CustomerModel {
  constructor() {
    this.id = 0;
    this.code = "";
    this.name = "";
    this.date = new Date;
  }

  @Expose({ name: "customerId" })
  id: number;
  
  @Expose({ name: "customerCode" })
  code: string;
  
  @Expose({ name: "customerName" })
  name: string;
  
  @Expose({ name: "planDate" })
  @Type(() => Date)
  date: Date|null;
}

export class FilterModel {
  constructor() {
    this.text = "";
    this.value = 0;
  }

  @Expose({ name: "customerProfileFilterName" })
  text: string;
  
  @Expose({ name: "customerProfileFilterValue" })
  value: number;
}

export class CustomersModel {
  constructor() {
    this.filter = [];
    this.customers = [];
  }

  @Type(() => FilterModel)
  @Expose({ name: "customerProfileDropDown" })
  filter: FilterModel[];

  @Type(() => CustomerModel)
  @Expose({ name: "customerProfile" })
  customers: CustomerModel[];
}