import axios, { AxiosRequestConfig } from 'axios';

export class OffPlanApi {
  async verifyOffPlan(data: any) {
    try {
      const res = await axios.post(`/api/CallPlan/VerifyOffPlan`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}
