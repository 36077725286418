import { isNotEmpty } from 'class-validator';
import { format, addYears, isToday } from 'date-fns';
const { th } = require('date-fns/locale');

export const formatThaiDate = (date?: number | Date | string | undefined) => {
  if (isNotEmpty(date)) {
    if (typeof date === 'string') date = new Date(date)
    const thaiDate = addYears(date!, 543); // convert to Buddhist Era
    return format(thaiDate, 'dd MMM yy', { locale: th });
  }
  return '-';
};

export const formatThaiMonth = (date?: number | Date | string) => {
  if (isNotEmpty(date)) {
    if (typeof date === 'string') date = new Date(date)
    const thaiDate = addYears(date!, 543); // convert to Buddhist Era
    return format(thaiDate, 'MMM yy', { locale: th });
  }

  return '-';
};

export const formatThaiMonthOnly = (date?: number | Date | string) => {
  if (isNotEmpty(date)) {
    if (typeof date === 'string') date = new Date(date)
    const thaiDate = addYears(date!, 543); // convert to Buddhist Era
    return format(thaiDate, 'MMMM', { locale: th });
  }

  return '-';
};

export const formatThaiMonthFull = (date: number | Date | string | undefined) => {
  if (isNotEmpty(date)) {
    if (typeof date === 'string') date = new Date(date)
    const thaiDate = addYears(date!, 543); // convert to Buddhist Era
    return format(thaiDate, 'MMMM yyyy', { locale: th });
  }

  return '-';
};

export const formatTime = (date?: number | Date | string | undefined) => {
  if (isNotEmpty(date)) {
    if (typeof date === 'string') date = new Date(date)
    return format(date!, 'HH:mm');
  }

  return '-';
};

export const formatThaiDateTime = (date?: Date | string | undefined) => {
  if (isNotEmpty(date)) {
    if (typeof date === 'string') date = new Date(date)
    if (isToday(date!)) {
      return `${formatTime(date)}`;
    }
    return `${formatThaiDate(date)} ${formatTime(date)}`;

  }
  return '-';
};

export const formatDate = (date?: number | Date | string | undefined) => {
  if (isNotEmpty(date)) {
    if (typeof date === 'string') date = new Date(date)
    return format(date!, 'EEEE, MMM dd, yyyy');

  }
  return '-';
};

export const formatDateTimeRange = (date1?: string | number | Date | undefined, date2?: string | number | Date | undefined) => {
  if (isNotEmpty(date1) && isNotEmpty(date2)) {
    let d1 = new Date(date1!);
    d1 = addYears(d1, 543);
    let d2 = new Date(date2!);
    d2 = addYears(d2, 543);
    if (d1.getDay() == d2.getDay() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear()) {
      return `${format(d1, 'dd/MM/yyyy')} : ${format(d1, 'HH:mm')} - ${format(d2, 'HH:mm')}`;
    } else {
      return `${format(d1, 'dd/MM/yyyy: HH:mm')} - ${format(d2, 'dd/MM/yyy: HH:mm')}`;
    }
  } else if (isNotEmpty(date1)) {
    let d1 = new Date(date1!);
    d1 = addYears(d1, 543);
    return `${format(d1, 'dd/MM/yyyy: HH:mm')}`;
  }
  return '-';
};

export const formatShortDate = (date: number | string | Date) => {
  if (typeof date === 'string') date = new Date(date)
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);

  const parts = dateTimeFormat.formatToParts(date);
  return `${parts[2].value} ${parts[0].value} ${parts[4].value}`;
};

export const getPreviousMonth = () => {
  const now = new Date();
  const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  return previousMonth;
};

export const getCurrentQuarter = () => {
  return Math.floor((new Date().getMonth() + 3) / 3) % 4;
};
