import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { CallPlanModel, PlanCalendarModel, PlanCustomersModel, OffPlanCustomersModel, PlanDraftModel, AddListCustomerModel, UpdateListCustomerModel, PlanSummaryModel, CopyPlanModel, SaveDraftModel, CheckHasChangeRoutePlanModel, PlanTierModel, SendPlanModel, ToDoListModel } from '@/models/classes/plan/on_plan';

const service = new Service.OnPlanService();

// Interface
interface CallPlanState {
  callPlans: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: CallPlanModel[];
    total: number;
  };
  calendar: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: PlanCalendarModel;
  };
  plansInMonth: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: PlanCalendarModel;
  };
  plans: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: PlanCustomersModel[];
    total: number;
  };
  customers: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: OffPlanCustomersModel[];
    total: number;
  };
  summary: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: PlanSummaryModel[];
    total: number;
  };
  planByTier: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: PlanTierModel;
    total: number;
  };
  draft: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: PlanDraftModel;
  };
  addList: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: AddListCustomerModel;
  };
  updateList: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: UpdateListCustomerModel;
  };
  resetRoutePlan: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: PlanCalendarModel;
  };
  saveDraft: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SaveDraftModel;
  };
  copyPlan: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: CopyPlanModel;
  };
  hasChange: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: CheckHasChangeRoutePlanModel;
  };
  sendPlan: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SendPlanModel;
  };
  toDoList: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ToDoListModel;
  };
}

// Initialize State
const initialState: CallPlanState = {
  callPlans: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  calendar: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  plansInMonth: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  plans: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  customers: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  summary: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  planByTier: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  draft: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  addList: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  updateList: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  resetRoutePlan: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  saveDraft: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  copyPlan: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  hasChange: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  sendPlan: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  toDoList: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'CallPlan',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(onFetchGetPlanSummaryData.pending, (state) => {
      state.callPlans.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetPlanSummaryData.fulfilled, (state, action) => {
      state.callPlans.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.callPlans.data = action.payload.data;
        state.callPlans.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetPlanSummaryData.rejected, (state, action) => {
      state.callPlans.network.code = ServiceStatus.failed;
      state.callPlans.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetPlanByRoutePlanId.pending, (state) => {
      state.calendar.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetPlanByRoutePlanId.fulfilled, (state, action) => {
      state.calendar.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.calendar.data = action.payload.data;
      }
    });
    builder.addCase(onFetchGetPlanByRoutePlanId.rejected, (state, action) => {
      state.calendar.network.code = ServiceStatus.failed;
      state.calendar.network.error = (action.payload as string) || 'Failed to fetch data';
      state.calendar.data = undefined;
    });

    builder.addCase(onFetchGetCustomerByPlanDate.pending, (state) => {
      state.plans.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetCustomerByPlanDate.fulfilled, (state, action) => {
      state.plans.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.plans.data = action.payload.data;
        state.plans.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetCustomerByPlanDate.rejected, (state, action) => {
      state.plans.network.code = ServiceStatus.failed;
      state.plans.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetCloneRoutePlan.pending, (state) => {
      state.draft.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetCloneRoutePlan.fulfilled, (state, action) => {
      state.draft.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.draft.data = action.payload;
      }
    });
    builder.addCase(onFetchGetCloneRoutePlan.rejected, (state, action) => {
      state.draft.network.code = ServiceStatus.failed;
      state.draft.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchListOfCustomerOffPlan.pending, (state) => {
      state.customers.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchListOfCustomerOffPlan.fulfilled, (state, action) => {
      state.customers.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.customers.data = action.payload.data;
        state.customers.total = action.payload.total;
      }
    });
    builder.addCase(onFetchListOfCustomerOffPlan.rejected, (state, action) => {
      state.customers.network.code = ServiceStatus.failed;
      state.customers.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetSummaryRoutePlan.pending, (state) => {
      state.summary.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetSummaryRoutePlan.fulfilled, (state, action) => {
      state.summary.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.summary.data = action.payload.data;
        state.summary.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetSummaryRoutePlan.rejected, (state, action) => {
      state.summary.network.code = ServiceStatus.failed;
      state.summary.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetSummaryRoutePlanByTier.pending, (state) => {
      state.planByTier.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetSummaryRoutePlanByTier.fulfilled, (state, action) => {
      state.planByTier.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.planByTier.data = action.payload.data;
        state.planByTier.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetSummaryRoutePlanByTier.rejected, (state, action) => {
      state.planByTier.network.code = ServiceStatus.failed;
      state.planByTier.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onAddListCustomer.pending, (state) => {
      state.addList.network.code = ServiceStatus.loading;
    });
    builder.addCase(onAddListCustomer.fulfilled, (state, action) => {
      state.addList.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.addList.data = action.payload;
      }
    });
    builder.addCase(onAddListCustomer.rejected, (state, action) => {
      state.addList.network.code = ServiceStatus.failed;
      state.addList.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onUpdateListCustomer.pending, (state) => {
      state.updateList.network.code = ServiceStatus.loading;
    });
    builder.addCase(onUpdateListCustomer.fulfilled, (state, action) => {
      state.updateList.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.updateList.data = action.payload;
      }
    });
    builder.addCase(onUpdateListCustomer.rejected, (state, action) => {
      state.updateList.network.code = ServiceStatus.failed;
      state.updateList.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onResetRoutePlan.pending, (state) => {
      state.resetRoutePlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onResetRoutePlan.fulfilled, (state, action) => {
      state.resetRoutePlan.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.resetRoutePlan.data = action.payload.data;
        state.calendar.data = undefined;
        state.plans.data = undefined;
      }
    });
    builder.addCase(onResetRoutePlan.rejected, (state, action) => {
      state.resetRoutePlan.network.code = ServiceStatus.failed;
      state.resetRoutePlan.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onSubmitDraftRoutePlan.pending, (state) => {
      state.saveDraft.network.code = ServiceStatus.loading;
    });
    builder.addCase(onSubmitDraftRoutePlan.fulfilled, (state, action) => {
      state.saveDraft.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.saveDraft.data = action.payload;
      }
    });
    builder.addCase(onSubmitDraftRoutePlan.rejected, (state, action) => {
      state.saveDraft.network.code = ServiceStatus.failed;
      state.saveDraft.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onSubmitCopyPlan.pending, (state) => {
      state.copyPlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onSubmitCopyPlan.fulfilled, (state, action) => {
      state.copyPlan.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.copyPlan.data = action.payload;
      }
    });
    builder.addCase(onSubmitCopyPlan.rejected, (state, action) => {
      state.copyPlan.network.code = ServiceStatus.failed;
      state.copyPlan.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetPlanByMonthAndYear.pending, (state) => {
      state.plansInMonth.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetPlanByMonthAndYear.fulfilled, (state, action) => {
      state.plansInMonth.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.plansInMonth.data = action.payload.data;
      }
    });
    builder.addCase(onFetchGetPlanByMonthAndYear.rejected, (state, action) => {
      state.plansInMonth.network.code = ServiceStatus.failed;
      state.plansInMonth.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onCheckHasChangeRoutePlan.pending, (state) => {
      state.hasChange.network.code = ServiceStatus.loading;
    });
    builder.addCase(onCheckHasChangeRoutePlan.fulfilled, (state, action) => {
      state.hasChange.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.hasChange.data = action.payload;
      }
    });
    builder.addCase(onCheckHasChangeRoutePlan.rejected, (state, action) => {
      state.hasChange.network.code = ServiceStatus.failed;
      state.hasChange.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onSendRoutePlan.pending, (state) => {
      state.sendPlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onSendRoutePlan.fulfilled, (state, action) => {
      state.sendPlan.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.sendPlan.data = action.payload;
      }
    });
    builder.addCase(onSendRoutePlan.rejected, (state, action) => {
      state.sendPlan.network.code = ServiceStatus.failed;
      state.sendPlan.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetToDoList.pending, (state) => {
      state.toDoList.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetToDoList.fulfilled, (state, action) => {
      state.toDoList.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.toDoList.data = action.payload;
      }
    });
    builder.addCase(onFetchGetToDoList.rejected, (state, action) => {
      state.toDoList.network.code = ServiceStatus.failed;
      state.toDoList.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchGetPlanSummaryData = createAsyncThunk('callplan/on_plan/summary', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchGetPlanSummaryData();
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetPlanByRoutePlanId = createAsyncThunk('callplan/on_plan/get_plans/by_route_plan_id', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetPlanByRoutePlanId(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetCustomerByPlanDate = createAsyncThunk('callplan/on_plan/get_plans/customers', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetCustomerByPlanDate(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetCloneRoutePlan = createAsyncThunk('callplan/on_plan/clone', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetCloneRoutePlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchListOfCustomerOffPlan = createAsyncThunk('callplan/customers', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchListOfCustomerOffPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetSummaryRoutePlan = createAsyncThunk('callplan/plan/summary', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetSummaryRoutePlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetSummaryRoutePlanByTier = createAsyncThunk('callplan/plan/summary/tier', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetSummaryRoutePlanByTier(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetPlanByMonthAndYear = createAsyncThunk('callplan/plan/copy/get', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetPlanByMonthAndYear(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onAddListCustomer = createAsyncThunk('callplan/customers/add', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.addListCustomer(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onUpdateListCustomer = createAsyncThunk('callplan/customers/update', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.updateListCustomer(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onResetRoutePlan = createAsyncThunk('callplan/on_plan/reset', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.resetRoutePlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onSubmitDraftRoutePlan = createAsyncThunk('callplan/on_plan/draft/submit', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.submitDraftRoutePlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onCheckHasChangeRoutePlan = createAsyncThunk('callplan/on_plan/draft/check', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.checkHasChangeRoutePlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onSubmitCopyPlan = createAsyncThunk('callplan/on_plan/copy', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.submitCopyPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onSendRoutePlan = createAsyncThunk('callplan/on_plan/send', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.sendRoutePlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetToDoList = createAsyncThunk('callplan/on_plan/get_plans/to_do_list', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetToDoList(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
