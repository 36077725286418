import { Expose } from 'class-transformer';

export class MenuModel {
  constructor() {
    this.id = 0;
    this.name = '';
    this.iconURL = undefined;
    this.group = '';
    this.handler = '';
    this.isAccess = false;
    this.isDelete = false;
    this.isUpdate = false;
  }

  @Expose({ name: 'menuId' })
  id: number;
  @Expose({ name: 'menuName' })
  name: string;
  @Expose({ name: 'menuIconURL' })
  iconURL?: string;
  @Expose({ name: 'menuGroup' })
  group: string;
  @Expose({ name: 'menuHandler' })
  handler: string;
  @Expose({ name: 'canAccess' })
  isAccess: boolean;
  @Expose({ name: 'canDelete' })
  isDelete: boolean;
  @Expose({ name: 'canUpdate' })
  isUpdate: boolean;
}
