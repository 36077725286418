import { getIncensive } from '@/services/league';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export enum LEAGUE_TYPE {
  total = 'total',
  sellin = 'sellin',
  marketshare = 'marketshare',
  distribution = 'distribution',
}

export interface LeagueState {
  status: {
    code: ServiceStatus;
    error?: string;
  };
  data: any;
  options: {
    year: any[];
    month: any[];
    type: any[];
  };
}

const initialState: LeagueState = {
  status: {
    code: ServiceStatus.idle,
    error: undefined,
  },
  data: {},
  options: {
    year: [],
    month: [],
    type: [],
  },
};
/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const slice = createSlice({
  name: 'league',
  initialState,
  //basic state change
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    builder.addCase(fetchIncentive.pending, (state, action) => {
      state.status.code = ServiceStatus.loading;
    });
    builder.addCase(fetchIncentive.rejected, (state, action) => {
      state.status.error = action.error.message;
      state.status.code = ServiceStatus.failed;
    });
    builder.addCase(fetchIncentive.fulfilled, (state, action) => {
      state.status.code = ServiceStatus.succeeded;
      state.data = action.payload;
      if (state.options.year.length == 0 && state.options.month.length == 0 && state.options.type.length == 0) {
        state.options.year = action.payload.yearOptions;
        state.options.month = action.payload.monthOptions;
        state.options.type = action.payload.typeOptions;
      }
    });
  },
});

/**ตัวอย่างการรัน aysnc fetch data จาก api
 * @link https://redux-toolkit.js.org/api/createAsyncThunk
 * @param custonParam ค่าที่จะรับข้อมูลมาใช้งาน กำหนดเอง
 */
interface ParamsType {
  year: string | number;
  month: string | number;
  viewMode: LEAGUE_TYPE;
}
export const fetchIncentive = createAsyncThunk('getIncentive', async (param: ParamsType, thunkAPI) => {
  const data = await getIncensive(param).catch((err: any) => {
    console.error('league error', err);
    return thunkAPI.rejectWithValue(err.message);
  });
  return data;
});

export const { reset, patch } = slice.actions;

export default slice.reducer;
