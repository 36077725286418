import { Expose } from 'class-transformer';

export class SalesPerformanceModel {
  constructor() {
    this.actualLabel = '';
    this.actualPercentage = 0;
    this.actualSales = 0;
    this.actualTarget = 0;

    this.stepLabel = '';
    this.stepPercentage = 0;
    this.stepSales = 0;
    this.stepTarget = 0;

    this.targetLabel = '';
    this.targetPercentage = 0;
    this.targetSales = 0;
    this.targetTarget = 0;

    this.sellOutLabel = '';
    this.sellOutPercentage = 0;
    this.sellOutSales = 0;
    this.sellOutTarget = 0;

    this.updatedDate = undefined;
  }

  // ขายรับเงิน
  actualLabel: string;
  actualPercentage: number;
  actualSales: number;
  actualTarget: number;

  // Step
  stepLabel: string;
  @Expose({ name: 'SstepPercentage' })
  stepPercentage: number;
  stepSales: number;
  stepTarget: number;

  // ขายขน
  targetLabel: string;
  targetPercentage: number;
  @Expose({ name: 'actual' })
  targetSales: number;
  @Expose({ name: 'target' })
  targetTarget: number;

  // Sell Out
  sellOutLabel: string;
  sellOutPercentage: number;
  sellOutSales: number;
  sellOutTarget: number;

  @Expose({ name: 'updatetedDate' })
  updatedDate? : Date;

  get barChart() {
    return [
      {
        name: this.actualLabel,
        legend: 'ขายรับเงิน',
        percentags: this.actualPercentage,
        value: this.actualSales,
        total: this.actualTarget,
        unit: 'ลัง',
        color: 'primary-50',
      },
      {
        name: this.stepLabel,
        legend: 'Step',
        percentags: this.stepPercentage,
        value: this.stepSales,
        total: this.stepTarget,
        unit: 'ลัง',
        color: 'info-200',
      },
      {
        name: this.targetLabel,
        legend: 'ขายขน',
        percentags: this.targetPercentage,
        value: this.targetSales,
        total: this.targetTarget,
        unit: 'ลัง',
        color: 'primary-700',
      },
      {
        name: this.sellOutLabel,
        legend: 'Sell Out',
        percentags: this.sellOutPercentage,
        value: this.sellOutSales,
        total: this.sellOutTarget,
        unit: 'ลัง',
        color: 'secondary',
      },
    ];
  }

  radialChart(isDark: Boolean) {
    return [
      {
        value: this.actualPercentage,
        color: isDark ? '#A3FABF' : '#7DB792',
      },
      {
        value: this.stepPercentage,
        color: isDark ? '#15CFF5' : '#75C0CE',
      },
      {
        value: this.targetPercentage,
        color: isDark ? '#118850' : '#37775B',
      },
    ];
  }
}
