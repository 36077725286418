import { ActivityItemModel } from './activity_items';
import { Expose, Type } from 'class-transformer';

export class TodayEActivityModel {
  constructor() {
    this.eActivityItems = [];
  }

  @Expose({ name: 'eActivityItems' })
  @Type(() => ActivityItemModel)
  eActivityItems: ActivityItemModel[];
}
