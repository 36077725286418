import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OptionModel } from '@/models/classes/report/option';
import Service from '@/services';

import { mergeObjects } from '@/utils/utils';

const service = new Service.ReportFilterService();

export enum SALE_FILTER_TYPE {
  all,
  region,
  province,
  employee,
  customer,
  unit,
}

export enum ABL_FILTER_TYPE {
  all,
  region,
  province,
  zone,
  subZone,
  employee,
  customer,
}

export enum MSK_FILTER_TYPE {
  all,
  region,
  province,
  employee,
  customer,
}

export enum CALLPLAN_FILTER_TYPE {
  all,
  employee,
}

export enum POWER_BI_FILTER_TYPE {
  all,
  employee,
}

export enum ONTRADE_FILTER_TYPE {
  all,
  region,
  province,
  shopType,
  size,
  image,
  employee,
  customer,
}
// Interface
interface ReportFiterState {
  sale: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: {
      regions: OptionModel[];
      provinces: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      units: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
      unitsTotal: number;
    };
    original: {
      regions: OptionModel[];
      provinces: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      units: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
      unitsTotal: number;
    };
  };
  ass: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: {
      regions: OptionModel[];
      provinces: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
    };
    original: {
      regions: OptionModel[];
      provinces: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
    };
  };
  abl: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: {
      regions: OptionModel[];
      provinces: OptionModel[];
      zone: OptionModel[];
      subZone: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
      zoneTotal: number;
      subZoneTotal: number;
    };
    original: {
      regions: OptionModel[];
      provinces: OptionModel[];
      zone: OptionModel[];
      subZone: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
      zoneTotal: number;
      subZoneTotal: number;
    };
  };
  msk: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: {
      regions: OptionModel[];
      provinces: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
    };
    original: {
      regions: OptionModel[];
      provinces: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
    };
  };
  callPlan: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: {
      employee: OptionModel[];
      employeeTotal: number;
    };
    original: {
      employee: OptionModel[];
      employeeTotal: number;
    };
  };
  powerBI: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: {
      employee: OptionModel[];
      employeeTotal: number;
    };
    original: {
      employee: OptionModel[];
      employeeTotal: number;
    };
  };
  ontrade: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options: {
      regions: OptionModel[];
      provinces: OptionModel[];
      shopTypes: OptionModel[];
      sizes: OptionModel[];
      images: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
      imagesTotal: number;
      shopTypesTotal: number;
      sizesTotal: number;
    };
    original: {
      regions: OptionModel[];
      provinces: OptionModel[];
      shopTypes: OptionModel[];
      sizes: OptionModel[];
      images: OptionModel[];
      employee: OptionModel[];
      customers: OptionModel[];
      regionsTotal: number;
      provincesTotal: number;
      employeeTotal: number;
      customersTotal: number;
      imagesTotal: number;
      shopTypesTotal: number;
      sizesTotal: number;
    };
  };
}

// Initialize State
const initialState: ReportFiterState = {
  sale: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: {
      regions: [],
      provinces: [],
      employee: [],
      customers: [],
      units: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
      unitsTotal: 0,
    },
    original: {
      regions: [],
      provinces: [],
      employee: [],
      customers: [],
      units: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
      unitsTotal: 0,
    },
  },
  ass: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: {
      regions: [],
      provinces: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
    },
    original: {
      regions: [],
      provinces: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
    },
  },
  abl: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: {
      regions: [],
      provinces: [],
      zone: [],
      subZone: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
      zoneTotal: 0,
      subZoneTotal: 0,
    },
    original: {
      regions: [],
      provinces: [],
      zone: [],
      subZone: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
      zoneTotal: 0,
      subZoneTotal: 0,
    },
  },
  msk: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: {
      regions: [],
      provinces: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
    },
    original: {
      regions: [],
      provinces: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
    },
  },
  callPlan: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: {
      employee: [],

      employeeTotal: 0,
    },
    original: {
      employee: [],

      employeeTotal: 0,
    },
  },
  powerBI: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: {
      employee: [],
      employeeTotal: 0,
    },
    original: {
      employee: [],
      employeeTotal: 0,
    },
  },
  ontrade: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: {
      regions: [],
      provinces: [],
      shopTypes: [],
      sizes: [],
      images: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
      imagesTotal: 0,
      shopTypesTotal: 0,
      sizesTotal: 0,
    },
    original: {
      regions: [],
      provinces: [],
      shopTypes: [],
      sizes: [],
      images: [],
      employee: [],
      customers: [],
      regionsTotal: 0,
      provincesTotal: 0,
      employeeTotal: 0,
      customersTotal: 0,
      imagesTotal: 0,
      shopTypesTotal: 0,
      sizesTotal: 0,
    },
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const reportsFilterSlice = createSlice({
  name: 'report_filter',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    builder.addCase(onFetchSaleDashboardFilter.pending, (state, action) => {
      state.sale.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSaleDashboardFilter.rejected, (state, action) => {
      state.sale.network.error = action.error.message;
      state.sale.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchSaleDashboardFilter.fulfilled, (state, action) => {
      const original = action.payload.original;

      if (Object.keys(original).length > 0) {
        state.sale.original = original;
      }

      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.sale.options.regions = response.regions;
        state.sale.options.regionsTotal = response.regionsTotal;
      }
      if (response.hasOwnProperty('provinces')) {
        state.sale.options.provinces = response.provinces;
        state.sale.options.provincesTotal = response.provincesTotal;
      }
      if (response.hasOwnProperty('employee')) {
        state.sale.options.employee = response.employee;
        state.sale.options.employeeTotal = response.employeeTotal;
      }
      if (response.hasOwnProperty('customers')) {
        state.sale.options.customers = response.customers;
        state.sale.options.customersTotal = response.customersTotal;
      }

      if (response.hasOwnProperty('units')) {
        state.sale.options.units = response.units;
        state.sale.options.unitsTotal = response.unitsTotal;
      }

      state.sale.network.code = ServiceStatus.succeeded;
    });

    builder.addCase(onLoadMoreSaleDashboardFilter.pending, (state, action) => {
      state.sale.network.code = ServiceStatus.loading;
    });
    builder.addCase(onLoadMoreSaleDashboardFilter.rejected, (state, action) => {});
    builder.addCase(onLoadMoreSaleDashboardFilter.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.sale.options.regions.push(...response.regions);
      } else if (response.hasOwnProperty('provinces')) {
        state.sale.options.provinces.push(...response.provinces);
      } else if (response.hasOwnProperty('employee')) {
        state.sale.options.employee.push(...response.employee);
      } else if (response.hasOwnProperty('customers')) {
        state.sale.options.customers.push(...response.customers);
      } else if (response.hasOwnProperty('units')) {
        state.sale.options.units.push(...response.units);
      }

      state.sale.network.code = ServiceStatus.succeeded;
    });

    //-------------------- ASS -------------------------
    builder.addCase(onFetchAssDashboardFilter.pending, (state, action) => {
      state.ass.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchAssDashboardFilter.rejected, (state, action) => {
      state.ass.network.error = action.error.message;
      state.ass.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchAssDashboardFilter.fulfilled, (state, action) => {
      const original = action.payload.original;

      if (Object.keys(original).length > 0) {
        state.ass.original = original;
      }

      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.ass.options.regions = response.regions;
        state.ass.options.regionsTotal = response.regionsTotal;
      }
      if (response.hasOwnProperty('provinces')) {
        state.ass.options.provinces = response.provinces;
        state.ass.options.provincesTotal = response.provincesTotal;
      }
      if (response.hasOwnProperty('employee')) {
        state.ass.options.employee = response.employee;
        state.ass.options.employeeTotal = response.employeeTotal;
      }
      if (response.hasOwnProperty('customers')) {
        state.ass.options.customers = response.customers;
        state.ass.options.customersTotal = response.customersTotal;
      }

      state.ass.network.code = ServiceStatus.succeeded;
    });

    builder.addCase(onLoadMoreAssDashboardFilter.pending, (state, action) => {
      state.ass.network.code = ServiceStatus.loading;
    });
    builder.addCase(onLoadMoreAssDashboardFilter.rejected, (state, action) => {});
    builder.addCase(onLoadMoreAssDashboardFilter.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.ass.options.regions.push(...response.regions);
      } else if (response.hasOwnProperty('provinces')) {
        state.ass.options.provinces.push(...response.provinces);
      } else if (response.hasOwnProperty('employee')) {
        state.ass.options.employee.push(...response.employee);
      } else if (response.hasOwnProperty('customers')) {
        state.ass.options.customers.push(...response.customers);
      }

      state.ass.network.code = ServiceStatus.succeeded;
    });

    //--------------------- ABL ----------------------
    //MARK: ABL
    builder.addCase(onFetchAblDashboardFilter.pending, (state, action) => {
      state.abl.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchAblDashboardFilter.rejected, (state, action) => {
      state.abl.network.error = action.error.message;
      state.abl.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchAblDashboardFilter.fulfilled, (state, action) => {
      const original = action.payload.original;

      if (Object.keys(original).length > 0) {
        state.abl.original = original;
      }

      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.abl.options.regions = response.regions;
        state.abl.options.regionsTotal = response.regionsTotal;
      }
      if (response.hasOwnProperty('provinces')) {
        state.abl.options.provinces = response.provinces;
        state.abl.options.provincesTotal = response.provincesTotal;
      }
      if (response.hasOwnProperty('zone')) {
        state.abl.options.zone = response.zone;
        state.abl.options.zoneTotal = response.zoneTotal;
      }
      if (response.hasOwnProperty('subZone')) {
        state.abl.options.subZone = response.subZone;
        state.abl.options.subZoneTotal = response.subZoneTotal;
      }
      if (response.hasOwnProperty('employee')) {
        state.abl.options.employee = response.employee;
        state.abl.options.employeeTotal = response.employeeTotal;
      }
      if (response.hasOwnProperty('customers')) {
        state.abl.options.customers = response.customers;
        state.abl.options.customersTotal = response.customersTotal;
      }

      state.abl.network.code = ServiceStatus.succeeded;
    });

    builder.addCase(onLoadMoreAblDashboardFilter.pending, (state, action) => {
      state.abl.network.code = ServiceStatus.loading;
    });
    builder.addCase(onLoadMoreAblDashboardFilter.rejected, (state, action) => {});
    builder.addCase(onLoadMoreAblDashboardFilter.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.abl.options.regions.push(...response.regions);
      } else if (response.hasOwnProperty('provinces')) {
        state.abl.options.provinces.push(...response.provinces);
      } else if (response.hasOwnProperty('zone')) {
        state.abl.options.zone.push(...response.zone);
      } else if (response.hasOwnProperty('subZone')) {
        state.abl.options.subZone.push(...response.subZone);
      } else if (response.hasOwnProperty('employee')) {
        state.abl.options.employee.push(...response.employee);
      } else if (response.hasOwnProperty('customers')) {
        state.abl.options.customers.push(...response.customers);
      }

      state.abl.network.code = ServiceStatus.succeeded;
    });

    //--------------------- MSK ----------------------------------
    //MARK: MSK
    builder.addCase(onFetchMskDashboardFilter.pending, (state, action) => {
      state.msk.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchMskDashboardFilter.rejected, (state, action) => {
      state.msk.network.error = action.error.message;
      state.msk.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchMskDashboardFilter.fulfilled, (state, action) => {
      const original = action.payload.original;

      if (Object.keys(original).length > 0) {
        state.msk.original = original;
      }

      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.msk.options.regions = response.regions;
        state.msk.options.regionsTotal = response.regionsTotal;
      }
      if (response.hasOwnProperty('provinces')) {
        state.msk.options.provinces = response.provinces;
        state.msk.options.provincesTotal = response.provincesTotal;
      }
      if (response.hasOwnProperty('employee')) {
        state.msk.options.employee = response.employee;
        state.msk.options.employeeTotal = response.employeeTotal;
      }
      if (response.hasOwnProperty('customers')) {
        state.msk.options.customers = response.customers;
        state.msk.options.customersTotal = response.customersTotal;
      }

      state.msk.network.code = ServiceStatus.succeeded;
    });

    builder.addCase(onLoadMoreMskDashboardFilter.pending, (state, action) => {
      state.msk.network.code = ServiceStatus.loading;
    });
    builder.addCase(onLoadMoreMskDashboardFilter.rejected, (state, action) => {});
    builder.addCase(onLoadMoreMskDashboardFilter.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.msk.options.regions.push(...response.regions);
      } else if (response.hasOwnProperty('provinces')) {
        state.msk.options.provinces.push(...response.provinces);
      } else if (response.hasOwnProperty('employee')) {
        state.msk.options.employee.push(...response.employee);
      } else if (response.hasOwnProperty('customers')) {
        state.msk.options.customers.push(...response.customers);
      }

      state.msk.network.code = ServiceStatus.succeeded;
    });

    //--------------------- Call Plan ----------------------------------
    //MARK: Call Plan
    builder.addCase(onFetchCallPlanDashboardFilter.pending, (state, action) => {
      state.callPlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchCallPlanDashboardFilter.rejected, (state, action) => {
      state.callPlan.network.error = action.error.message;
      state.callPlan.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchCallPlanDashboardFilter.fulfilled, (state, action) => {
      const original = action.payload.original;

      if (Object.keys(original).length > 0) {
        state.callPlan.original = original;
      }

      const response = action.payload.response;

      if (response.hasOwnProperty('employee')) {
        state.callPlan.options.employee = response.employee;
        state.callPlan.options.employeeTotal = response.employeeTotal;
      }

      state.callPlan.network.code = ServiceStatus.succeeded;
    });

    builder.addCase(onLoadMoreCallPlanDashboardFilter.pending, (state, action) => {
      state.callPlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onLoadMoreCallPlanDashboardFilter.rejected, (state, action) => {});
    builder.addCase(onLoadMoreCallPlanDashboardFilter.fulfilled, (state, action) => {
      const response = action.payload.response;
      if (response.hasOwnProperty('employee')) {
        state.msk.options.employee.push(...response.employee);
      }
      state.msk.network.code = ServiceStatus.succeeded;
    });

    //----------------------Power BI-----------------------------------
    //MARK: Power BI
    //onFetchPowerBIDashboardFilter
    builder.addCase(onFetchPowerBIDashboardFilter.pending, (state, action) => {
      state.powerBI.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchPowerBIDashboardFilter.rejected, (state, action) => {
      state.powerBI.network.error = action.error.message;
      state.powerBI.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchPowerBIDashboardFilter.fulfilled, (state, action) => {
      const original = action.payload.original;

      if (Object.keys(original).length > 0) {
        state.powerBI.original = original;
      }

      const response = action.payload.response;

      if (response.hasOwnProperty('employee')) {
        state.powerBI.options.employee = response.employee;
        state.powerBI.options.employeeTotal = response.employeeTotal;
      }

      state.powerBI.network.code = ServiceStatus.succeeded;
    });

    //------------------------On trade-------------------------------
    //MARK: Ontrade
    builder.addCase(onFetchOntradeDashboardFilter.pending, (state, action) => {
      state.ontrade.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchOntradeDashboardFilter.rejected, (state, action) => {
      state.ontrade.network.error = action.error.message;
      state.ontrade.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchOntradeDashboardFilter.fulfilled, (state, action) => {
      const original = action.payload.original;

      if (Object.keys(original).length > 0) {
        state.ontrade.original = original;
      }

      const response = action.payload.response;
      if (response.hasOwnProperty('regions')) {
        state.ontrade.options.regions = response.regions;
        state.ontrade.options.regionsTotal = response.regionsTotal;
      }
      if (response.hasOwnProperty('provinces')) {
        state.ontrade.options.provinces = response.provinces;
        state.ontrade.options.provincesTotal = response.provincesTotal;
      }

      if (response.hasOwnProperty('employee')) {
        state.ontrade.options.employee = response.employee;
        state.ontrade.options.employeeTotal = response.employeeTotal;
      }
      if (response.hasOwnProperty('customers')) {
        state.ontrade.options.customers = response.customers;
        state.ontrade.options.customersTotal = response.customersTotal;
      }

      if (response.hasOwnProperty('images')) {
        state.ontrade.options.images = response.images;
        state.ontrade.options.imagesTotal = response.imagesTotal;
      }
      if (response.hasOwnProperty('shopTypes')) {
        state.ontrade.options.shopTypes = response.shopTypes;
        state.ontrade.options.shopTypesTotal = response.shopTypesTotal;
      }

      if (response.hasOwnProperty('sizes')) {
        state.ontrade.options.sizes = response.sizes;
        state.ontrade.options.sizesTotal = response.sizesTotal;
      }

      // console.log("slicer ", action.payload,state.data.options.ontrade)

      state.ontrade.network.code = ServiceStatus.succeeded;
    });
  },
});

export const onFetchSaleDashboardFilter = createAsyncThunk('report_filter/sale', async (param: any = {}, thunkAPI) => {
  try {
    const query = Object.keys(param).length == 0 ? { type: 0 } : param;
    const type = query.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };
    switch (type) {
      case SALE_FILTER_TYPE.customer:
        if (query.action && type == SALE_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.customer = {
            selectValue: query.data.customer.optionValue,
            limit: 20,
            offset: 0,
          };
        }
      case SALE_FILTER_TYPE.employee:
        if (query.action && type == SALE_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.employee = {
            selectValue: query.data.employee.optionValue,
            limit: 20,
            offset: 0,
          };
        }
      case SALE_FILTER_TYPE.province:
        payload.province = query.data.province.optionValue;
      case SALE_FILTER_TYPE.region:
        payload.regionId = Number(query.data.region.optionValue);
      case SALE_FILTER_TYPE.unit:
        payload.province = query?.data?.unit?.optionValue;
      case SALE_FILTER_TYPE.all:
        break;
    }

    const response = await service.postSaleDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));

    // Init
    let original: any = {};
    if (Object.keys(param).length === 0) {
      original = { ...response };
    }

    return { original: original, response: response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onLoadMoreSaleDashboardFilter = createAsyncThunk('report_filter/sale/loadmore', async (param: any = {}, thunkAPI) => {
  try {
    const type = param.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };
    switch (type) {
      case SALE_FILTER_TYPE.customer:
        if (param.action && type == SALE_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: param?.keyword,
            limit: param?.limit,
            offset: param?.offset,
          };
        } else {
          payload.customer = {
            selectValue: SALE_FILTER_TYPE.customer < type ? param?.data?.customer?.optionValue : 0,
            limit: param?.limit,
            offset: param?.offset,
          };
        }
      case SALE_FILTER_TYPE.employee:
        if (param.action && type == SALE_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: param?.keyword,
            limit: param?.limit,
            offset: param?.offset,
          };
        } else {
          payload.employee = {
            selectValue: SALE_FILTER_TYPE.employee < type ? param?.data?.employee?.optionValue : 0,
            limit: param?.limit,
            offset: param?.offset,
          };
        }
      case SALE_FILTER_TYPE.province:
        payload.province = param?.data?.province?.optionValue;
      case SALE_FILTER_TYPE.region:
        payload.regionId = Number(param?.data?.region?.optionValue);
      case SALE_FILTER_TYPE.unit:
        payload.unit = Number(param?.data?.units?.optionValue);
      case SALE_FILTER_TYPE.all:
        break;
    }
    const response = await service.postSaleDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));
    return { response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchAssDashboardFilter = createAsyncThunk('report_filter/ass', async (param: any = {}, thunkAPI) => {
  try {
    const query = Object.keys(param).length == 0 ? { type: 0 } : param;
    const type = query.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };
    switch (type) {
      case SALE_FILTER_TYPE.customer:
        if (query.action && type == SALE_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.customer = {
            selectValue: query.data.customer.optionValue,
            limit: 20,
            offset: 0,
          };
        }
      case SALE_FILTER_TYPE.employee:
        if (query.action && type == SALE_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.employee = {
            selectValue: query.data.employee.optionValue,
            limit: 20,
            offset: 0,
          };
        }
      case SALE_FILTER_TYPE.province:
        payload.province = query.data.province.optionValue;
      case SALE_FILTER_TYPE.region:
        payload.regionId = Number(query.data.region.optionValue);
      case SALE_FILTER_TYPE.all:
        break;
    }

    const response = await service.postAssistantDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));

    // Init
    let original: any = {};
    if (Object.keys(param).length === 0) {
      original = { ...response };
    }

    return { original: original, response: response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onLoadMoreAssDashboardFilter = createAsyncThunk('report_filter/ass/loadmore', async (param: any = {}, thunkAPI) => {
  try {
    const type = param.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };
    switch (type) {
      case SALE_FILTER_TYPE.customer:
        if (param.action && type == SALE_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.customer = {
            selectValue: SALE_FILTER_TYPE.customer < type ? param.data.customer.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }
      case SALE_FILTER_TYPE.employee:
        if (param.action && type == SALE_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.employee = {
            selectValue: SALE_FILTER_TYPE.employee < type ? param.data.employee.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }
      case SALE_FILTER_TYPE.province:
        payload.province = param.data.province.optionValue;
      case SALE_FILTER_TYPE.region:
        payload.regionId = Number(param.data.region.optionValue);
      case SALE_FILTER_TYPE.all:
        break;
    }
    const response = await service.postAssistantDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));
    return { response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchAblDashboardFilter = createAsyncThunk('report_filter/abl', async (param: any = {}, thunkAPI) => {
  try {
    const query = Object.keys(param).length == 0 ? { type: 0 } : param;
    const type = query.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      zone: {
        limit: 20,
        offset: 0,
      },
      subZone: {
        limit: 20,
        offset: 0,
      },
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };

    switch (type) {
      case ABL_FILTER_TYPE.customer:
        if (query.action && type == ABL_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.customer = {
            selectValue: query.data.customer.optionValue,
            limit: 20,
            offset: 0,
          };
        }
      case ABL_FILTER_TYPE.employee:
        if (query.action && type == ABL_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.employee = {
            selectValue: query.data.employee.optionValue,
            limit: 20,
            offset: 0,
          };
        }

      case ABL_FILTER_TYPE.subZone:
        if (query.action && type == ABL_FILTER_TYPE.subZone) {
          payload.subZone = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.subZone = {
            selectValue: query.data.subZone.optionValue,
            limit: 20,
            offset: 0,
          };
        }

      case ABL_FILTER_TYPE.zone:
        if (query.action && type == ABL_FILTER_TYPE.zone) {
          payload.zone = {
            searchText: query.keyword,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.zone = {
            selectValue: query.data.zone.optionValue,
            limit: 20,
            offset: 0,
          };
        }

      case ABL_FILTER_TYPE.province:
        payload.province = query.data.province.optionValue;
      case ABL_FILTER_TYPE.region:
        payload.regionId = Number(query.data.region.optionValue);
      case ABL_FILTER_TYPE.all:
        break;
    }

    const response = await service.postABLDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));

    // Init
    let original: any = {};
    if (Object.keys(param).length === 0) {
      original = { ...response };
    }

    return { original: original, response: response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onLoadMoreAblDashboardFilter = createAsyncThunk('report_filter/abl/loadmore', async (param: any = {}, thunkAPI) => {
  try {
    const type = param.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      zone: {
        limit: 20,
        offset: 0,
      },
      subZone: {
        limit: 20,
        offset: 0,
      },
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };
    switch (type) {
      case ABL_FILTER_TYPE.customer:
        if (param.action && type == ABL_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.customer = {
            selectValue: ABL_FILTER_TYPE.customer < type ? param.data.customer.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }
      case ABL_FILTER_TYPE.employee:
        if (param.action && type == ABL_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.employee = {
            selectValue: ABL_FILTER_TYPE.employee < type ? param.data.employee.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }

      case ABL_FILTER_TYPE.subZone:
        if (param.action && type == ABL_FILTER_TYPE.subZone) {
          payload.subZone = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.subZone = {
            selectValue: ABL_FILTER_TYPE.subZone < type ? param.data.subZone.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }

      case ABL_FILTER_TYPE.zone:
        if (param.action && type == ABL_FILTER_TYPE.zone) {
          payload.zone = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.zone = {
            selectValue: ABL_FILTER_TYPE.zone < type ? param.data.zone.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }

      case ABL_FILTER_TYPE.province:
        payload.province = param.data.province.optionValue;
      case ABL_FILTER_TYPE.region:
        payload.regionId = Number(param.data.region.optionValue);
      case ABL_FILTER_TYPE.all:
        break;
    }
    const response = await service.postABLDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));
    return { response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchMskDashboardFilter = createAsyncThunk('report_filter/msk', async (param: any = {}, thunkAPI) => {
  try {
    const query = Object.keys(param).length == 0 ? { type: 0 } : param;
    const type = query.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      regionId: 0,
      province: '',
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };

    switch (type) {
      case MSK_FILTER_TYPE.customer:
        if (query.action && type == MSK_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: query.keyword,
            selectValue: query.data.customer.optionValue,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.customer = {
            searchText: query.keyword,
            selectValue: query.data.customer.optionValue,
            limit: 20,
            offset: 0,
          };
        }
      case MSK_FILTER_TYPE.employee:
        if (query.action && type == MSK_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: query.keyword,
            selectValue: query.data.employee.optionValue,
            limit: 20,
            offset: 0,
          };
        } else {
          payload.employee = {
            searchText: query.keyword,
            selectValue: query.data.employee.optionValue,
            limit: 20,
            offset: 0,
          };
        }

      case MSK_FILTER_TYPE.province:
        payload.province = query.data.province.optionValue;
      case MSK_FILTER_TYPE.region:
        payload.regionId = Number(query.data.region.optionValue);
      case MSK_FILTER_TYPE.all:
        break;
    }

    const response = await service.postMSKDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));

    // Init
    let original: any = {};
    if (Object.keys(param).length === 0) {
      original = { ...response };
    }

    return { original: original, response: response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onLoadMoreMskDashboardFilter = createAsyncThunk('report_filter/msk/loadmore', async (param: any = {}, thunkAPI) => {
  try {
    const type = param.type ?? 0;

    // Filter
    let payload: any = {
      requestType: type,
      province: {
        limit: 20,
        offset: 0,
      },
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };
    switch (type) {
      case MSK_FILTER_TYPE.customer:
        if (param.action && type == MSK_FILTER_TYPE.customer) {
          payload.customer = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.customer = {
            selectValue: MSK_FILTER_TYPE.customer < type ? param.data.customer.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }
      case MSK_FILTER_TYPE.employee:
        if (param.action && type == MSK_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: param.keyword,
            limit: param.limit,
            offset: param.offset,
          };
        } else {
          payload.employee = {
            selectValue: MSK_FILTER_TYPE.employee < type ? param.data.employee.optionValue : 0,
            limit: param.limit,
            offset: param.offset,
          };
        }

      case MSK_FILTER_TYPE.province:
        payload.province = param.data.province.optionValue;
      case MSK_FILTER_TYPE.region:
        payload.regionId = Number(param.data.region.optionValue);
      case MSK_FILTER_TYPE.all:
        break;
    }
    const response = await service.postMSKDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));
    return { response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchCallPlanDashboardFilter = createAsyncThunk('report_filter/call_plan', async (param: any = {}, thunkAPI) => {
  try {
    const response = await service.postCallPlanDashboardFilter(param);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));

    // Init
    let original: any = {};
    if (Object.keys(param).length === 0) {
      original = { ...response };
    }

    return { original: original, response: response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onLoadMoreCallPlanDashboardFilter = createAsyncThunk('report_filter/call_plan/loadmore', async (param: any = {}, thunkAPI) => {
  try {
    const type = param.type ?? 0;

    // Filter
    let payload: any = {
      employee: {
        limit: 20,
        offset: 0,
      },
    };

    switch (type) {
      case CALLPLAN_FILTER_TYPE.employee:
        if (param.action && type == CALLPLAN_FILTER_TYPE.employee) {
          payload.employee = {
            searchText: param?.keyword,
            limit: param?.limit,
            offset: param?.offset,
          };
        } else {
          payload.employee = {
            selectValue: CALLPLAN_FILTER_TYPE.employee < type ? param?.data?.employee?.optionValue : 0,
            limit: param?.limit,
            offset: param?.offset,
          };
        }
      case CALLPLAN_FILTER_TYPE.all:
        break;
    }
    const response = await service.postCallPlanDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));
    return { response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchPowerBIDashboardFilter = createAsyncThunk('report_filter/power_bi', async (param: any = {}, thunkAPI) => {
  try {
    const response = await service.postPowerBIDashboardFilter(param);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));

    // Init
    let original: any = {};
    if (Object.keys(param).length === 0) {
      original = { ...response };
    }

    return { original: original, response: response };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const onFetchOntradeDashboardFilter = createAsyncThunk('report_filter/ontrade', async (param: any = {}, thunkAPI) => {
  try {
    const query = Object.keys(param).length == 0 ? { type: 0 } : param;
    const type = query.type ?? 0;

    // console.log('+++ontrade param', query)
    // Filter
    let payload: any = {
      requestType: type,
      regionId: 0,
      province: '',
      shopType: 0,
      size: '',
      image: '',
      employee: {
        limit: 20,
        offset: 0,
      },
      customer: {
        limit: 20,
        offset: 0,
      },
    };

    switch (type) {
      case ONTRADE_FILTER_TYPE.customer:
        payload.customer = {
          searchText: query.keyword,
          selectValue: query.data.customer.value,
          limit: 20,
          offset: 0,
        };
      case ONTRADE_FILTER_TYPE.employee:
        payload.employee = {
          searchText: query.keyword,
          selectValue: query.data.employee.value,
          limit: 20,
          offset: 0,
        };

      case ONTRADE_FILTER_TYPE.image:
        payload.image = query.data.image.value;
      case ONTRADE_FILTER_TYPE.size:
        payload.size = query.data.size.value;
      case ONTRADE_FILTER_TYPE.shopType:
        payload.shopType = query.data.shopType.value;

      case ONTRADE_FILTER_TYPE.province:
        payload.province = query.data.province.value;
      case ONTRADE_FILTER_TYPE.region:
        payload.regionId = Number(query.data.region.value);
      case ONTRADE_FILTER_TYPE.all:
        break;
    }

    // throw "";
    const response = await service.getOntradeDashboardFilter(payload);
    Object.keys(response).forEach((key) => (response[key as keyof typeof response] === undefined ? delete response[key as keyof typeof response] : {}));

    // Init
    let original: any = {};
    if (Object.keys(param).length === 0) {
      original = { ...response };
    }

    return { original: original, response: response };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const { patch, reset } = reportsFilterSlice.actions;

export default reportsFilterSlice.reducer;
