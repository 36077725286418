import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import Service from '@/services';
import { mergeObjects } from '@/utils/utils';
import { HistoryModel, SaleManagerByCustomerModel } from '@/models/classes/management';

const service = new Service.ManagementService();

// Interface
interface ManagementEmployeeState {
  employee: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SaleManagerByCustomerModel;
  };
  timeline: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: HistoryModel[];
  };
  filter: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
}

// Initialize State
const initialState: ManagementEmployeeState = {
  employee: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  timeline: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  filter: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'ManagementEmployee',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(onFetchViewSaleManagerByCustomer.pending, (state) => {
      state.employee.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchViewSaleManagerByCustomer.fulfilled, (state, action) => {
      state.employee.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.employee.data = action.payload.data;
        if (action.payload.data.history) {
          state.filter.data = action.payload.data?.month;
          state.timeline.data = action.payload.data?.history;
        }
      }
    });
    builder.addCase(onFetchViewSaleManagerByCustomer.rejected, (state, action) => {
      state.employee.network.code = ServiceStatus.failed;
      state.employee.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchViewSaleManagerByCustomerTimeLine.pending, (state) => {
      state.timeline.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchViewSaleManagerByCustomerTimeLine.fulfilled, (state, action) => {
      state.timeline.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        if (state.employee.data?.frequency) {
          state.employee.data.frequency = action.payload.apiData?.data.frequency;
        }
        state.timeline.data = action.payload.apiData?.data.history;
        state.filter.data = action.payload.apiData?.data.month;
      }
    });
    builder.addCase(onFetchViewSaleManagerByCustomerTimeLine.rejected, (state, action) => {
      state.timeline.network.code = ServiceStatus.failed;
      state.timeline.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchViewSaleManagerByCustomer = createAsyncThunk('management/employee/list', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchViewSaleManagerByCustomer(params);
    return apiData;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchViewSaleManagerByCustomerTimeLine = createAsyncThunk('management/employee/timeline', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchViewSaleManagerByCustomer(param);
    return { apiData };
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

// Actions
export const { patch, reset } = slice.actions;

// Export
export default slice.reducer;
