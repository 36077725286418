import { plainToClass } from 'class-transformer';
import { ReportFilterApi } from '../api/report/filter';
import { FilterOntradeModel, FilterWithOutZoneModel, FilterWithZoneModel } from '@/models/classes/report/filter';

const api = new ReportFilterApi();

let options = {
  regions: new Array(),
  regionsTotal: 0,
  provinces: new Array(),
  provincesTotal: 0,
  employee: new Array(),
  employeeTotal: 0,
  customers: new Array(),
  customersTotal: 0,
  units: new Array(),
  unitsTotal: 0,
};

let optionsWithZone = {
  regions: new Array(),
  regionsTotal: 0,
  provinces: new Array(),
  provincesTotal: 0,
  zone: new Array(),
  zoneTotal: 0,
  subZone: new Array(),
  subZoneTotal: 0,
  employee: new Array(),
  employeeTotal: 0,
  customers: new Array(),
  customersTotal: 0,
  units: new Array(),
  unitsTotal: 0,
};
let optionsOntrade = {
  regions: new Array(),
  regionsTotal: 0,
  provinces: new Array(),
  provincesTotal: 0,
  employee: new Array(),
  employeeTotal: 0,
  customers: new Array(),
  customersTotal: 0,
  images: new Array(),
  imagesTotal: 0,
  shopTypes: new Array(),
  shopTypesTotal: 0,
  sizes: new Array(),
  sizesTotal: 0,
};

export class ReportFilterService {
  postSaleDashboardFilter = async (data: any) => {
    try {
      const resp = await api.postSaleDashboardFilter(JSON.stringify(data));
      if (resp?.data !== undefined) {
        let options = await this.formatWithOutZone(resp?.data);
        return plainToClass(FilterWithOutZoneModel, options);
      } else {
        return options;
      }
    } catch (error) {
      return options;
    }
  };

  postABLDashboardFilter = async (data: any) => {
    try {
      const resp = await api.postABLDashboardFilter(JSON.stringify(data));
      if (resp?.data !== undefined) {
        let optionsWithZone = await this.formatWithZone(resp?.data);
        return plainToClass(FilterWithZoneModel, optionsWithZone);
      } else {
        return optionsWithZone;
      }
    } catch (error) {
      return optionsWithZone;
    }
  };

  postAssistantDashboardFilter = async (data: any) => {
    try {
      const resp = await api.postAssistantDashboardFilter(JSON.stringify(data));
      if (resp?.data !== undefined) {
        let options = await this.formatWithOutZone(resp?.data);
        return plainToClass(FilterWithOutZoneModel, options);
      } else {
        return options;
      }
    } catch (error) {
      return options;
    }
  };

  async postMSKDashboardFilter(data: any) {
    try {
      const resp = await api.postMSKDashboardFilter(JSON.stringify(data));
      if (resp?.data !== undefined) {
        let optionsWithZone = await this.formatWithZone(resp?.data);
        return plainToClass(FilterWithZoneModel, optionsWithZone);
      } else {
        return optionsWithZone;
      }
    } catch (error) {
      return optionsWithZone;
    }
  }

  async postCallPlanDashboardFilter(data: any) {
    try {
      const resp = await api.postCallPlanDashboardFilter(data);
      if (resp?.data !== undefined) {
        let optionsWithZone = await this.formatWithZone(resp?.data);
        console.log('optionsWithZone', optionsWithZone);
        return plainToClass(FilterWithZoneModel, optionsWithZone);
      } else {
        return optionsWithZone;
      }
    } catch (error) {
      return optionsWithZone;
    }
  }

  async postPowerBIDashboardFilter(data: any) {
    try {
      const resp = await api.postPowerBIDashboardFilter(data);
      if (resp?.data !== undefined) {
        let optionsWithZone = await this.formatWithZone(resp?.data);
        return plainToClass(FilterWithZoneModel, optionsWithZone);
      } else {
        return optionsWithZone;
      }
    } catch (error) {
      return optionsWithZone;
    }
  }

  async getOntradeDashboardFilter(data: any) {
    try {
      const resp = await api.getOntradeDashboardFilter(JSON.stringify(data));
      if (resp?.data !== undefined) {
        let optionsOntrade = await this.formatOntrade(resp?.data);
        return plainToClass(FilterOntradeModel, optionsOntrade);
      } else {
        return optionsOntrade;
      }
    } catch (error) {
      console.warn('getOntradeDashboardFilter error', error);
      return optionsOntrade;
    }
  }

  formatWithOutZone = async (data: any) => {
    let opt = options;
    opt.regions = data?.regionOption?.option;
    opt.regionsTotal = data?.regionOption?.xTotalCount;
    opt.provinces = data?.provinceOption?.option;
    opt.provincesTotal = data?.provinceOption?.xTotalCount;
    opt.employee = data?.employeeOption?.option;
    opt.employeeTotal = data?.employeeOption?.xTotalCount;
    opt.customers = data?.customerOption?.option;
    opt.customersTotal = data?.customerOption?.xTotalCount;
    opt.units = data?.productUnitOption?.option;
    opt.unitsTotal = data?.productUnitOption?.xTotalCount;
    return opt;
  };

  formatWithZone = async (data: any) => {
    let opt = optionsWithZone;
    opt.regions = data?.regionOption?.option;
    opt.regionsTotal = data?.regionOption?.xTotalCount;
    opt.provinces = data?.provinceOption?.option;
    opt.provincesTotal = data?.provinceOption?.xTotalCount;
    opt.employee = data?.employeeOption?.option;
    opt.employeeTotal = data?.employeeOption?.xTotalCount;
    opt.customers = data?.customerOption?.option;
    opt.customersTotal = data?.customerOption?.xTotalCount;
    opt.zone = data?.zoneOption?.option;
    opt.zoneTotal = data?.zoneOption?.xTotalCount;
    opt.subZone = data?.subZoneOption?.option;
    opt.subZoneTotal = data?.subZoneOption?.xTotalCount;
    opt.units = data?.productUnitOption?.option;
    opt.unitsTotal = data?.productUnitOption?.xTotalCount;
    return opt;
  };

  formatOntrade = (data: any) => {
    let opt = optionsOntrade;
    opt.regions = data?.regionOption?.option;
    opt.regionsTotal = data?.regionOption?.xTotalCount;
    opt.provinces = data?.provinceOption?.option;
    opt.provincesTotal = data?.provinceOption?.xTotalCount;
    opt.employee = data?.employeeOption?.option;
    opt.employeeTotal = data?.employeeOption?.xTotalCount;
    opt.customers = data?.customerOption?.option;
    opt.customersTotal = data?.customerOption?.xTotalCount;

    opt.images = data?.imageOption?.option;
    opt.imagesTotal = data?.imageOption?.xTotalCount;
    opt.shopTypes = data?.shopTypeOption?.option;
    opt.shopTypesTotal = data?.shopTypeOption?.xTotalCount;
    opt.sizes = data?.sizeOption?.option;
    opt.sizesTotal = data?.sizeOption?.xTotalCount;
    return opt;
  };
}
