import axios from 'axios';

import { OfflineTodayActivity } from './../offline/todayActivity';
const offline = new OfflineTodayActivity();

export class OverviewApi {
  async fetchOverview() {
    let path = `/api/User/GetOverview`;
    try {
      if (window.isOffline) {
        return await offline.fetchTodayActivity();
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getTodayActivity() {
    let path = `/api/User/GetTodayOverview`;
    try {
      if (window.isOffline) {
        return await offline.fetchTodayActivity();
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getEActivity() {
    const config = {
      method: 'get',
      url: `/api/User/GetEActivityOverview`,
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (err) {}
  }
}
