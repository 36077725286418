import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type } from "class-transformer";
import { OptionModel } from "@/models/classes/report/option";

export class FilterWithOutZoneModel {
  constructor() {
    this.regions = [];
    this.provinces = [];
    this.employee = [];
    this.customers = [];
    this.units = [];
    this.regionsTotal = 0;
    this.provincesTotal = 0;
    this.employeeTotal = 0;
    this.customersTotal = 0;
    this.unitsTotal = 0;
  }

  @Type(() => OptionModel)
  @Expose()
  regions: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  provinces: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  employee: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  customers: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  units: OptionModel[];

  regionsTotal: number;
  provincesTotal: number;
  employeeTotal: number;
  customersTotal: number;
  unitsTotal: number;
}

export class FilterWithZoneModel {
  constructor() {
    this.regions = [];
    this.provinces = [];
    this.employee = [];
    this.customers = [];
    this.zone = [];
    this.subZone = [];
    this.customers = [];
    this.units = [];
    this.regionsTotal = 0;
    this.provincesTotal = 0;
    this.employeeTotal = 0;
    this.customersTotal = 0;
    this.zoneTotal = 0;
    this.subZoneTotal = 0;
    this.unitsTotal = 0;

  }

  @Type(() => OptionModel)
  @Expose()
  regions: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  provinces: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  employee: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  customers: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  zone: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  subZone: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  units: OptionModel[];

  regionsTotal: number;
  provincesTotal: number;
  employeeTotal: number;
  customersTotal: number;
  zoneTotal: number;
  subZoneTotal: number;
  unitsTotal: number;
}



export class FilterOntradeModel {
  constructor() {
    this.regions = [];
    this.provinces = [];
    this.shopTypes = [];
    this.sizes = [];
    this.images = [];
    this.employee = [];
    this.customers = [];
    this.regionsTotal = 0;
    this.provincesTotal = 0;
    this.employeeTotal = 0;
    this.customersTotal = 0;
    this.imagesTotal = 0;
    this.shopTypesTotal = 0;
    this.sizesTotal = 0;
  }

  @Type(() => OptionModel)
  @Expose()
  regions: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  provinces: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  employee: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  customers: OptionModel[];

  @Type(() => OptionModel)
  @Expose()
  shopTypes: OptionModel[]
  @Type(() => OptionModel)
  @Expose()
  sizes: OptionModel[]
  @Type(() => OptionModel)
  @Expose()
  images: OptionModel[]

  regionsTotal: number;
  provincesTotal: number;
  employeeTotal: number;
  customersTotal: number;
  imagesTotal: number
  shopTypesTotal: number
  sizesTotal: number
}