import { OverviewApi } from './api/overview';
import { plainToClassFromExist } from 'class-transformer';
import { OverviewModel } from '@/models/classes/overview';
import { ResponseModel } from '@/models/classes/response';
import { TodayEActivityModel } from '@/models/classes/overview/today_e_activity';
import { TodayActivityModel } from '@/models/classes/overview/today_activity';

const api = new OverviewApi();
export class OverviewService {
  fetchOverview = async () => {
    const resData = await api.fetchOverview();
    let formatData = { ...resData };

    if (formatData?.data?.marketShareACN) {
      let data: any = new Array();
      let color: any = {
        classic: '#456643',
        coldbrew: '#59834d',
        leo: '#9e2418',
        other: '#bababa',
        singha: '#bc9736',
      };

      let arr = Object.entries(resData?.data?.marketShareACN);
      arr?.map((item) => {
        let k = item[0];
        if (k != 'updatedDate') {
          data.push({
            name: k,
            value: item[1],
            color: color[k],
          });
        }
      });

      formatData.data.marketShareACN.data = data;
    }

    const resModel = plainToClassFromExist(new ResponseModel<OverviewModel>(OverviewModel), formatData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetTodayActivity = async () => {
    const resData = await api.getTodayActivity();
    const resModel = plainToClassFromExist(new ResponseModel<TodayActivityModel>(TodayActivityModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchGetTodayEActivity = async () => {
    const resData = await api.getEActivity();
    const resModel = plainToClassFromExist(new ResponseModel<TodayEActivityModel>(TodayEActivityModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };
}
