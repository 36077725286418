import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { format } from 'date-fns';

//API
import APIBusinessReview from '@/services/api/business_review';
import APIFile from '@/services/api/file';

//Entities
import { ResponseModel } from '@/models/classes/response';
import { SuccessModel } from '@/models/classes/success';
import { ResponsesModel } from '@/models/classes/responses';
import { ReviewBySalesEmployeeModel } from '@/models/classes/business_review';
import optionTextValueObj from '@/models/classes/general/optionTextValueObj';
import Item from '@/models/classes/business_review/item';
import SaleOrg from '@/models/classes/business_review/saleOrg';
import Customer from '@/models/classes/business_review/customer';
import BusinessReviewItem from '@/models/classes/business_review/item';
import BusinessReviewDetail from '@/models/classes/business_review/detail';

const API = new APIBusinessReview();
const File = new APIFile();

//Services
class BusinessReview {
  async getListBusinessReviewBySalesOrg(selectQuarter: string | any) {
    let data: any = {
      lstOptionQuarter: [],
      lstBusinessReviewBySalesOrg: [],
    };
    try {
      const resp = await API.getListBusinessReviewBySalesOrg(selectQuarter);

      if (resp?.data.lstOptionQuarter != undefined) {
        let list: any = new Array();
        await Promise.all(
          resp.data.lstOptionQuarter.map(async (item: any) => {
            let itm = {
              label: item.optionText,
              value: item.optionValue,
            };
            list.push(itm);
          })
        );
        data.lstOptionQuarter = plainToClass(optionTextValueObj, list);
      }

      if (resp?.data.lstBusinessReviewBySalesOrg != undefined) {
        data.lstBusinessReviewBySalesOrg = plainToClass(SaleOrg, resp.data.lstBusinessReviewBySalesOrg);
      }

      let result = {
        quarter: data.lstOptionQuarter,
        reviews: data.lstBusinessReviewBySalesOrg,
      };

      return result;
    } catch (err) {
      return err;
    }
  }

  async getListBusinessReviewOfOwner(selectQuarter: string | any, SelectCategory: string | any) {
    let data: any = {
      lstOptionQuarter: [],
      lstOptionCategory: [],
      lstBusinessReview: [],
      lstBusinessReviewCategory: [],
    };
    try {
      const resp = await API.getListBusinessReviewOfOwner(selectQuarter, SelectCategory);

      let summary = {
        total: 0,
        target: 0,
        plan: 0,
        actual: 0,
      };

      //Quarter
      if (resp?.data.lstOptionQuarter != undefined) {
        let list: any = new Array();
        await Promise.all(
          resp.data.lstOptionQuarter.map(async (item: any) => {
            let itm = {
              label: item.optionText,
              value: item.optionValue,
            };
            list.push(itm);
          })
        );
        data.lstOptionQuarter = plainToClass(optionTextValueObj, list);
      }

      if (resp?.data.lstOptionCategory != undefined) {
        let list: any = new Array();
        await Promise.all(
          resp.data.lstOptionCategory.map(async (item: any) => {
            let itm = {
              label: item.optionText,
              value: item.optionValue,
            };
            list.push(itm);
          })
        );
        data.lstOptionCategory = plainToClass(optionTextValueObj, list);
      }

      if (resp?.data.lstBusinessReviewCategory != undefined) {
        let list: any = new Array();
        await Promise.all(
          resp.data.lstBusinessReviewCategory.map(async (item: any) => {
            let itm = {
              label: item.businessReviewCategoryName,
              value: item.qty,
            };
            list.push(itm);

            let value = item.qty;
            if (item.businessReviewCategoryName.toLowerCase() == 'target') {
              summary.total = value;
              summary.target = value;
            } else if (item.businessReviewCategoryName.toLowerCase() == 'plan') {
              summary.plan = value;
            } else if (item.businessReviewCategoryName.toLowerCase() == 'actual') {
              summary.actual = value;
            }
          })
        );
        data.lstBusinessReviewCategory = plainToClass(optionTextValueObj, list);
      }

      if (resp?.data.lstBusinessReview != undefined) {
        data.lstBusinessReview = plainToClass(Item, resp.data.lstBusinessReview);
      }

      let result = {
        quarter: data.lstOptionQuarter,
        summary: summary,
        category: data.lstOptionCategory,
        reviews: data.lstBusinessReview,
      };

      return result;
    } catch (err) {
      return err;
    }
  }

  async getListSendToManager() {
    const resData = await API.getListSendToManager();

    const resModel = plainToClassFromExist(new ResponsesModel<BusinessReviewItem>(BusinessReviewItem), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  }

  async getBusinessReviewDetail(businessReviewId: number | any) {
    const resData = await API.getBusinessReviewDetail(businessReviewId);

    const resModel = plainToClassFromExist(new ResponseModel<BusinessReviewDetail>(BusinessReviewDetail), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }

    return undefined;
  }

  async getSearchCustomer(searchText: string | any) {
    let data: any = { customer: [] };
    try {
      const resp = await API.getSearchCustomer(searchText);

      if (resp?.data != undefined) {
        data.customer = plainToClass(Customer, resp.data);
      }

      return data;
    } catch (err) {
      return err;
    }
  }

  async getListBusinessReviewBySalesEmployee(params: any = {}) {
    const resData = await API.getListBusinessReviewBySalesEmployee(params);

    const resModel = plainToClassFromExist(new ResponseModel<ReviewBySalesEmployeeModel>(ReviewBySalesEmployeeModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  //--------------------- POST ----------------------------
  async postSubmitSendToManager(params: any) {
    const resData = await API.postSubmitSendToManager(params);

    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async postCreateBusinessReviewPlan(params: any) {
    const resData = await API.postCreateBusinessReviewPlan(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async postCreateBusinessReviewActual(params: any) {
    try {
      let payload = {
        reviewData: {
          businessReviewDate: format(params.date, 'yyyy-MM-dd'),
          customerId: params.customers.id,
        },
        actualData: {
          salesGrowth: parseInt(params.salesGrowth),
          salesGrowthDetail: params.salesGrowthDetail,
          ideaAndInvestment: params.ideaAndInvestment,
          pic1: '',
          pic2: '',
          pic3: '',
          pic4: '',
          attachFile: '',
          nextMeetingDate: params.nextMeetingDate,
          lstParticipants: new Array(),
        },
      };

      await Promise.all(
        params.participants.map((item: any) => {
          payload.actualData.lstParticipants.push({
            participantsEmployeeName: item.participantsEmployeeName,
            participantsPositionName: item.participantsPositionName,
          });
        })
      );

      for (let i = 0; i < params.images.length; i++) {
        if (params.images[i] !== '') {
          let file = await File.upload(params.images[i]);
          if (file.folderFileId != null) {
            let id = file.folderFileId;
            if (i === 0) {
              payload.actualData.pic1 = id + '';
            } else if (i === 1) {
              payload.actualData.pic2 = id + '';
            } else if (i === 2) {
              payload.actualData.pic3 = id + '';
            } else if (i === 3) {
              payload.actualData.pic4 = id + '';
            }
          }
        }
      }

      if (params.attachFile != undefined && params.attachFile != null) {
        let store = await File.uploadFile(params.attachFile).catch((e) => console.error('upload file error bypass', e.message));
        if (store?.folderFileId !== undefined) {
          payload.actualData.attachFile = store.folderFileId + '';
        } else {
          payload.actualData.attachFile = '';
        }
      }

      const resData = await API.postCreateBusinessReviewActual(payload);

      const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return resModel.data;
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  }

  //V2
  async postCreateBusinessReviewTarget(params: any) {
    if (params) {
      params.targetData.year = params.targetData.year + '';
      params.targetData.visitQ1 = parseInt(params.targetData.visitQ1);
      params.targetData.visitQ2 = parseInt(params.targetData.visitQ2);
      params.targetData.visitQ3 = parseInt(params.targetData.visitQ3);
      params.targetData.visitQ4 = parseInt(params.targetData.visitQ4);
    }

    const resData = await API.postCreateBusinessReviewTarget(params);

    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }
}

export default BusinessReview;
