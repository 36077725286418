import { OfflineIndex } from './index';
const offline = new OfflineIndex();

export class OfflinePlan {
  async getPlanByMonthAndYear(month: string | number | any, year: string | number | any) {
    let allPlans = await offline.getPlans();
    let plans: any = await offline.fetchWithOrg(allPlans);

    const res: any = {
      routePlanId: 0,
      month: month,
      year: year,
      plannedDate: [],
      customerPlan: [],
    };

    if (plans) {
      await Promise.all(
        plans?.map((item: any) => {
          if (item.month == month && item.year == year) {
            res.routePlanId = item.routePlanId;
            res.plannedDate = item.plannedDate;
            res.customerPlan = item.customerPlan;
          }
        })
      );
    }

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: res,
      error: null,
      xTotalCount: 0,
    };
    return result;
  }

  async getGetToDoList() {
    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: {
        customerCallPlan: [],
        morningTalk: [],
      },
      error: null,
      xTotalCount: 0,
    };
    return result;
  }
}
