//Entities

import { plainToClassFromExist, plainToInstance } from 'class-transformer';
import { ExpandListCallPlanModel } from '@/models/classes/report/expand_list_call_plan';
import { ResponseModel } from '@/models/classes/response';
import { CallPlanOverviewModel } from '@/models/classes/report/call_plan';
import { ReportDashboardCallPlanApi } from '../api/report/dashboard_call_plan';

const api = new ReportDashboardCallPlanApi();

//Services
export class ReportDashboardCallPlanService {
  getDashboardCallPlanOverview = async (params: any = {}) => {
    try {
      const resp: any = await api.getDashboardCallPlanOverview(params);
      const resModel = plainToClassFromExist(new ResponseModel<CallPlanOverviewModel>(CallPlanOverviewModel), resp);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return resModel.data;
      }
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  };

  getDashboardCallPlan = async (params: any = {}) => {
    try {
      const resp: any = await api.getDashboardCallPlan(params);

      if (resp !== undefined) {
        let res = await this.formatList(resp?.data);
        let onPlan = new Array();
        let offPlan = new Array();

        res?.map((item: any) => {
          if (item.isOffPlan) {
            offPlan.push(item);
          } else {
            onPlan.push(item);
          }
        });

        let result = {
          onPlan: plainToInstance(ExpandListCallPlanModel, onPlan),
          offPlan: plainToInstance(ExpandListCallPlanModel, offPlan),
        };
        return result;
      } else {
        return undefined;
      }
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  };

  formatList = async (data: any) => {
    let res = new Array();
    for (let i = 0; i < data.length; i++) {
      let item: any = {
        level: 1,
        name: data[i].name,
        value: data[i].value,
        percentage: data[i].percentage,
        isLastLevel: data[i].isLastLevel,
        isOffPlan: data[i].isOffPlan,
        isSuccess: data[i].percentage == 100,
        children: [],
      };
      if (data[i].child) {
        for (let j = 0; j < data[i].child.length; j++) {
          let itm: any = {
            level: 2,
            name: data[i].child[j].name,
            value: data[i].child[j].value,
            percentage: data[i].child[j].isOffPlan ? null : data[i].child[j].percentage,
            isLastLevel: data[i].child[j].isLastLevel,
            isOffPlan: data[i].child[j].isOffPlan,
            isSuccess: data[i].child[j].percentage == 100,
            children: [],
          };

          if (data[i].child[j].child) {
            for (let k = 0; k < data[i].child[j].child.length; k++) {
              let itm2: any = {
                level: 3,
                name: data[i].child[j].child[k].name,
                value: data[i].child[j].child[k].value,
                percentage: data[i].child[j].child[k].isOffPlan ? null : data[i].child[j].child[k].percentage,
                isLastLevel: data[i].child[j].child[k].isLastLevel,
                isOffPlan: data[i].child[j].child[k].isOffPlan,
                isSuccess: data[i].child[j].child[k].percentage == 100,
                children: [],
              };
              itm.children.push(itm2);
            }
          }

          item.children.push(itm);
        }
      }
      res.push(item);
    }

    return res;
  };
}
