//Entities

import { ResponsesModel } from '@/models/classes/responses';
import { ReportDashboardSumCallPlanApi } from './../api/report/dashboard_sum_call_plan';

import { plainToClassFromExist } from 'class-transformer';
import { ResponseModel } from '@/models/classes/response';
import { SumCallPlanDashboardDetailListModel, SumCallPlanDashboardDetailModel, SumCallPlanDashboardListModel, SumCallPlanDashboardModel, SumCallPlanFilterModel, SumCallPlanListModel } from '@/models/classes/report/sum_call_plan';

const api = new ReportDashboardSumCallPlanApi();

//Services
export class ReportDashboardSumCallPlanService {
  getDashboardSumCallPlanFilter = async (params: any = {}) => {
    try {
      const resp: any = await api.getDashboardSumCallPlanFilter(params);
      const resModel = plainToClassFromExist(new ResponsesModel<SumCallPlanFilterModel>(SumCallPlanFilterModel), resp);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return resModel.data;
      }
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  };

  getDashboardSumCallPlan = async (params: any = {}) => {
    try {
      const resp: any = await api.getDashboardSumCallPlan(params);
      const resModel = plainToClassFromExist(new ResponseModel<SumCallPlanListModel>(SumCallPlanListModel), resp);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return { data: resModel.data, total: resModel.total ?? 0 };
      }
    } catch (error) {
      console.log('error', error);
      return undefined;
    }
  };

  getDashboardSumCallPlanDashboard = async (params: any = {}) => {
    try {
      const resp: any = await api.getDashboardSumCallPlanDashboard(params);
      const resModel = plainToClassFromExist(new ResponseModel<SumCallPlanDashboardListModel>(SumCallPlanDashboardListModel), resp);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return { data: resModel.data, total: resModel.total ?? 0 };
      }
    } catch (error) {
      return undefined;
    }
  };

  getDashboardSumCallPlanDashboardDetail = async (params: any = {}) => {
    try {
      const resp: any = await api.getDashboardSumCallPlanDashboardDetail(params);
      const resModel = plainToClassFromExist(new ResponseModel<SumCallPlanDashboardDetailListModel>(SumCallPlanDashboardDetailListModel), resp);
      if (resModel.status == 200 && typeof resModel.data == 'object') {
        return { data: resModel.data, total: resModel.total ?? 0 };
      }
    } catch (error) {
      return undefined;
    }
  };
}
