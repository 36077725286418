import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import Service from '@/services';
import { mergeObjects } from '@/utils/utils';
import { FilterModel, MapCustomerModel } from '@/models/classes/management';

const service = new Service.ManagementService();

// Interface
interface ManagementState {
  customer: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: MapCustomerModel[];
    total?: number;
  };
  searchCustomer: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: MapCustomerModel[];
    total?: number;
  };
  filter: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: FilterModel[];
    total?: number;
  };
}

// Initialize State
const initialState: ManagementState = {
  customer: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  searchCustomer: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  filter: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
};

// Reducer
export const slice = createSlice({
  name: 'ManagementMap',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(onFetchFilter.pending, (state) => {
      state.filter.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchFilter.fulfilled, (state, action) => {
      state.filter.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.filter.data = action.payload.data;
        state.filter.total = action.payload.total;
      }
    });
    builder.addCase(onFetchFilter.rejected, (state, action) => {
      state.filter.network.code = ServiceStatus.failed;
      state.filter.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchCustomer.pending, (state) => {
      state.customer.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchCustomer.fulfilled, (state, action) => {
      state.customer.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.customer.data = action.payload.data;
        state.customer.total = action.payload.total;
      }
    });
    builder.addCase(onFetchCustomer.rejected, (state, action) => {
      state.customer.network.code = ServiceStatus.failed;
      state.customer.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSearchCustomer.pending, (state) => {
      state.searchCustomer.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSearchCustomer.fulfilled, (state, action) => {
      state.searchCustomer.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.searchCustomer.data = action.payload.data;
        state.searchCustomer.total = action.payload.total;
      }
    });
    builder.addCase(onFetchSearchCustomer.rejected, (state, action) => {
      state.searchCustomer.network.code = ServiceStatus.failed;
      state.searchCustomer.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSearchMoreCustomer.pending, (state) => {
      state.searchCustomer.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSearchMoreCustomer.fulfilled, (state, action) => {
      state.searchCustomer.network.code = ServiceStatus.succeeded;
      if (action.payload != null && state.searchCustomer.data) {
        let lst = [...state.searchCustomer.data, ...action.payload.data];
        state.searchCustomer.data = lst;
        state.searchCustomer.total = action.payload.total;
      }
    });
    builder.addCase(onFetchSearchMoreCustomer.rejected, (state, action) => {
      state.searchCustomer.network.code = ServiceStatus.failed;
      state.searchCustomer.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchFilter = createAsyncThunk('management/map/filter', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchFilter(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchCustomer = createAsyncThunk('management/map/customer', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchCustomer(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSearchCustomer = createAsyncThunk('management/map/customer/search', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchSearchCustomer(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSearchMoreCustomer = createAsyncThunk('management/map/customer/search/more', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchSearchCustomer(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { patch, reset } = slice.actions;

// Export
export default slice.reducer;
