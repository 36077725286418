import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type } from "class-transformer";

export class DataModel {
  constructor() {
    this.name = "";
    this.value = "";
    this.percentage = 0;
    this.isLastLevel = false;
    this.isOffPlan = false;
    this.children = [];
  }

  name: string;
  value: string;
  percentage: number;
  isLastLevel: boolean;
  isOffPlan: boolean;
  
  @Type(() => DataModel)
  @Expose()
  children: DataModel[];

}

export class ExpandListCallPlanModel {
  constructor() {
    this.name = "";
    this.value = "";
    this.percentage = undefined;
    this.isLastLevel = false;
    this.isOffPlan = false;
    this.children = [];
  }

  @Type(() => DataModel)
  @Expose()
  children: DataModel[];

  name: string;
  value: string;
  percentage?: number|undefined;
  isLastLevel: boolean;
  isOffPlan: boolean;
}