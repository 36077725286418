export class Cookie {
  static setOrganize = (company: string, channel: string, sale: number) => {
    localStorage.setItem('orgCompany', company);
    localStorage.setItem('orgChannel', channel);
    localStorage.setItem('orgSale', sale.toString());
  };

  static getOrganize = () => {
    return {
      company: localStorage.getItem('orgCompany'),
      channel: localStorage.getItem('orgChannel'),
      sale: Number(localStorage.getItem('orgSale')),
    };
  };

  static removeOrganize = () => {
    localStorage.removeItem('orgCompany');
    localStorage.removeItem('orgChannel');
    localStorage.removeItem('orgSale');
  };
}
