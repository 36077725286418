import axios from 'axios';

export class NotificationApi {
  async fetchNotification() {
    let path = `/api/Notificaiton/ListNotify`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch notification`, error);
      throw error;
    }
  }

  async getNotficaitonReadStatus() {
    let path = `/api/Notificaiton/GetNotficaitonReadStatus`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postUpdateReadStatus(params: any = {}) {
    try {
      const res = await axios.post(`/api/Notificaiton/UpdateReadStatus?notifyHistoryId=${params.notifyHistoryId}&isCommonMessage=${params.isCommonMessage}`);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}
