import axios from 'axios';
import * as qs from 'qs';

//Services
export class ReportDashboardCallPlanApi {
  getDashboardCallPlan = async (params: any = {}) => {
    let path = `/api/DashBoard/GetCallPlanDashboardDetail?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getDashboardCallPlanOverview = async (params: any = {}) => {
    let path = `/api/DashBoard/GetCallPlanOverview?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };
}
