import axios from "axios";
import * as qs from "qs";

//Services
class APIReportPowerBI {
  async getPowerBILink() {
    const config = {
      method: "get",
      url: `/api/DashBoard/GetPowerBILink`,
      // params: params,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (err) {
      throw err;
    }
  }
}

export default APIReportPowerBI;
