//API
import APIReportPowerBI from '@/services/api/reports_power_bi';

const API = new APIReportPowerBI();

//Services
class ServicesReportMsk {
  async getPowerBILink() {
    try {
      const resp: any = await API.getPowerBILink();
      if (resp?.data !== undefined) {
        return resp?.data?.link as string;
      } else {
        throw 'getPowerBILink no data';
      }
    } catch (err) {
      throw err;
    }
  }
}

export default ServicesReportMsk;
