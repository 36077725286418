import { CommunicationApi } from './api/communication';
import APIFile from './api/file';
import { plainToClassFromExist } from 'class-transformer';
import { ResponseModel } from '@/models/classes/response';
import { SearchNotifyModel, HistoryNotifyModel, NotifyOptionsModel, NotifyDraftModel } from '@/models/classes/communication';
import { NotificationModel } from '@/models/classes/notification';
import { SuccessModel } from '@/models/classes/success';

const api = new CommunicationApi();
const fileApi = new APIFile();

export class CommunicationService {
  fetchSearch = async (notifyType: number, limit: number, offset: number) => {
    const resData = await api.getSearchNotify(notifyType, limit, offset);
    const resModel = plainToClassFromExist(new ResponseModel<SearchNotifyModel>(SearchNotifyModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  fetchHistory = async (searchText: string | null, notifyStatus: string, limit: number, offset: number) => {
    const resData = await api.getSearchHistory(searchText, notifyStatus, limit, offset);

    const resModel = plainToClassFromExist(new ResponseModel<HistoryNotifyModel>(HistoryNotifyModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total ?? 0 };
    }
    return undefined;
  };

  getNotify = async () => {
    const resData = await api.getNotify();

    const resModel = plainToClassFromExist(new ResponseModel<NotifyOptionsModel>(NotifyOptionsModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  viewNotify = async (params: any = {}) => {
    const resData = await api.viewNotify(params);

    const resModel = plainToClassFromExist(new ResponseModel<NotificationModel>(NotificationModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  viewHistoryNotify = async (params: any = {}) => {
    const resData = await api.viewHistoryNotify(params);

    const resModel = plainToClassFromExist(new ResponseModel<NotificationModel>(NotificationModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  saveDraftNotify = async (params: any) => {
    let payload: any = {
      notifyTitle: params?.title,
      notifyContent: params?.content,
      notifyTypeId: params?.type?.value,
      notifyPic1: null,
      notifyPic2: null,
      notifyPic3: null,
      notifyPic4: null,
      notifyGroupId: params?.group?.value,
    };

    for (let i = 0; i < params?.images.length; i++) {
      if (params?.images[i] !== '') {
        let file = await fileApi.upload(params?.images[i]);
        if (file.folderFileId != null) {
          let id = file.folderFileId;
          if (i === 0) {
            payload.notifyPic1 = id + '';
          } else if (i === 1) {
            payload.notifyPic2 = id + '';
          } else if (i === 2) {
            payload.notifyPic3 = id + '';
          } else if (i === 3) {
            payload.notifyPic4 = id + '';
          }
        }
      }
    }

    const resData = await api.saveDraft(payload);

    const resModel = plainToClassFromExist(new ResponseModel<NotifyDraftModel>(NotifyDraftModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  restore = async (params: any = {}) => {
    const resData = await api.restore(params);

    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  send = async (params: any = {}) => {
    const resData = await api.send(params);

    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };
}
