import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Service from '@/services';
import { ExpandListTwoColumnModel } from '@/models/classes/report/expand_list_two_column';
import { ExpandListThreeColumnModel } from '@/models/classes/report/expand_list_three_column';
import { SalesPerformanceModel } from '@/models/classes/overview/sales_performance';
import { mergeObjects } from '@/utils/utils';
const service = new Service.ReportDashboardSaleService();

// Interface
interface DashboardSaleState {
  performance: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SalesPerformanceModel;
  };
  saleReceiveMoney: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ExpandListTwoColumnModel;
  };
  outStandingCarry: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ExpandListTwoColumnModel;
  };
  stock: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ExpandListTwoColumnModel;
  };
  sellOut: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ExpandListThreeColumnModel;
  };
}

// Initialize State
const initialState: DashboardSaleState = {
  performance: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  saleReceiveMoney: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  outStandingCarry: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  stock: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  sellOut: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const dashboardSaleSlice = createSlice({
  name: 'dashboard_sale',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    // Fetch SaleReceiveMoney
    builder.addCase(onFetchSalePerformance.pending, (state, action) => {
      state.performance.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSalePerformance.fulfilled, (state, action) => {
      state.performance.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.performance.data = action.payload;
      } else {
        state.performance.data = undefined;
      }
    });
    builder.addCase(onFetchSalePerformance.rejected, (state, action) => {
      state.performance.network.code = ServiceStatus.failed;
      state.performance.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch SaleReceiveMoney
    builder.addCase(onFetchSaleReceiveMoney.pending, (state, action) => {
      state.saleReceiveMoney.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSaleReceiveMoney.fulfilled, (state, action) => {
      state.saleReceiveMoney.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.saleReceiveMoney.data = action.payload;
      } else {
        state.saleReceiveMoney.data = undefined;
      }
    });
    builder.addCase(onFetchSaleReceiveMoney.rejected, (state, action) => {
      state.saleReceiveMoney.network.code = ServiceStatus.failed;
      state.saleReceiveMoney.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch OutStandingCarry
    builder.addCase(onFetchOutStandingCarry.pending, (state, action) => {
      state.outStandingCarry.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchOutStandingCarry.fulfilled, (state, action) => {
      state.outStandingCarry.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.outStandingCarry.data = action.payload;
      } else {
        state.outStandingCarry.data = undefined;
      }
    });
    builder.addCase(onFetchOutStandingCarry.rejected, (state, action) => {
      state.outStandingCarry.network.code = ServiceStatus.failed;
      state.outStandingCarry.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch Stock
    builder.addCase(onFetchStock.pending, (state, action) => {
      state.stock.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchStock.fulfilled, (state, action) => {
      state.stock.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.stock.data = action.payload;
      } else {
        state.stock.data = undefined;
      }
    });
    builder.addCase(onFetchStock.rejected, (state, action) => {
      state.stock.network.code = ServiceStatus.failed;
      state.stock.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch Sell Out
    builder.addCase(onFetchSellOut.pending, (state, action) => {
      state.sellOut.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSellOut.fulfilled, (state, action) => {
      state.sellOut.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.sellOut.data = action.payload;
      } else {
        state.sellOut.data = undefined;
      }
    });
    builder.addCase(onFetchSellOut.rejected, (state, action) => {
      state.sellOut.network.code = ServiceStatus.failed;
      state.sellOut.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

export const onFetchSalePerformance = createAsyncThunk('dashboard/sale/performance', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getSalePerformance(param?.filter?.type, param?.filter?.searchId, param?.filter?.regionId);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSaleReceiveMoney = createAsyncThunk('dashboard/sale/receive_money', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSaleReceiveMoney(params);

    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchOutStandingCarry = createAsyncThunk('reports/sale/out_standing_carry', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardRemainCarry(param?.filter?.type, param?.filter?.searchId, param?.filter?.regionId);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchStock = createAsyncThunk('dashboard/sale/stock', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashBoardWareHouse(param?.filter?.type, param?.filter?.searchId, param?.filter?.regionId);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSellOut = createAsyncThunk('dashboard/sale/sell_out', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardSellOut(param?.filter?.type, param?.filter?.searchId, param?.filter?.regionId);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const { reset, patch } = dashboardSaleSlice.actions;

export default dashboardSaleSlice.reducer;
