import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';

import { PlanModel } from '@/models/classes/plan/approve';
import { SuccessModel } from '@/models/classes/success';

const service = new Service.ApproveCallPlanService();

// Interface
interface ApproveCallPlanState {
  callPlans: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data: PlanModel[];
    total: number;
  };
  approve: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
  reject: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
}

// Initialize State
const initialState: ApproveCallPlanState = {
  callPlans: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: [],
    total: 0,
  },
  approve: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  reject: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'ApproveCallPlan',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(onFetchGetListApproveCallPlan.pending, (state) => {
      state.callPlans.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetListApproveCallPlan.fulfilled, (state, action) => {
      state.callPlans.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload.data) {
        state.callPlans.data = action.payload.data;
        state.callPlans.total = action.payload.total;
      }
    });
    builder.addCase(onFetchGetListApproveCallPlan.rejected, (state, action) => {
      state.callPlans.network.code = ServiceStatus.failed;
      state.callPlans.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch
    builder.addCase(onUpdateApproveListApproveCallPlan.pending, (state) => {
      state.approve.network.code = ServiceStatus.loading;
    });
    builder.addCase(onUpdateApproveListApproveCallPlan.fulfilled, (state, action) => {
      state.approve.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.approve.data = action.payload;
      }
    });
    builder.addCase(onUpdateApproveListApproveCallPlan.rejected, (state, action) => {
      state.approve.network.code = ServiceStatus.failed;
      state.approve.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch
    builder.addCase(onUpdateRejectListApproveCallPlan.pending, (state) => {
      state.reject.network.code = ServiceStatus.loading;
    });
    builder.addCase(onUpdateRejectListApproveCallPlan.fulfilled, (state, action) => {
      state.reject.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.reject.data = action.payload;
      }
    });
    builder.addCase(onUpdateRejectListApproveCallPlan.rejected, (state, action) => {
      state.reject.network.code = ServiceStatus.failed;
      state.reject.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchGetListApproveCallPlan = createAsyncThunk('approve/callplans', async (params: any, thunkAPI) => {
  try {
    const apiData = await service.fetchGetListApproveCallPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onUpdateApproveListApproveCallPlan = createAsyncThunk('approve/approve', async (params: any, thunkAPI) => {
  try {
    const apiData = await service.updateApproveListApproveCallPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onUpdateRejectListApproveCallPlan = createAsyncThunk('approve/reject', async (params: any, thunkAPI) => {
  try {
    const apiData = await service.updateRejectListApproveCallPlan(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
