import { Exclude, Expose, Type } from "class-transformer";
import { OrganizeModel } from "./organize";
// import { ImageModel } from './image';

export class NotificationModel {
  constructor() {
    this.id = 0;
    this.date = new Date();
    this.alertDate = "";
    this.title = "";
    this.content = "";
    this.historyId = 0;
    this.isCommonMessage = false;
    this.destinationPage = null;
    this.saleOrgData = null;
    this.firstName = null;
    this.lastName = null;
    this.postName = null;
    this.notifyPic1 = null;
    this.notifyPic2 = null;
    this.notifyPic3 = null;
    this.notifyPic4 = null;
    this.status = "";
    this.action = "";
    this.restore = "";
    this.group = "";
    this.isUnread = false;
  }

  @Expose({ name: "isUnread" })
  isUnread: boolean | undefined;

  @Expose({ name: "notificationGroupName" })
  group: string | undefined;

  @Expose({ name: "notifyStatus" })
  status: string | undefined;

  @Expose({ name: "actionName" })
  action: string | undefined;

  @Expose({ name: "restoreTitleName" })
  restore: string | undefined;

  @Expose({ name: "notifyHistoryId" })
  historyId: string | number | null | undefined;

  @Expose({ name: "notifyHistoryDate" })
  @Type(() => Date)
  date: Date | undefined;

  @Expose({ name: "alertDate" })
  alertDate: string | undefined;

  @Expose({ name: "notifyId" })
  id: number;

  @Expose({ name: "firstName" })
  firstName: string | null;

  @Expose({ name: "lastName" })
  lastName: string | null;

  @Expose({ name: "postNameThai" })
  postName: string | null | undefined;

  @Expose({ name: "notifyTitle" })
  title: string;

  @Expose({ name: "notifyContent" })
  content: string;

  @Expose({ name: "notifyPic1" })
  notifyPic1: string | null;

  @Expose({ name: "notifyPic2" })
  notifyPic2: string | null;

  @Expose({ name: "notifyPic3" })
  notifyPic3: string | null;

  @Expose({ name: "notifyPic4" })
  notifyPic4: string | null;

  @Expose({ name: "iscommonMessage" })
  isCommonMessage: boolean;

  destinationPage: string | null;

  @Type(() => OrganizeModel)
  saleOrgData?: OrganizeModel | null;

  @Expose()
  get images() {
    let imgs = [];
    if (this.notifyPic1) {
      imgs.push(this.notifyPic1);
    }
    if (this.notifyPic2) {
      imgs.push(this.notifyPic2);
    }
    if (this.notifyPic3) {
      imgs.push(this.notifyPic3);
    }
    if (this.notifyPic4) {
      imgs.push(this.notifyPic4);
    }
    return imgs;
  }
}

export class UnreadModel {
  constructor() {
    this.hasUnreadMessage = false;
  }

  @Expose({ name: "hasUnreadMessage" })
  hasUnreadMessage: boolean;
}
