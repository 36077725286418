import { ReportDashboardMskApi } from '../api/report/dashboard_msk';

const api = new ReportDashboardMskApi();

//Services
export class ReportDashboardMskService {
  async getMSKSubAgentPurchasePercent(params: any = {}) {
    try {
      const resp: any = await api.getMSKSubAgentPurchasePercent(params);

      let data: any = {
        report: [],
        filter: [],
        updatedDate: null,
      };

      if (resp?.data !== undefined) {
        resp?.data?.report.map((item: any) => {
          let itm = {
            name: item.ProductName,
            value: item.ProductValue,
            color: item.ColorCode,
          };
          data.report.push(itm);
        });

        data.filter = resp?.data?.filter;
        data.updatedDate = resp?.data?.syncDatetime;

        return data;
      } else {
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  }
}
