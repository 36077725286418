import { CustomerApi } from './api/customer';
import { plainToClassFromExist } from 'class-transformer';

import { ResponsesModel } from '@/models/classes/responses';
import { CustomersModel } from '@/models/classes/customer';
import { CustomerDetailModel } from '@/models/classes/customer/detail';
import { ResponseModel } from '@/models/classes/response';
import { SuccessModel } from '@/models/classes/success';
import { OptionModel } from '@/models/classes/option';
import { BUTypeModel, CustomerTypeModel, GroupShopTypeModel } from '@/models/classes/customer/shopType';

const api = new CustomerApi();

export class CustomerService {
  fetchCustomers = async (params: any = {}) => {
    const resData = await api.getCustomers(params);
    const resModel = plainToClassFromExist(new ResponseModel<CustomersModel>(CustomersModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data.customers,
        options: resModel.data.filter,
        total: resModel.total,
      };
    }
    return undefined;
  };

  getDetail = async (params: any = {}) => {
    const resData = await api.getDetail(params);

    const resModel = plainToClassFromExist(new ResponseModel<CustomerDetailModel>(CustomerDetailModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  updateCustomerLocation = async (params: any = {}) => {
    const resData = await api.updateCustomerLocation(params);

    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  getSearchProvinceAddress = async (params: any = {}) => {
    const resData = await api.getSearchProvinceAddress(params);

    const resModel = plainToClassFromExist(new ResponsesModel<OptionModel>(OptionModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total };
    }
    return undefined;
  };

  getSearchDistrictAddress = async (params: any = {}) => {
    const resData = await api.getSearchDistrictAddress(params);

    const resModel = plainToClassFromExist(new ResponsesModel<OptionModel>(OptionModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total };
    }
    return undefined;
  };

  getSearchSubDistrictAddress = async (params: any = {}) => {
    const resData = await api.getSearchSubDistrictAddress(params);

    const resModel = plainToClassFromExist(new ResponsesModel<OptionModel>(OptionModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total };
    }
    return undefined;
  };

  postAddCustomerProfile = async (params: any = {}) => {
    const resData = await api.postAddCustomerProfile(params);

    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  getCustomerProfileDropdown = async (params: any = {}) => {
    const resData = await api.getCustomerProfileDropdown(params);
    const resModel = plainToClassFromExist(new ResponseModel<GroupShopTypeModel>(GroupShopTypeModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  getCustomerTierDropdown = async (params: any = {}) => {
    const resData = await api.getCustomerTierDropdown(params);
    const resModel = plainToClassFromExist(new ResponseModel<BUTypeModel>(BUTypeModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  getAddCustomerDropdown = async (params: any = {}) => {
    const resData = await api.getAddCustomerDropdown(params);
    const resModel = plainToClassFromExist(new ResponseModel<CustomerTypeModel>(CustomerTypeModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  submitCustomerOtherOrg = async (params: any = {}) => {
    const resData = await api.submitCustomerOtherOrg(params);

    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  async putUpdateCustomerName(params: any) {
    const resData = await api.putUpdateCustomerName(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async putUpdateCustomerTaxNo(params: any) {
    const resData = await api.putUpdateCustomerTaxNo(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async putUpdateCustomerBranch(params: any) {
    const resData = await api.putUpdateCustomerBranch(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async putUpdateCustomerPhone(params: any) {
    const resData = await api.putUpdateCustomerPhone(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async putUpdateCustomerShopType(params: any) {
    const resData = await api.putUpdateCustomerShopType(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async putUpdateCustomerAddress(params: any) {
    const resData = await api.putUpdateCustomerAddress(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }

  async putUpdateCustomerBu(params: any) {
    const resData = await api.putUpdateCustomerBu(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }
}
