import { Expose, Type } from "class-transformer";

export class OffPlanFilterModel {
  constructor() {
    this.text = "";
    this.value = 0;
    this.color = "";
    this.default = false;
  }

  @Expose({ name: "filterText" })
  text: string;

  @Expose({ name: "filterValue" })
  value: number;

  @Expose({ name: "filterColor" })
  color: string;

  @Expose({ name: "isDefault" })
  default: boolean;
}
