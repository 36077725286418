import { Expose, Type } from "class-transformer";
import { OptionModel } from "../option";

export class BudgetModel {
  constructor() {
    this.spend = 0;
    this.allocate = 0;
  }

  @Expose({ name: "spend" })
  spend: number;

  @Expose({ name: "allocate" })
  allocate: number;
}

export class AchievementModel {
  constructor() {
    this.output = 0;
    this.goal = 0;
  }

  @Expose({ name: "aggregateOutput" })
  output: number;

  @Expose({ name: "aggregateGoal" })
  goal: number;
}

export class TotalSaleTargetModel {
  constructor() {
    this.coordinator = "";
    this.product = [];
  }

  @Expose({ name: "activitycoordinator" })
  coordinator: string;

  @Expose({ name: "product" })
  product?: [];
}

export class ActivityHistoryModel {
  constructor() {
    this.image = "";
    this.date = "";
    this.name = "";
    this.budget = undefined;
    this.achievement = undefined;
    this.totalsalestarget = undefined;
  }

  @Expose({ name: "surveyPic" })
  image: string | any;

  @Expose({ name: "surveyDate" })
  date: string | any;

  @Expose({ name: "activityName" })
  name: string | any;

  @Type(() => BudgetModel)
  @Expose({ name: "budget" })
  budget?: BudgetModel;

  @Type(() => AchievementModel)
  @Expose({ name: "achievement" })
  achievement?: AchievementModel;

  @Type(() => TotalSaleTargetModel)
  @Expose({ name: "totalsalestarget" })
  totalsalestarget?: TotalSaleTargetModel;
}

export class ActivityNextModel {
  constructor() {
    this.image = "";
    this.date = "";
    this.name = "";
    this.budget = undefined;
    this.achievement = undefined;
    this.totalsalestarget = undefined;
  }

  @Expose({ name: "surveyPic" })
  image: string | any;

  @Expose({ name: "monthEventMap" })
  date: string;

  @Expose({ name: "activityName" })
  name: string;

  @Type(() => BudgetModel)
  @Expose({ name: "budget" })
  budget?: BudgetModel;

  @Type(() => AchievementModel)
  @Expose({ name: "achievement" })
  achievement?: AchievementModel;

  @Type(() => TotalSaleTargetModel)
  @Expose({ name: "totalsalestarget" })
  totalsalestarget?: TotalSaleTargetModel;
}

export class FrequencyModel {
  constructor() {
    this.amount = 0;
    this.month = "";
    this.sequence = 0;
  }

  @Expose({ name: "frequency" })
  amount: number;

  @Expose({ name: "monthName" })
  month: string;

  sequence: number;
}

export class HistoryModel {
  constructor() {
    this.date = "";
    this.visitPlanStatus = "";
    this.sequence = 0;
    this.detail = "";
  }

  sequence: number;
  visitPlanStatus: string;

  @Expose({ name: "visitPlanDate" })
  date: string;

  @Expose({ name: "visitPlanDetail" })
  detail: string;

  get status() {
    let value = 0;
    if (this.visitPlanStatus == "COMPLETE") {
      value = 1;
    } else if (this.visitPlanStatus == "NEVER") {
      value = 2;
    } else if (this.visitPlanStatus == "NEXT") {
      value = 3;
    } else if (this.visitPlanStatus == "INCOMPLETE") {
      value = 4;
    }

    return value;
  }

  get statusText() {
    let value = "";
    if (this.visitPlanStatus == "COMPLETE") {
      value = "เยี่ยมสำเร็จ";
    } else if (this.visitPlanStatus == "NEVER") {
      value = "ไม่มีการเข้าเยี่ยมในวันนี้";
    } else if (this.visitPlanStatus == "NEXT") {
      value = "แผนครั้งถัดไป";
    } else if (this.visitPlanStatus == "INCOMPLETE") {
      value = "เยี่ยมไม่สำเร็จ";
    } else {
      value = "ยังไม่ถึงรอบ";
    }
    return value;
  }
}

export class ManagerModel {
  constructor() {
    this.contactId = 0;
    this.saleOrgCriteriaId = 0;
    this.saleOrgCriteriaName = "";
    this.titleName = "";
    this.firstName = "";
    this.lastName = "";
    this.phone = "";
    this.email = "";
    this.date = "";
    this.isCurrent = false;
    this.image = undefined;
  }

  contactId: number;
  saleOrgCriteriaId: number;
  saleOrgCriteriaName: string;
  titleName: string;
  firstName: string;
  lastName: string;
  email: string;

  @Expose({ name: "mobileNo" })
  phone: string;

  @Expose({ name: "lastEffectiveDate" })
  date: string;

  @Expose({ name: "currentFlag" })
  isCurrent: boolean;

  @Expose({ name: "imagePath" })
  image?: string | null;

  get name() {
    return this.titleName + this.firstName + " " + this.lastName;
  }
}

export class SaleManagerByCustomerModel {
  constructor() {
    this.month = undefined;
    this.frequency = undefined;
    this.manager = [];
    this.history = [];
  }

  @Type(() => OptionModel)
  @Expose({ name: "viewMonthFilter" })
  month?: OptionModel[];

  @Type(() => FrequencyModel)
  @Expose({ name: "visitPlanFrequency" })
  frequency?: FrequencyModel[];

  @Type(() => ManagerModel)
  @Expose({ name: "saleManagerHistory" })
  manager?: ManagerModel[];

  @Type(() => HistoryModel)
  @Expose({ name: "visitPlanDateHistory" })
  history?: HistoryModel[];
}

export class CustomerModel {
  constructor() {
    this.id = 0;
    this.prefix = "";
    this.name = "";
    this.code = "";
    this.owner = "";
    this.phone = "";
    // this.latitude = 0;
    // this.longitude = 0;
    this.image = "";
    this.note = "";
    this.address = "";
    // this.agentType = "";
    this.salesOrg = "";
    this.isDisableCheckIn = true;
    this.showCheckoutMenu = false;
    this.eActivityList = [];
    this.customerPlanId = 0;
    this.planID = undefined;
    this.routePlanId = undefined;
    this.customerLocationData = undefined;
    this.managerName = "";
    this.subManagerName = "";
    this.sellerName = "";
    // this.visitorName = "";
    // this.visitDate = "";
    this.mobile = "";
    this.customerTierListData = []
    this.latestemployeevisit = undefined;
  }

  @Expose({ name: "customerId" })
  id: number;

  @Expose({ name: "customerPrefix" })
  prefix: string;

  @Expose({ name: "customerName" })
  name: string;

  @Expose({ name: "customerCode" })
  code: string;

  @Expose({ name: "ownerName" })
  owner: string;

  @Expose({ name: "ownerMobileNo" })
  mobile: string;

  @Expose({ name: "CustomerTel" })
  phone: string;

  @Expose({ name: "address" })
  address: string;

  @Expose({ name: "customerLocationData" })
  customerLocationData: any;

  @Expose({ name: "customerTierListData" })
  customerTierListData: any;

  // @Expose({ name: "latitude" })
  // latitude: number | null;

  // @Expose({ name: "longitude" })
  // longitude: number | null;

  @Expose({ name: "customerPicName" })
  image: string;

  @Expose({ name: "note" })
  note: string | null;

  // @Expose({ name: "agentType" })
  // agentType: string;

  @Expose({ name: "salesOrg" })
  salesOrg: string;

  @Expose({ name: "isDisableCheckIn" })
  isDisableCheckIn: boolean;

  @Expose({ name: "showCheckoutMenu" })
  showCheckoutMenu: boolean;

  @Expose({ name: "lstEact" })
  eActivityList: [];

  @Expose({ name: "customerPlanId" })
  customerPlanId: number | string | null;

  @Expose({ name: "planID" })
  planID?: number | string | null;

  @Expose({ name: "routePlanId" })
  routePlanId?: number | string | null;

  @Expose({ name: "manager" })
  managerName: string;

  @Expose({ name: "assistantmanager" })
  subManagerName: string;

  @Expose({ name: "saleman" })
  sellerName: string;

  // @Expose({ name: "customerId" })
  // visitorName: string;

  // @Expose({ name: "visitDate" })
  // visitDate: string;

  @Expose({ name: "latestemployeevisit" })
  latestemployeevisit: any;


  get visitorName() {
    if (this.latestemployeevisit) {
      return this.latestemployeevisit?.empName
    } else {
      return undefined
    }
  }

  get visitDate() {
    if (this.latestemployeevisit) {
      return this.latestemployeevisit?.visitDate
    } else {
      return undefined
    }
  }

  get latitude() {
    return this.customerLocationData?.latitude ?? 0;
  }

  get longitude() {
    return this.customerLocationData?.longtitude ?? 0;
  }

  get tier() {
    let lst = new Array();
    this.customerTierListData?.map((item: any) => {
      lst.push(item.shopAttribute)
    })
    return lst.join(", ");
  }
}


export class H2HModel {
  constructor() {
    this.companyBrand = "";
    this.companyColorCode = "";
    this.otherBrand = "";
    this.otherColorCode = "";
    this.percentOfBrand = "";
  }

  @Expose({ name: "companyBrand" })
  companyBrand: string;

  @Expose({ name: "companyColorCode" })
  companyColorCode: string;

  @Expose({ name: "otherBrand" })
  otherBrand: string;

  @Expose({ name: "otherColorCode" })
  otherColorCode: string;

  @Expose({ name: "percentOfBrand" })
  percentOfBrand: string;

}

export class FilterModel {
  constructor() {
    this.text = "";
    this.value = 0;
    this.color = "";
    this.default = false;
  }

  @Expose({ name: "filterText" })
  text: string;

  @Expose({ name: "filterValue" })
  value: number;

  @Expose({ name: "filterColor" })
  color: string;

  @Expose({ name: "isDefault" })
  default: boolean;
}


export class MapCustomerModel {
  constructor() {
    this.id = 0;
    this.code = "";
    this.name = "";
    this.latitude = 0;
    this.longitude = 0;
    this.type = 0;
  }

  @Expose({ name: "customerId" })
  id: number;

  @Expose({ name: "customerCode" })
  code: string;

  @Expose({ name: "customerName" })
  name: string;

  @Expose({ name: "latitude" })
  latitude: number;

  @Expose({ name: "longitude" })
  longitude: number;

  @Expose({ name: "type" })
  type: number;
}
