import { Expose, Type } from 'class-transformer';

export class AssitantPerformanceModel {
  constructor() {
    this.name = '';
    this.purchaseLabel = '';
    this.purchasePercentage = 0;
    this.purchaseSales = 0;
    this.purchaseTarget = 0;
    this.stockLabel = '';
    this.stockPercentage = 0;
    this.stockSales = 0;
    this.stockTarget = 0;
    this.sellOutLabel = '';
    this.sellOutPercentage = 0;
    this.sellOutSales = 0;
    this.sellOutTarget = 0;
  }

  name: string;

  purchaseLabel: string;
  purchasePercentage: number;
  purchaseSales: number;
  purchaseTarget: number;

  stockLabel: string;
  stockPercentage: number;
  stockSales: number;
  stockTarget: number;

  sellOutLabel: string;
  sellOutPercentage: number;
  sellOutSales: number;
  sellOutTarget: number;

  @Expose({ name: 'updatetedDate' })
  @Type(() => Date)
  updatedDate?: Date;

  get barChart() {
    return [
      {
        name: this.purchaseLabel,
        legend: 'ยอดซื้อซับ',
        percentags: this.purchasePercentage,
        value: this.purchaseSales,
        total: this.purchaseTarget,
        unit: 'ลัง',
        color: 'primary-50',
      },
      {
        name: this.stockLabel,
        legend: 'สต๊อก',
        percentags: this.stockPercentage,
        value: this.stockSales,
        total: this.stockTarget,
        unit: 'ลัง',
        color: 'info-200',
      },
      {
        name: this.sellOutLabel,
        legend: 'Sell Out',
        percentags: this.sellOutPercentage,
        value: this.sellOutSales,
        total: this.sellOutTarget,
        unit: 'ลัง',
        color: 'secondary',
      },
    ];
  }

  radialChart(isDark: Boolean) {
    return [
      {
        value: this.purchasePercentage,
        color: isDark ? '#A3FABF' : '#7DB792',
      },
      {
        value: this.stockPercentage,
        color: isDark ? '#15CFF5' : '#75C0CE',
      },
    ];
  }
}
