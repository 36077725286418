import { IsNumber, IsString } from 'class-validator';
import { Expose, Transform, Type } from 'class-transformer';

export class TitleModel {
  constructor() {
    this.name = '';
    this.unit = '';
    this.actual = 0;
    this.total = 0;
  }

  name: string;
  unit: string;
  actual: string | number;
  total: string | number;
}

export class DataModel {
  constructor() {
    this.order = 0;
    this.left = new TitleModel();
    this.rigth = new TitleModel();
    this.children = [];
  }

  order: number;

  @Type(() => TitleModel)
  @Expose()
  left: TitleModel;

  @Type(() => TitleModel)
  @Expose()
  rigth: TitleModel;

  @Type(() => DataModel)
  @Expose()
  children: DataModel[];
}

export class ExpandListTwoColumnModel {
  constructor() {
    this.name = '';
    this.unit = '';
    this.month = '';
    this.left = new TitleModel();
    this.rigth = new TitleModel();
    this.data = new DataModel();
  }

  @Type(() => DataModel)
  @Expose()
  data: DataModel;

  name: string;
  unit: string;
  month: string;

  @Type(() => TitleModel)
  @Expose()
  left: TitleModel;

  @Type(() => TitleModel)
  @Expose()
  rigth: TitleModel;
}
