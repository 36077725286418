import { Expose } from "class-transformer";

export class OptionModel {
  constructor() {
    this.text = "";
    this.value = "";
  }

  @Expose({ name: "optionText" })
  text: string;

  @Expose({ name: "optionValue" })
  value: string|number;
}
