import { OrganizeModel } from "@/models/classes/organize";
import { AuthApi } from "./api/auth";
import { plainToClassFromExist } from "class-transformer";
import { ResponseModel } from "@/models/classes/response";
import { UserModel, SaleOrgModel } from "@/models/classes/user";
import { SuccessModel } from "@/models/classes/success";
import {  MenuCallPlanItem } from "@/models/classes/plan/visit";

const api = new AuthApi();
export class AuthService {
  getUser = async () => {
    const resOrgData = await api.getCurrentSaleOrg();
    const resData = await api.getUser();

    const resModel = plainToClassFromExist(
      new ResponseModel<UserModel>(UserModel),
      resData
    );

    const resOrgModel = plainToClassFromExist(
      new ResponseModel<SaleOrgModel>(SaleOrgModel),
      resOrgData
    );

    if (resModel.status == 200 && typeof resModel.data == "object") {
      return { user: resModel.data, saleOrg: resOrgModel.data };
    }
    return undefined;
  };

  fetchOrganize = async (company?: string | null, channel?: string | null) => {
    const resData = await api.fetchOrganize(company, channel);
    const resModel = plainToClassFromExist(
      new ResponseModel<OrganizeModel>(OrganizeModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return {
        data: resModel.data,
        selectedIndex: resData?.selectedIndex,
        selectedValue: resData?.selectedValue,
      };
    }
    return undefined;
  };

  updateOrganize = async (company: string, channel: string, sale: number) => {
    const resData = await api.updateOrganize(company, channel, sale);
    const resModel = plainToClassFromExist(
      new ResponseModel<SuccessModel>(SuccessModel),
      resData
    );
    if (resModel.status == 200 && typeof resModel.data == "object") {
      return resModel.data;
    }
    return false;
  };

  fetchMenuCallPlan = async (params: any = {}) => {
    const resData = await api.getMenuCallPlan(params);//MenuCallPlan
    // console.log("+++menu callplan res data", resData)
    const resModel = plainToClassFromExist(new ResponseModel<MenuCallPlanItem[]>(MenuCallPlanItem), resData);
    // console.log("+++menu callplan res model", resModel)
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  }
}
