import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type } from "class-transformer";

export class LegendModel {
  constructor() {
    this.legend = "";
  }
  legend: string;
}

export class DataModel {
  constructor() {
    this.name = "";
    this.unit = "";
    this.order = 0;
    this.actual = 0;
    this.target = 0;
    this.percent = 0;
    this.total = 0;
    this.total_unit = "";
    this.children = [];
  }

  name: string;
  unit: string;
  order: number;
  actual: number;
  target: number;
  total: number;
  total_unit: string;
  percent: number;
  
  @Type(() => DataModel)
  @Expose()
  children: DataModel[];

}

export class ExpandListOneColumnModel {
  constructor() {
    this.name = "";
    this.unit = "";
    this.amount = 0;
    this.amount_unit = "";
    this.actual = new LegendModel;
    this.target = new LegendModel;
    this.data = new DataModel;
  }

  @Type(() => DataModel)
  @Expose()
  data: DataModel;

  name: string;
  unit: string;
  amount: number;
  amount_unit: string;
  
  @Type(() => LegendModel)
  @Expose({ name: "actual" })
  actual: LegendModel;
  
  @Type(() => LegendModel)
  @Expose({ name: "target" })
  target: LegendModel;

}