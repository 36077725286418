import axios from 'axios';

export class ApproveCallPlanApi {
  async getListApproveCallPlan() {
    let path = `/api/CallPlan/GetListApproveCallPlan`;

    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async updateApproveListApproveCallPlan(payload: any) {
    var config = {
      method: 'post',
      url: `/api/CallPlan/UpdateApproveListApproveCallPlan`,
      data: JSON.stringify(payload),
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async updateRejectListApproveCallPlan(payload: any) {
    var config = {
      method: 'post',
      url: `/api/CallPlan/UpdateRejectListApproveCallPlan`,
      data: JSON.stringify(payload),
    };
    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}
