import { Expose, Type } from "class-transformer";

export class VerifyOffPlanModel {
  constructor() {
    this.planId = 0;
    this.customerPlanId = 0;
    this.routePlanId = 0;
    this.result = false;
    this.message = "";
  }

  @Expose({ name: "planId" })
  planId: number;

  @Expose({ name: "customerPlanId" })
  customerPlanId: number;

  @Expose({ name: "routePlanId" })
  routePlanId: number;

  @Expose({ name: "result" })
  result: boolean;

  @Expose({ name: "longitude" })
  message: string;
}
