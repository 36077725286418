import { Type, Expose } from 'class-transformer';
import { IsNumber, IsString } from 'class-validator';
import { ToDate } from '../_transform/to_date';

class BusinessReviewItem {
  constructor() {
    this.businessReviewId = 0;
    this.customerName = '';
    this.ownerName = '';
    this.businessReviewCreator = '';
    this.businessReviewCategory = '';
    this.businessReviewCategoryName = '';
    this.businessReviewDate = '';
    this.updatedDate = '';
    this.modifiedDate = new Date();
    this.selected=false;
  }

  @IsNumber()
  @Expose({ name: 'businessReviewId' })
  businessReviewId: number;

  @IsString()
  @Expose({ name: 'customerName' })
  customerName: string;
  
  selected: boolean;

  @IsString()
  @Expose({ name: 'ownerName' })
  ownerName: string;

  @IsString()
  @Expose({ name: 'businessReviewCreator' })
  businessReviewCreator: string;

  @IsString()
  @Expose({ name: 'businessReviewCategory' })
  businessReviewCategory: string;

  @IsString()
  @Expose({ name: 'businessReviewCategoryName' })
  businessReviewCategoryName: string;

  @IsString()
  @Expose({ name: 'businessReviewDate' })
  businessReviewDate: string;

  @IsString()
  @Expose({ name: 'updatedDate' })
  updatedDate: string;

  @ToDate()
  @Expose({ name: 'modifiedDate' })
  modifiedDate: Date;
}

export default BusinessReviewItem;
