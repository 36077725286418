import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import Service from '@/services';
import { OffPlanFilterModel } from '@/models/classes/plan/off_plan/filter';
import { OffPlanCustomerModel } from '@/models/classes/plan/off_plan/customer';
import { VerifyOffPlanModel } from '@/models/classes/plan/off_plan';
import { mergeObjects } from '@/utils/utils';

const service = new Service.OffPlanService();

// Interface
interface OffPlanState {
  customer: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: OffPlanCustomerModel[];
    total?: number;
  };
  searchCustomer: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: OffPlanCustomerModel[];
    total?: number;
  };
  filter: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: OffPlanFilterModel[];
    total?: number;
  };
  verify: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: VerifyOffPlanModel;
  };
}

// Initialize State
const initialState: OffPlanState = {
  customer: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: undefined,
  },
  searchCustomer: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: undefined,
  },
  filter: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: undefined,
  },
  verify: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'OffPlan',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(onFetchFilter.pending, (state) => {
      state.filter.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchFilter.fulfilled, (state, action) => {
      state.filter.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.filter.data = action.payload.data;
        state.filter.total = action.payload.total;
      }
    });
    builder.addCase(onFetchFilter.rejected, (state, action) => {
      state.filter.network.code = ServiceStatus.failed;
      state.filter.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch User
    builder.addCase(onFetchCustomer.pending, (state) => {
      state.customer.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchCustomer.fulfilled, (state, action) => {
      state.customer.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.customer.data = action.payload.data;
        state.customer.total = action.payload.total;
      }
    });
    builder.addCase(onFetchCustomer.rejected, (state, action) => {
      state.customer.network.code = ServiceStatus.failed;
      state.customer.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSearchCustomerOffPlan.pending, (state) => {
      state.searchCustomer.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSearchCustomerOffPlan.fulfilled, (state, action) => {
      state.searchCustomer.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        if (action.payload.param?.offset == 0) {
          state.searchCustomer.data = action.payload.apiData!.data;
        } else {
          if (state?.searchCustomer?.data) {
            state.searchCustomer.data = [...state?.searchCustomer?.data, ...action.payload.apiData!.data];
          }
        }
        state.searchCustomer.total = action.payload.apiData!.total;
      }
    });
    builder.addCase(onFetchSearchCustomerOffPlan.rejected, (state, action) => {
      state.searchCustomer.network.code = ServiceStatus.failed;
      state.searchCustomer.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchVerifyOffPlan.pending, (state) => {
      state.verify.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchVerifyOffPlan.fulfilled, (state, action) => {
      state.verify.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.verify.data = action.payload;
      }
    });
    builder.addCase(onFetchVerifyOffPlan.rejected, (state, action) => {
      state.verify.network.code = ServiceStatus.failed;
      state.verify.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchFilter = createAsyncThunk('off_plan/filter', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchFilter();
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchCustomer = createAsyncThunk('off_plan/customers', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchCustomer(param.filter, param.latitude, param.longitude, param.radius);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSearchCustomerOffPlan = createAsyncThunk('off_plan/customers/search', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchCustomerSearch(param.searchText, param.limit, param.offset, param.filter);
    return { apiData, param };
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchVerifyOffPlan = createAsyncThunk('off_plan/verify', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchVerifyOffPlan(params);
    return apiData;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { patch, reset } = slice.actions;

// Export
export default slice.reducer;
