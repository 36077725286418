import { Type, Expose } from "class-transformer";
import { IsNumber, IsString } from "class-validator";
import { ToDate } from "../_transform/to_date";
import { OptionModel } from "../option";
import Summary from "./summary";

export class ReviewModel {
  constructor() {
    this.id = 0;
    this.category = "";
    this.customer = "";
    this.owner = "";
    this.date = new Date();
    this.creator = "";
  }

  @Expose({ name: "businessReviewId" })
  id: number;

  @Expose({ name: "categoryName" })
  category: string;

  @Expose({ name: "customerName" })
  customer: string;

  @Expose({ name: "ownerName" })
  owner: string;

  @Expose({ name: "modifiedDate" })
  date: Date;

  @Expose({ name: "transCreatorName" })
  creator: string;
}

export class ReviewBySalesEmployeeModel {
  constructor() {
    this.reviews = [];
    this.quarter = [];
    this.summary = undefined;
  }

  @Type(() => OptionModel)
  @Expose({ name: "lstOptionQuarter" })
  quarter: OptionModel[];

  @Type(() => ReviewModel)
  @Expose({ name: "lstBusinessReview" })
  reviews: ReviewModel[];

  @Type(() => Summary)
  @Expose({ name: "summaryBusinessReview" })
  summary?: Summary;
}
