import axios from 'axios';

export class WeeklyReportApi {
  async getListOfSummarySalesWeeklyReport(params: any = {}) {
    let path = `/api/SalesWeekly/ListOfSummarySalesWeeklyReport?RegionId=${params.region}&saleOrgEmployeeId=${params.employee}&Period=${params.period}&Limit=${params.limit}&Offset=${params.offset}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getMySalesWeeklyReport(params: any = {}) {
    let path = `/api/SalesWeekly/GetMySalesWeeklyReport?SalesWeeklyReportId=${params.id}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getListOfMySalesWeeklyReport(params: any = {}) {
    let path = `/api/SalesWeekly/ListOfMySalesWeeklyReport?Limit=${params.limit}&Offset=${params.offset}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getReportDetail(params: any = {}) {
    let path = `/api/SalesWeekly/GetSummarySalesWeeklyReport?SalesWeeklyReportId=${params.id}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getRegionFilter(params: any = {}) {
    let path = `/api/SalesWeekly/GetRegionFilter`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getEmployeeFilter(params: any = {}) {
    let path = `/api/SalesWeekly/GetSalesEmployeeFilter?Limit=${params.limit}&Offset=${params.offset}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getDurationFilter(params: any = {}) {
    let path = `/api/SalesWeekly/GetDurationFilter`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async postSubmitAcknowledgeSalesWeeklyReport(data: any) {
    try {
      const res = await axios.post(`/api/SalesWeekly/SubmitAcknowledgeSalesWeeklyReport`, data);
      return res.data;
    } catch (err) {
      return undefined;
    }
  }

  async postSubmitSummarySalesWeeklyReport(data: any) {
    try {
      const res = await axios.post(`/api/SalesWeekly/SubmitSummarySalesWeeklyReport`, data);
      return res.data;
    } catch (err) {
      return undefined;
    }
  }

  async postSubmitMySalesWeeklyReport(data: any) {
    try {
      const res = await axios.post(`/api/SalesWeekly/SubmitMySalesWeeklyReport`, data);
      return res.data;
    } catch (err) {
      return undefined;
    }
  }

  async postSendMySalesWeeklyReport(params: any = {}) {
    try {
      const res = await axios.post(`/api/SalesWeekly/SendMySalesWeeklyReport?SalesWeeklyReportId=${params.id}`, {});
      return res.data;
    } catch (err) {
      return undefined;
    }
  }
}
