import { MorningTalkApi } from './api/morning_talk';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { ResponsesModel } from '@/models/classes/responses';
import { SuccessModel } from '@/models/classes/success';
import { MorningTalkDetailModel, MorningTalkModel } from '@/models/classes/morning_talk';
import { ResponseModel } from '@/models/classes/response';

const api = new MorningTalkApi();

export class MorningTalkService {
  fetchGetListTodayMeeting = async (params: any = {}) => {
    const resData = await api.getListTodayMeeting();
    const resModel = plainToClassFromExist(new ResponsesModel<MorningTalkModel>(MorningTalkModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total };
    }
    return undefined;
  };

  fetchGetSearchHistory = async (params: any = {}) => {
    const resData = await api.getSearchHistory(params);
    const resModel = plainToClassFromExist(new ResponsesModel<MorningTalkModel>(MorningTalkModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total };
    }
    return undefined;
  };

  fetchViewTodayMeetingRoom = async (params: any = {}) => {
    const resData = await api.getViewTodayMeetingRoom(params);
    const resModel = plainToClassFromExist(new ResponseModel<MorningTalkDetailModel>(MorningTalkDetailModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchViewHistoryMeeting = async (params: any = {}) => {
    const resData = await api.getViewHistoryMeeting(params);
    const resModel = plainToClassFromExist(new ResponseModel<MorningTalkDetailModel>(MorningTalkDetailModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchSubmitJoinMeeting = async (params: any = {}) => {
    const resData = await api.submitJoinMeeting(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  fetchSubmitNoteMeeting = async (params: any = {}) => {
    const resData = await api.submitNoteMeeting(params);
    const resModel = plainToClassFromExist(new ResponseModel<SuccessModel>(SuccessModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };
}
