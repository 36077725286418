import { ManagementApi } from './api/management/index';
import { ActivityHistoryModel, ActivityNextModel, CustomerModel, FilterModel, H2HModel, MapCustomerModel, SaleManagerByCustomerModel } from '@/models/classes/management';

import { plainToClassFromExist } from 'class-transformer';
import { ResponsesModel } from '@/models/classes/responses';
import { ActivityItemModel } from '@/models/classes/overview/activity_items';
import { ResponseModel } from '@/models/classes/response';

const api = new ManagementApi();

export class ManagementService {
  fetchFilter = async (params: any = {}) => {
    const resData = await api.getFilter(params);
    const resModel = plainToClassFromExist(new ResponsesModel<FilterModel>(FilterModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data,
        total: resModel.total,
      };
    }
    return undefined;
  };

  fetchCustomer = async (params: any = {}) => {
    const resData = await api.getCustomer(params);
    const resModel = plainToClassFromExist(new ResponsesModel<MapCustomerModel>(MapCustomerModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data,
        total: resModel.total,
      };
    }
    return undefined;
  };

  fetchSearchCustomer = async (params: any = {}) => {
    const resData = await api.getSearchCustomer(params);
    const resModel = plainToClassFromExist(new ResponsesModel<MapCustomerModel>(MapCustomerModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data,
        total: resModel.total,
      };
    }
    return undefined;
  };

  fetchActivityHistory = async (params: any = {}) => {
    const resData = await api.getActivityHistory(params);
    const resModel = plainToClassFromExist(new ResponsesModel<ActivityHistoryModel>(ActivityHistoryModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data,
        total: resModel.total,
      };
    }
    return undefined;
  };

  fetchActivityNext = async (params: any = {}) => {
    const resData = await api.getActivityNext(params);
    const resModel = plainToClassFromExist(new ResponsesModel<ActivityNextModel>(ActivityNextModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data,
        total: resModel.total,
      };
    }
    return undefined;
  };

  fetchEActivity = async (params: any = {}) => {
    const resData = await api.getEActivity(params);
    const resModel = plainToClassFromExist(new ResponsesModel<ActivityItemModel>(ActivityItemModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data,
        total: resModel.total,
      };
    }
    return undefined;
  };

  fetchGetCustomer = async (params: any = {}) => {
    const resData = await api.getDetailCustomer(params);
    const resModel = plainToClassFromExist(new ResponsesModel<CustomerModel>(CustomerModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return {
        data: resModel.data,
        total: resModel.total,
      };
    }
    return undefined;
  };

  fetchGetH2H = async (params: any = {}) => {
    const resData = await api.getDetailH2H(params);
    const resModel = plainToClassFromExist(new ResponsesModel<H2HModel>(H2HModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      let legend = new Array();
      if (resModel.data) {
        resModel.data?.map((item: H2HModel) => {
          legend.push({
            name: item.companyBrand,
            color: item.companyColorCode,
          });
          legend.push({
            name: item.otherBrand,
            color: item.otherColorCode,
          });
        });
      }

      return {
        data: resModel.data,
        total: resModel.total,
        legend: legend,
      };
    }
    return undefined;
  };

  fetchViewSaleManagerByCustomer = async (params: any = {}) => {
    const resData = await api.getViewSaleManagerByCustomer(params);
    const resModel = plainToClassFromExist(new ResponseModel<SaleManagerByCustomerModel>(SaleManagerByCustomerModel), resData);

    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data };
    }
    return undefined;
  };
}
