import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import Service from '@/services';
import { mergeObjects } from '@/utils/utils';
import { ActivityHistoryModel, ActivityNextModel } from '@/models/classes/management';
import { ActivityItemModel } from '@/models/classes/overview/activity_items';

const service = new Service.ManagementService();

// Interface
interface ManagementState {
  activityHistory: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ActivityHistoryModel[];
    total?: number;
  };
  activityNext: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ActivityNextModel[];
    total?: number;
  };
  eActivity: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ActivityItemModel[];
    total?: number;
  };
}

// Initialize State
const initialState: ManagementState = {
  activityHistory: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  activityNext: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
  eActivity: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    total: 0,
  },
};

// Reducer
export const slice = createSlice({
  name: 'Management',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(onFetchActivityHistory.pending, (state) => {
      state.activityHistory.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchActivityHistory.fulfilled, (state, action) => {
      state.activityHistory.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.activityHistory.data = action.payload.data;
        state.activityHistory.total = action.payload.total;
      }
    });
    builder.addCase(onFetchActivityHistory.rejected, (state, action) => {
      state.activityHistory.network.code = ServiceStatus.failed;
      state.activityHistory.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchMoreActivityHistory.pending, (state) => {
      state.activityHistory.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchMoreActivityHistory.fulfilled, (state, action) => {
      state.activityHistory.network.code = ServiceStatus.succeeded;
      if (action.payload != null && state.activityHistory.data) {
        let lst = [...state.activityHistory.data, ...action.payload.data];
        state.activityHistory.data = lst;
        state.activityHistory.total = action.payload.total;
      }
    });
    builder.addCase(onFetchMoreActivityHistory.rejected, (state, action) => {
      state.activityHistory.network.code = ServiceStatus.failed;
      state.activityHistory.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchActivityNext.pending, (state) => {
      state.activityNext.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchActivityNext.fulfilled, (state, action) => {
      state.activityNext.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.activityNext.data = action.payload.data;
        state.activityNext.total = action.payload.total;
      }
    });
    builder.addCase(onFetchActivityNext.rejected, (state, action) => {
      state.activityNext.network.code = ServiceStatus.failed;
      state.activityNext.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchMoreActivityNext.pending, (state) => {
      state.activityNext.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchMoreActivityNext.fulfilled, (state, action) => {
      state.activityNext.network.code = ServiceStatus.succeeded;
      if (action.payload != null && state.activityNext.data) {
        let lst = [...state.activityNext.data, ...action.payload.data];
        state.activityNext.data = lst;
        state.activityNext.total = action.payload.total;
      }
    });
    builder.addCase(onFetchMoreActivityNext.rejected, (state, action) => {
      state.activityNext.network.code = ServiceStatus.failed;
      state.activityNext.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchEActivity.pending, (state) => {
      state.eActivity.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchEActivity.fulfilled, (state, action) => {
      state.eActivity.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.eActivity.data = action.payload.data;
        state.eActivity.total = action.payload.total;
      }
    });
    builder.addCase(onFetchEActivity.rejected, (state, action) => {
      state.eActivity.network.code = ServiceStatus.failed;
      state.eActivity.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchActivityHistory = createAsyncThunk('management/activity/history', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchActivityHistory(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchMoreActivityHistory = createAsyncThunk('management/activity/history/more', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchActivityHistory(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchActivityNext = createAsyncThunk('management/activity/next', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchActivityNext(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchMoreActivityNext = createAsyncThunk('management/activity/next/more', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchActivityNext(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchEActivity = createAsyncThunk('management/eactivity', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchEActivity(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { patch, reset } = slice.actions;

// Export
export default slice.reducer;
