import axios, { AxiosRequestConfig } from 'axios';

export class CommunicationApi {
  async getSearchNotify(notifyType: number, limit: number = 10, offset: number = 0) {
    let path = `/api/Notificaiton/SearchNotify?notifyType=${notifyType}&limit=${limit}&offset=${offset}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getSearchHistory(searchText: string | null, notifyStatus: string, limit: number = 10, offset: number = 0) {
    let path = `/api/Notificaiton/SearchHistory?searchText=${searchText}&notifyStatus=${notifyStatus}&limit=${limit}&offset=${offset}`;

    try {
      const res = await axios.get(path);
      if (window.isOffline) {
        return null;
      } else {
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getNotify() {
    let path = `/api/Notificaiton/GetNotify`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async viewNotify(params: any = {}) {
    let path = `/api/Notificaiton/ViewNotify?notifyHistoryId=${params.notifyHistoryId}&commonFlag=${params.commonFlag}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async viewHistoryNotify(params: any = {}) {
    let path = `/api/Notificaiton/ViewHistoryNotify?notifyId=${params.notifyId}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async saveDraft(data: any) {
    try {
      const res = await axios.post(`/api/Notificaiton/SaveDraftNotify`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async restore(params: any = {}) {
    var config = {
      method: 'put',
      url: `/api/Notificaiton/RestoreNotify?notifyId=${params.id}`,
    };

    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async send(params: any = {}) {
    var config = {
      method: 'put',
      url: `/api/Notificaiton/SendNotify?notifyId=${params.id}`,
    };

    try {
      const res = await axios(config);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
}
