import axios from 'axios';
import * as qs from 'qs';

//Services
export class ReportDashboardAssistantApi {
  getAssistantSalePerformance = async (type: string | any, searchId: number | any, unit: string | any) => {
    let params = {
      type: type,
      searchId: searchId,
      unit: unit,
    };

    let path = `/api/DashBoard/GetAssistantSalePerformance?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  };

  getAssistantDashBoardStock = async (type: string | any, searchId: number | any, period: number | any, unit: string | any) => {
    let params = {
      type: type,
      searchId: searchId,
      period: period,
      unit: unit,
    };
    let path = `/api/DashBoard/GetAssistantDashBoardStock?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  };

  getAssistantDashBoardPurchase = async (type: string | any, searchId: number | any, unit: string | any) => {
    let params = {
      type: type,
      searchId: searchId,
      unit: unit,
    };

    let path = `/api/DashBoard/GetAssistantDashBoardPurchase?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  };

  getAssistantDashboardSellOut = async (type: string | any, searchId: number | any, unit: string | any) => {
    let params = {
      type: type,
      searchId: searchId,
      unit: unit,
    };
    let path = `/api/DashBoard/GetAssistantDashboardSellOut?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  };
}
