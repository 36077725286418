import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Service from '@/services';
import { ExpandListCallPlanModel } from '@/models/classes/report/expand_list_call_plan';
import { CallPlanOverviewModel } from '@/models/classes/report/call_plan';
import { mergeObjects } from '@/utils/utils';
const service = new Service.ReportDashboardCallPlanService();

// Interface
interface DashboardCallPlanState {
  callPlan: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: {
      onPlan?: ExpandListCallPlanModel[];
      offPlan?: ExpandListCallPlanModel[];
    };
  };
  overview: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: CallPlanOverviewModel;
  };
}

// Initialize State
const initialState: DashboardCallPlanState = {
  callPlan: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: {
      onPlan: undefined,
      offPlan: undefined,
    },
  },
  overview: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const dashboardCallPlanSlice = createSlice({
  name: 'dashboard_call_plan',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    // Fetch SaleReceiveMoney
    builder.addCase(onFetchSaleCallPlan.pending, (state, action) => {
      state.callPlan.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSaleCallPlan.fulfilled, (state, action) => {
      state.callPlan.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.callPlan.data = action.payload;
      } else {
        state.callPlan.data = undefined;
      }
    });
    builder.addCase(onFetchSaleCallPlan.rejected, (state, action) => {
      state.callPlan.network.code = ServiceStatus.failed;
      state.callPlan.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchSaleCallPlanOverview.pending, (state, action) => {
      state.overview.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSaleCallPlanOverview.fulfilled, (state, action) => {
      state.overview.network.code = ServiceStatus.succeeded;
      if (action.payload && action.payload != null) {
        state.overview.data = action.payload;
      } else {
        state.overview.data = undefined;
      }
    });
    builder.addCase(onFetchSaleCallPlanOverview.rejected, (state, action) => {
      state.overview.network.code = ServiceStatus.failed;
      state.overview.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

export const onFetchSaleCallPlan = createAsyncThunk('dashboard/sale/call_plan', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardCallPlan(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchSaleCallPlanOverview = createAsyncThunk('dashboard/sale/call_plan/overview', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getDashboardCallPlanOverview(param);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const { reset, patch } = dashboardCallPlanSlice.actions;

export default dashboardCallPlanSlice.reducer;
