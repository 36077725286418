import { NotificationApi } from './api/notification';
import { plainToClassFromExist } from 'class-transformer';
import { ResponsesModel } from '@/models/classes/responses';
import { NotificationModel, UnreadModel } from '@/models/classes/notification';
import { ResponseModel } from '@/models/classes/response';

const api = new NotificationApi();
export class NotificationService {
  fetchNotification = async () => {
    const resData = await api.fetchNotification();
    const resModel = plainToClassFromExist(new ResponsesModel<NotificationModel>(NotificationModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return { data: resModel.data, total: resModel.total };
    }
    return undefined;
  };

  getNotficaitonReadStatus = async () => {
    const resData = await api.getNotficaitonReadStatus();
    const resModel = plainToClassFromExist(new ResponseModel<UnreadModel>(UnreadModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };

  postUpdateReadStatus = async (params: any = {}) => {
    try {
      const resp = await api.postUpdateReadStatus(params);
      if (resp?.data?.isSuccess !== undefined) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw error;
    }
  };
}
