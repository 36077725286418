import { Expose, Type } from "class-transformer";

export class ReportTabsModel {
  constructor() {
    this.id = 0;
    this.code = "";
    this.fullName = "";
    this.shortName = "";
  }

  @Expose({ name: "dashBoardGroupId" })
  id: number;

  @Expose({ name: "dashBoardGroupCode" })
  code: string;

  @Expose({ name: "dashBoardGroupFullName" })
  fullName: string;

  @Expose({ name: "dashBoardGroupShortName" })
  shortName: string;
}
