import { Exclude, Expose, Type } from "class-transformer";
import { type } from "os";
import { OptionModel } from "../option";

export class ReportModel {
  constructor() {
    this.id = 0;
    this.effect = "";
    this.value = 0;
    this.problem = "";
    this.color = "";
    this.period = "";
    this.date = undefined;
  }

  @Expose({ name: "salesWeeklyReportId" })
  id: number;
  @Expose({ name: "effect" })
  effect: string;
  @Expose({ name: "effectValue" })
  value: number;
  @Expose({ name: "poblem" })
  problem: string;
  @Expose({ name: "priorityColor" })
  color: string;
  @Expose({ name: "salesWeeklyReportDatePeriod" })
  period: string;
  @Expose({ name: "salesWeeklyReportDate" })
  date?: string;
}

export class PriorityModel {
  constructor() {
    this.name = "";
    this.color = "";
  }

  @Expose({ name: "priorityName" })
  name: string;
  @Expose({ name: "Color" })
  color: string;
}

export class SummaryReportModel {
  constructor() {
    this.priority = [];
    this.reports = [];
  }

  @Expose({ name: "listOfPrioritySummary" })
  @Type(() => PriorityModel)
  priority: PriorityModel[];

  @Expose({ name: "listOfSummarySalesWeeklyReport" })
  @Type(() => ReportModel)
  reports: ReportModel[];
}

export class DetailModel {
  constructor() {
    this.id = 0;
    this.action = "";
    this.creator = "";
    this.effect = "";
    this.value = 0;
    this.priority = 0;
    this.problem = "";
    this.problemType = "";
    this.problemId = 0;
    this.solution = "";
    this.status = 0;
    this.support = "";
  }

  @Expose({ name: "salesWeeklyReportId" })
  id: number;

  @Expose({ name: "action" })
  action: string;

  @Expose({ name: "creatorName" })
  creator: string;

  @Expose({ name: "effect" })
  effect: string;

  @Expose({ name: "effectvalue" })
  value: number;

  @Expose({ name: "priorityTypeId" })
  priority: number;

  @Expose({ name: "problem" })
  problem: string;

  @Expose({ name: "problemTypeName" })
  problemType: string;

  @Expose({ name: "problemTypeId" })
  problemId?: number;

  @Expose({ name: "solution" })
  solution: string;

  @Expose({ name: "statusTypeId" })
  status: number;

  @Expose({ name: "supportBy" })
  support: string;
}

export class ReportDetailModel {
  constructor() {
    this.priority = [];
    this.status = [];
    this.buttonFlag = false;
    this.isAcknowledge = false;
    this.detail = undefined;
  }

  @Expose({ name: "prioritytypeOptions" })
  @Type(() => OptionModel)
  priority: OptionModel[];

  @Expose({ name: "statusTypeOptions" })
  @Type(() => OptionModel)
  status: OptionModel[];

  @Expose({ name: "buttonFlag" })
  buttonFlag: boolean;

  @Expose({ name: "isAcknowledge" })
  isAcknowledge: boolean;

  @Expose({ name: "summarySalesWeeklyReport" })
  @Type(() => DetailModel)
  detail?: DetailModel;

  @Expose()
  get statusText() {
    let item = this.status.find((itm: any) => itm.value == this.detail?.status);
    return item?.text ?? "- ยังไม่ระบุ";
  }

  @Expose()
  get priorityText() {
    let item = this.priority.find(
      (itm: any) => itm.value == this.detail?.priority
    );
    return item?.text ?? "- ยังไม่ระบุ";
  }
}

export class MyReportModel {
  constructor() {
    this.isSaveDraft = false;
    this.detail = undefined;
    this.problems = [];
  }

  @Expose({ name: "isSaveDraft" })
  isSaveDraft: boolean;

  @Expose({ name: "mySalesWeeklyReport" })
  @Type(() => DetailModel)
  detail?: DetailModel;

  @Expose({ name: "problemTypeOptions" })
  @Type(() => OptionModel)
  problems: OptionModel[];
}
