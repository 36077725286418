import { mergeObjects } from '@/utils/utils';
import { ThemeType } from '@/models/types/theme';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Chart } from 'chart.js';

// Interface
interface ThemeState {
  theme: ThemeType;
  dark: boolean;
}

const getInitialTheme = () => {
  // Get Theme form TechServ
  const appConfig = window.androidWebview?.appConfig;
  if (appConfig?.theme?.name) {
    return appConfig.theme.name;
  }

  const theme = localStorage.getItem('theme');
  if (theme == 'dark' || theme == 'light') {
    return theme;
  }

  return 'auto';
};

const getInitialDark = () => {
  const appConfig = window.androidWebview?.appConfig;
  if (appConfig?.theme?.name) {
    return appConfig.theme.name == 'dark';
  }
  const theme = localStorage.getItem('theme');
  if (theme == 'dark') {
    return true;
  } else if (theme == 'light') {
    return false;
  } else {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
};

// Initialize State
const initialState: ThemeState = {
  theme: getInitialTheme(),
  dark: getInitialDark(),
};

// Reducer
export const slice = createSlice({
  name: 'Theme',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
    initTheme: (state) => {
      onSetTheme(state.theme);
    },
    setTheme: (state, action: PayloadAction<ThemeType>) => {
      state.theme = action.payload;
      localStorage.setItem('theme', action.payload);
      if (action.payload == 'dark') {
        state.dark = true;
      } else if (action.payload == 'light') {
        state.dark = false;
      } else {
        state.dark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      }
      onSetTheme(action.payload);
    },
  },
  extraReducers: (builder) => {},
});

// Service

// Actions
export const { reset, patch, initTheme, setTheme } = slice.actions;

// Export
export default slice.reducer;

function onSetTheme(theme: ThemeType) {
  const root = document.documentElement;
  if ((theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) || theme === 'dark') {
    Chart.defaults.color = '#FFFFFF';
    root.classList.add('dark');
  } else {
    Chart.defaults.color = '#000000';
    root.classList.remove('dark');
  }
}
