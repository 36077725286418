import { IsNumber, IsString } from "class-validator";
import { Expose, Transform, Type } from "class-transformer";

export class TitleModel {
  constructor() {
    this.name = "";
    this.unit = "";
    this.actual = 0;
    this.total = 0;
  }

  name: string;
  unit: string;
  actual: string|number;
  total: string|number;
}

export class UnitModel {
  constructor() {
    this.unit = "";
  }
  unit: string;
}


export class ItemModel {
  constructor() {
    this.name = "";
    this.prev = new UnitModel;
    this.curr = new UnitModel;
  }
  name: string;
  prev: UnitModel;
  curr: UnitModel;
}

export class DataModel {
  constructor() {
    this.left = new TitleModel;
    this.rigth = new TitleModel;
    this.data = [];
  }
  
  @Type(() => TitleModel)
  @Expose()
  left: TitleModel;
  
  @Type(() => TitleModel)
  @Expose()
  rigth: TitleModel;
  
  @Type(() => DataModel)
  @Expose()
  data: ItemModel[];

}

export class ExpandListThreeColumnModel {
  constructor() {
    this.name = "";
    this.unit = "";
    this.left = new TitleModel;
    this.rigth = new TitleModel;
    this.data = [];
  }

  name: string;
  unit: string;

  @Type(() => TitleModel)
  @Expose()
  left: TitleModel;
  
  @Type(() => TitleModel)
  @Expose()
  rigth: TitleModel;

  @Type(() => DataModel)
  @Expose()
  data: DataModel[];
}