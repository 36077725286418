import { Exclude, Expose, Type } from "class-transformer";

export class CallPlanModel {
  constructor() {
    this.id = 0;
    this.month = 0;
    this.year = 0;
    this.month_name = "";
    this.status = "";
    this.actual = 0;
    this.total = 0;
    this.shopInPlan = 0;
    this.shopInSalesOrg = 0;
  }

  @Expose({ name: "routePlanId" })
  id: number;

  @Expose({ name: "routePlanMonth" })
  month: number;

  @Expose({ name: "routePlanYear" })
  year: number;

  @Expose({ name: "month" })
  month_name: string;

  @Expose({ name: "status" })
  status: string;

  @Expose({ name: "actualPlan" })
  actual: number;

  @Expose({ name: "totalPlan" })
  total: number;

  @Expose({ name: "countOfShopInPlan" })
  shopInPlan: number;

  @Expose({ name: "countOfShopInSalesOrg" })
  shopInSalesOrg: number;
}

export class PlanCalendarModel {
  constructor() {
    this.id = 0;
    this.month = 0;
    this.year = 0;
    this.date = [];
  }

  @Expose({ name: "routePlanId" })
  id: number;

  @Expose({ name: "year" })
  year: number;

  @Expose({ name: "month" })
  month: number;

  @Expose({ name: "plannedDate" })
  date: [];
}

export class PlanCustomersModel {
  constructor() {
    this.id = 0;
    this.order = 0;
    this.name = "";
    this.customerId = 0;
    this.routePlanId = 0;
    this.customerPlanId = 0;
  }

  @Expose({ name: "planID" })
  id: number;

  @Expose({ name: "orderRank" })
  order: number;

  @Expose({ name: "customerName" })
  name: string;

  @Expose({ name: "customerId" })
  customerId: number;

  @Expose({ name: "routePlanId" })
  routePlanId: number;

  @Expose({ name: "customerPlanId" })
  customerPlanId: number;
}

export class OffPlanCustomersModel {
  constructor() {
    this.id = 0;
    this.code = 0;
    this.name = "";
    this.latitude = 0;
    this.longitude = 0;
    this.type = 0;
  }

  @Expose({ name: "customerId" })
  id: number;

  @Expose({ name: "customerCode" })
  code: string | number;

  @Expose({ name: "customerName" })
  name: string;

  @Expose({ name: "latitude" })
  latitude: number;

  @Expose({ name: "longitude" })
  longitude: number;

  @Expose({ name: "type" })
  type: number;
}

export class PlanSummaryModel {
  constructor() {
    this.id = 0;
    this.type = "";
    this.tier = "";
    this.actual = 0;
    this.goal = 0;
    this.status = 0;
  }

  @Expose({ name: "customerTierId" })
  id: number;

  @Expose({ name: "customerTypeName" })
  type: string;

  @Expose({ name: "customerTierName" })
  tier: string;

  @Expose({ name: "actual" })
  actual: number;

  @Expose({ name: "goal" })
  goal: number;

  @Expose({ name: "status" })
  status: number|string;
}

export class PlanDraftModel {
  constructor() {
    this.isSuccess = false;
    this.routePlanId = undefined;
  }

  @Expose({ name: "isSuccess" })
  isSuccess: boolean;

  @Expose({ name: "routePlanId" })
  routePlanId: number | undefined;
}

export class AddListCustomerModel {
  constructor() {
    this.isSuccess = false;
    this.planId = undefined;
    this.routePlanId = undefined;
  }

  @Expose({ name: "isSuccess" })
  isSuccess: boolean;

  @Expose({ name: "planId" })
  planId: number | undefined;

  @Expose({ name: "routePlanId" })
  routePlanId: number | undefined;
}

export class CheckHasChangeRoutePlanModel {
  constructor() {
    this.hasChange = false;
  }

  @Expose({ name: "hasChange" })
  hasChange: boolean;
}

export class CopyPlanModel {
  constructor() {
    this.isSuccess = false;
  }

  @Expose({ name: "isSuccess" })
  isSuccess: boolean;
}

export class SaveDraftModel {
  constructor() {
    this.isSuccess = false;
  }

  @Expose({ name: "isSuccess" })
  isSuccess: boolean;
}

export class SendPlanModel {
  constructor() {
    this.planCode = 0;
    this.result = false;
    this.message = "";
  }

  @Expose({ name: "planCode" })
  planCode: number;

  @Expose({ name: "result" })
  result: boolean;

  @Expose({ name: "message" })
  message: string;
}

export class UpdateListCustomerModel {
  constructor() {
    this.isSuccess = false;
    this.planId = undefined;
    this.routePlanId = undefined;
  }

  @Expose({ name: "isSuccess" })
  isSuccess: boolean;

  @Expose({ name: "planId" })
  planId: number | undefined;

  @Expose({ name: "routePlanId" })
  routePlanId: number | undefined;
}

export class ListCustomerModel {
  constructor() {
    this.planId = 0;
    this.routePlanMonth = 0;
    this.routePlanYear = 0;
    this.lstCustomer = [];
  }

  @Expose({ name: "planId" })
  planId: number;

  @Expose({ name: "routePlanMonth" })
  routePlanMonth: number;

  @Expose({ name: "routePlanYear" })
  routePlanYear: number;

  @Expose({ name: "lstCustomer" })
  @Type(() => PlanCustomersModel)
  lstCustomer: PlanCustomersModel[];
}


export class CustomerTierModel {
  constructor() {
    this.id = 0;
    this.name = "";
    this.actual = 0;
    this.goal = 0;
    this.status = 0;
    this.customerId = 0;
    this.customerName = "";
  }

  @Expose({ name: "customerId" })
  id: number;

  @Expose({ name: "customerName" })
  name: string;

  @Expose({ name: "actual" })
  actual: number;

  @Expose({ name: "goal" })
  goal: number;
  status: number;
  customerId: number;
  customerName: string;

}


export class PlanTierModel {
  constructor() {
    this.withInPlan = [];
    this.withOutPlan = [];
  }

  @Expose({ name: "customerWithInPlan" })
  withInPlan: CustomerTierModel[];

  @Expose({ name: "customerWithoutPlan" })
  withOutPlan: CustomerTierModel[];
}


export class MorningTalkModel {
  constructor() {
    this.name = "";
    this.enable = false;
  }

  @Expose({ name: "menuName" })
  name: string;

  @Expose({ name: "isEnable" })
  enable: boolean;
}



export class ToDoListModel {
  constructor() {
    this.customers = [];
    this.morningTalk = [];
  }

  @Expose({ name: "customerCallPlan" })
  customers: PlanCustomersModel[];

  @Expose({ name: "morningTalk" })
  morningTalk: MorningTalkModel[];
}
