import { Type, Expose } from "class-transformer";
import { IsNumber, IsString } from "class-validator";
import { ToDate } from "../_transform/to_date";
import { OptionModel } from "../option";
import Customer from "./customer";

export class CreatorModel {
  constructor() {
    this.contactId = 0;
    this.fullName = "";
  }

  @Expose({ name: "contactId" })
  contactId: number;

  @Expose({ name: "fullName" })
  fullName: string;
}

export class TargetModel {
  constructor() {
    this.businessReviewTargetId = 0;
    this.visitQ1 = "";
    this.visitQ2 = "";
    this.visitQ3 = "";
    this.visitQ4 = "";
  }

  @Expose({ name: "businessReviewTargetId" })
  businessReviewTargetId: number;

  @Expose({ name: "visitQ1" })
  visitQ1: string;
  @Expose({ name: "visitQ2" })
  visitQ2: string;
  @Expose({ name: "visitQ3" })
  visitQ3: string;
  @Expose({ name: "visitQ4" })
  visitQ4: string;
}

export class ParticipantsModel {
  constructor() {
    this.participantsEmployeeName = "";
    this.participantsPositionName = "";
  }

  @Expose({ name: "participantsEmployeeName" })
  participantsEmployeeName: string;

  @Expose({ name: "participantsPositionName" })
  participantsPositionName: string;
}

export class PlanModel {
  constructor() {
    this.businessReviewPlanId = 0;
    this.lstParticipants = undefined;
  }

  @Expose({ name: "businessReviewPlanId" })
  businessReviewPlanId: number;

  @Type(() => ParticipantsModel)
  @Expose({ name: "lstParticipants" })
  lstParticipants?: ParticipantsModel;
}

export class ActualModel {
  constructor() {
    this.attachFile = "";
    this.businessReviewActualId = 0;
    this.ideaAndInvestment = "";
    this.nextMeetingDate = new Date();
    this.pic1 = "";
    this.pic2 = "";
    this.pic3 = "";
    this.pic4 = "";
    this.salesGrowth = 0;
    this.salesGrowthDetail = "";
    this.lstParticipants = undefined;
  }

  @Expose({ name: "attachFile" })
  attachFile: string;

  @Expose({ name: "businessReviewActualId" })
  businessReviewActualId: number;

  @Expose({ name: "ideaAndInvestment" })
  ideaAndInvestment: string;

  @Expose({ name: "nextMeetingDate" })
  nextMeetingDate: Date;

  @Expose({ name: "pic1" })
  pic1: string;

  @Expose({ name: "pic2" })
  pic2: string;

  @Expose({ name: "pic3" })
  pic3: string;

  @Expose({ name: "pic4" })
  pic4: string;

  @Expose({ name: "salesGrowth" })
  salesGrowth: number;

  @Expose({ name: "salesGrowthDetail" })
  salesGrowthDetail: string;

  @Type(() => ParticipantsModel)
  @Expose({ name: "lstParticipants" })
  lstParticipants?: ParticipantsModel;

  @Expose()
  get images() {
    let imgs = [];
    if (this.pic1) {
      imgs.push(this.pic1);
    }
    if (this.pic2) {
      imgs.push(this.pic2);
    }
    if (this.pic3) {
      imgs.push(this.pic3);
    }
    if (this.pic4) {
      imgs.push(this.pic4);
    }
    return imgs;
  }
}

class BusinessReviewDetail {
  constructor() {
    this.businessReviewCategory = "";
    this.businessReviewConfig = undefined;
    this.businessReviewDate = new Date();
    this.businessReviewId = 0;
    this.categoryId = 0;
    this.creator = undefined;
    this.customer = undefined;
    this.plan = undefined;
    this.actual = undefined;
    this.target = undefined;
  }

  @Expose({ name: "businessReviewCategory" })
  businessReviewCategory: string;

  @Type(() => OptionModel)
  @Expose({ name: "businessReviewConfig" })
  businessReviewConfig?: OptionModel;

  @Expose({ name: "businessReviewDate" })
  businessReviewDate: Date;

  @Expose({ name: "businessReviewId" })
  businessReviewId: number;

  @Expose({ name: "categoryId" })
  categoryId: number;

  @Type(() => CreatorModel)
  @Expose({ name: "creator" })
  creator?: CreatorModel;

  @Type(() => Customer)
  @Expose({ name: "customer" })
  customer?: Customer;

  @Type(() => Customer)
  @Expose({ name: "plan" })
  plan?: Customer;

  @Type(() => ActualModel)
  @Expose({ name: "actual" })
  actual?: ActualModel;

  @Type(() => TargetModel)
  @Expose({ name: "target" })
  target?: TargetModel;
}

export default BusinessReviewDetail;
