import { Exclude, Expose, Type } from "class-transformer";

const AMPMTo24Hr = (time: any) => {
  let hours = Number(time.match(/^(\d+)/)[1]);
  let minutes = Number(time!.match(/:(\d+)/)[1]);
  let AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM == "PM" && hours < 12) hours = hours + 12;
  if (AMPM == "AM" && hours == 12) hours = hours - 12;
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) sHours = "0" + sHours;
  if (minutes < 10) sMinutes = "0" + sMinutes;
  return sHours + ":" + sMinutes;
};

export class MorningTalkModel {
  constructor() {
    this.id = 0;
    this.room = "";
    this.startMeetingDate = "";
    this.endMeetingDate = "";
    this.startTime = "";
    this.endTime = "";
    this.meetingDate = undefined;
    this.url = "";
    this.managerName = "";
    this.positionName = "";
    this.attendee = 0;
    this.invite = 0;
    this.absentee = 0;
    this.color = "";
  }

  @Expose({ name: "morningTalkId" })
  id: number;
  @Expose({ name: "roomName" })
  room: string;
  @Expose({ name: "startMeetingDate" })
  startMeetingDate: string;
  @Expose({ name: "endMeetingDate" })
  endMeetingDate: string;
  @Expose({ name: "startMeetingTime" })
  startTime: string;
  @Expose({ name: "endMeetingTime" })
  endTime: string;
  @Expose({ name: "historyMeetingDate" })
  meetingDate: string | undefined;
  @Expose({ name: "meetingUrl" })
  url: string;
  @Expose({ name: "regionManagerName" })
  managerName: string;
  @Expose({ name: "positionName" })
  positionName: string;
  @Expose({ name: "cntOfAttendee" })
  attendee: number;
  @Expose({ name: "cntOfInvite" })
  invite: number;
  @Expose({ name: "cntOfAbsentee" })
  absentee: number;
  @Expose({ name: "colorStatus" })
  color: string;

  @Expose()
  get startTime24() {
    return AMPMTo24Hr(this.startTime);
  }

  @Expose()
  get endTime24() {
    return AMPMTo24Hr(this.endTime);
  }

  @Expose()
  get startDate() {
    return new Date(this.startMeetingDate).toLocaleDateString("en-GB");
  }

  @Expose()
  get endDate() {
    return new Date(this.endMeetingDate).toLocaleDateString("en-GB");
  }
}

export class MemberModel {
  constructor() {
    this.id = 0;
    this.name = "";
  }

  @Expose({ name: "inviteetId" })
  id: number;
  @Expose({ name: "inviteeName" })
  name: string;
}

export class MorningTalkDetailModel {
  constructor() {
    this.id = 0;
    this.room = "";
    this.currentMeetingDate = "";
    this.startMeetingDate = "";
    this.endMeetingDate = "";
    this.startTime = "";
    this.endTime = "";
    this.meetingDate = undefined;
    this.url = "";
    this.managerName = "";
    this.positionName = "";
    this.attendee = "";
    this.invite = "";
    this.absentee = "";
    this.color = "";
    this.problem = "";
    this.target = "";
    this.subject = "";
    this.closeTime = "";
    this.rate = 0;
    this.attendeeList = [];
    this.absenteeList = [];
  }

  @Expose({ name: "morningTalkId" })
  id: number;
  @Expose({ name: "roomName" })
  room: string;
  @Expose({ name: "startMeetingDate" })
  startMeetingDate: string;
  @Expose({ name: "currentMeetingDate" })
  currentMeetingDate: string;
  @Expose({ name: "endMeetingDate" })
  endMeetingDate: string;
  @Expose({ name: "startMeetingTime" })
  startTime: string;
  @Expose({ name: "endMeetingTime" })
  endTime: string;
  
  @Expose({ name: "historyMeetingDate" })
  meetingDate: string | undefined;

  @Expose({ name: "meetingUrl" })
  url: string;
  @Expose({ name: "regionManagerName" })
  managerName: string;
  @Expose({ name: "positionName" })
  positionName: string;
  @Expose({ name: "cntOfAttendee" })
  attendee: string;
  @Expose({ name: "cntOfInvite" })
  invite: string;
  @Expose({ name: "cntOfAbsentee" })
  absentee: string;
  @Expose({ name: "colorStatus" })
  color: string;
  @Expose({ name: "problem" })
  problem: string;
  @Expose({ name: "target" })
  target: string;
  @Expose({ name: "subject" })
  subject: string;
  @Expose({ name: "closeTime" })
  closeTime: string;
  @Expose({ name: "rate" })
  rate: number;

  @Expose({ name: "attendeeList" })
  attendeeList: MemberModel[];
  @Expose({ name: "absenteeList" })
  absenteeList: MemberModel[];

  @Expose()
  get startTime24() {
    return AMPMTo24Hr(this.startTime);
  }

  @Expose()
  get endTime24() {
    return AMPMTo24Hr(this.endTime);
  }

  @Expose()
  get startDate() {
    return new Date(this.startMeetingDate).toLocaleDateString("en-GB");
  }

  @Expose()
  get endDate() {
    return new Date(this.endMeetingDate).toLocaleDateString("en-GB");
  }
}
