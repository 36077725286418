import { Stores } from '@/components/_models/enums/stores';
import { OfflineServices } from '@/utils/offline';

const offline = new OfflineServices();

export class OfflineLocation {
  //logic
  async getProvince() {
    let province = await offline.get(Stores.Provinces, 'province');
    let res = new Array();
    province.map((item: any) => {
      res.push({ optionText: item.provinceName, optionValue: item.provinceId });
    });

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: res,
      error: null,
      xTotalCount: res.length,
    };

    console.log('result', result);
    return result;
  }

  async getDistrict(provinceCode: string | number) {
    let district = await offline.get(Stores.Districts, 'district');
    let res = new Array();
    district.map((item: any) => {
      if (item.provinceId == provinceCode) {
        res.push({
          optionText: item.districtName,
          optionValue: item.districtId,
        });
      }
    });

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: res,
      error: null,
      xTotalCount: res.length,
    };

    console.log('result', result);
    return result;
  }

  async getSubDistrict(districtCode: string | number) {
    let subDistrict = await offline.get(Stores.SubDistrict, 'subDistrict');
    let res = new Array();
    subDistrict.map((item: any) => {
      if (item.districtId == districtCode) {
        res.push({
          optionText: item.subDistrictName,
          optionValue: item.subDistrictId,
        });
      }
    });

    let result = {
      result: true,
      responseCode: '200',
      responseMessage: 'Success',
      responseDataSource: 'offline',
      isCalled: false,
      data: res,
      error: null,
      xTotalCount: res.length,
    };

    console.log('result', result);
    return result;
  }
}
