import { plainToClassFromExist } from 'class-transformer';
import { ReportTabsModel } from '@/models/classes/report/tabs';
import { ResponsesModel } from '@/models/classes/responses';
import { ReportApi } from '../api/report';

const api = new ReportApi();

export class ReportService {
  fetchListOfDashboard = async (saleOrgCriteriaId: number) => {
    const resData = await api.fetchListOfDashboard(saleOrgCriteriaId);
    const resModel = plainToClassFromExist(new ResponsesModel<ReportTabsModel>(ReportTabsModel), resData);
    if (resModel.status == 200 && typeof resModel.data == 'object') {
      return resModel.data;
    }
    return undefined;
  };
}
