import { forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  children?: ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error' | 'neutral';
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

const FilledButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, type = 'button', color = '', className = '', fullWidth = true, onClick = () => {}, disabled = false, loading = false, ...rest } = props;
  return (
    <button
      //
      {...rest}
      ref={ref}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
      className={twMerge('button relative filled', className, color, fullWidth ? 'w-full' : '!px-16')}>
      {/* {loading && <span className="absolute left-4 h-6 w-6 border-t-transparent border-solid animate-spin rounded-full" />} */}
      {children}
    </button>
  );
});

export default FilledButton;
