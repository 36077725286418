import { Expose, Type } from "class-transformer";

export class OffPlanCustomerModel {
  constructor() {
    this.id = 0;
    this.code = "";
    this.name = "";
    this.latitude = 0;
    this.longitude = 0;
    this.type = 0;
  }

  @Expose({ name: "customerId" })
  id: number;

  @Expose({ name: "customerCode" })
  code: string;

  @Expose({ name: "customerName" })
  name: string;

  @Expose({ name: "latitude" })
  latitude: number;

  @Expose({ name: "longitude" })
  longitude: number;

  @Expose({ name: "type" })
  type: number;
}