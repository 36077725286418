import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
const service = new Service.ReportDashboardMskService();

// Interface
interface MSKDashoboardState {
  purchasePercent: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
}

// Initialize State
const initialState: MSKDashoboardState = {
  purchasePercent: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const dashboardMSKSlice = createSlice({
  name: 'dashboard_msk',
  initialState,
  //basic state change
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    // Fetch MSK
    builder.addCase(onFetchGetMSKSubAgentPurchasePercent.pending, (state, action) => {
      state.purchasePercent.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetMSKSubAgentPurchasePercent.rejected, (state, action) => {
      state.purchasePercent.network.error = action.error.message;
      state.purchasePercent.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchGetMSKSubAgentPurchasePercent.fulfilled, (state, action) => {
      // state.stock.data = action.payload;
      if (action.payload && action.payload != null) {
        state.purchasePercent.data = action.payload;
      } else {
        state.purchasePercent.data = undefined;
      }
      state.purchasePercent.network.code = ServiceStatus.succeeded;
    });
  },
});

export const onFetchGetMSKSubAgentPurchasePercent = createAsyncThunk('dashboard/msk/purchase_percent', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getMSKSubAgentPurchasePercent(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const { reset, patch } = dashboardMSKSlice.actions;

export default dashboardMSKSlice.reducer;
