import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import Service from '@/services';
import { mergeObjects } from '@/utils/utils';

const service = new Service.ManagementService();

// Interface
interface ManagementDetailState {
  detail: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  h2h: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
    legend?: any;
  };
}

// Initialize State
const initialState: ManagementDetailState = {
  detail: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  h2h: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    legend: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'ManagementDetail',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(onFetchGetCustomer.pending, (state) => {
      state.detail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetCustomer.fulfilled, (state, action) => {
      state.detail.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.detail.data = action.payload.data;
      }
    });
    builder.addCase(onFetchGetCustomer.rejected, (state, action) => {
      state.detail.network.code = ServiceStatus.failed;
      state.detail.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchGetH2H.pending, (state) => {
      state.h2h.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchGetH2H.fulfilled, (state, action) => {
      state.h2h.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.h2h.data = action.payload.data;
        state.h2h.legend = action.payload.legend;
      }
    });
    builder.addCase(onFetchGetH2H.rejected, (state, action) => {
      state.h2h.network.code = ServiceStatus.failed;
      state.h2h.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchGetCustomer = createAsyncThunk('management/detail/detail', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetCustomer(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetH2H = createAsyncThunk('management/detail/h2h', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.fetchGetH2H(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { patch, reset } = slice.actions;

// Export
export default slice.reducer;
