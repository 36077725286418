import { LEAGUE_TYPE } from '@/stores/slices/league';
import { LeagueAPI } from './api/league';
const leagueApi = new LeagueAPI();
interface ParamsType {
  year: string | number;
  month: string | number;
  viewMode: LEAGUE_TYPE;
}

export const getIncensive = async (params: ParamsType) => {
  const resp = await leagueApi.getIncensive(params);
  const data = resp.data;
  const yearOptions = data.lstYears.map((x: any) => {
    return { label: x, value: x };
  });
  const monthOptions = data.lstMonths.map((x: any) => {
    return { label: x.monthName, value: x.monthId };
  });
  const typeOptions = data.lstTypeFilters.map((x: any) => {
    return { label: x.usageInfoName, value: x.usageInfoCode };
  });
  //label value
  return { ...data, yearOptions, monthOptions, typeOptions };
};
