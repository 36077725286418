import axios, { AxiosRequestConfig } from 'axios';

export class ReportApi {
  async fetchListOfDashboard(saleOrgCriteriaId: number) {
    let path = `/api/Dashboard/ListOfDashboardGroupBySaleOrgCriteriaId?saleOrgCriteriaId=${saleOrgCriteriaId}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }
}
