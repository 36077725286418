import axios from 'axios';
import * as qs from 'qs';

//Services
export class ReportDashboardMskApi {
  async getMSKSubAgentPurchasePercent(params: any = {}) {
    let path = `/api/DashBoard/GetMSKSubAgentPurchasePercent?${qs.stringify(params)}`;
    const config = {
      method: 'get',
      url: path,
      headers: {
        ByPassAuth: true,
      },
    };
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios(config);
        if (res) {
          return res.data;
        } else {
          return undefined;
        }
      }
    } catch (err) {
      return undefined;
    }
  }
}
