import { ServiceStatus } from '@/models/enums/service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { ExpandListThreeColumnModel } from '@/models/classes/report/expand_list_three_column';
import { ExpandListTwoColumnModel } from '@/models/classes/report/expand_list_two_column';
import { AssitantPerformanceModel } from '@/models/classes/overview/assitant_performance';
const service = new Service.ReportDashboardAssistantService();

// Interface
interface ReportAssistantState {
  performance: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: AssitantPerformanceModel;
  };
  stock: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ExpandListTwoColumnModel;
  };
  purchase: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ExpandListTwoColumnModel;
  };
  sellOut: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: ExpandListThreeColumnModel;
  };
}

// Initialize State
const initialState: ReportAssistantState = {
  performance: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  stock: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  purchase: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  sellOut: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const dashboardAssistantSlice = createSlice({
  name: 'dashboard_assistant',
  initialState,
  //basic state change
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    // Fetch Performance
    builder.addCase(onFetchAssistantSalePerformance.pending, (state, action) => {
      state.performance.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchAssistantSalePerformance.rejected, (state, action) => {
      state.performance.network.error = action.error.message;
      state.performance.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchAssistantSalePerformance.fulfilled, (state, action) => {
      // state.performance.data = action.payload;
      if (action.payload && action.payload != null) {
        state.performance.data = action.payload;
      } else {
        state.performance.data = undefined;
      }
      state.performance.network.code = ServiceStatus.succeeded;
    });

    // Fetch Stock
    builder.addCase(onFetchAssistantStock.pending, (state, action) => {
      state.stock.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchAssistantStock.rejected, (state, action) => {
      state.stock.network.error = action.error.message;
      state.stock.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchAssistantStock.fulfilled, (state, action) => {
      // state.stock.data = action.payload;
      if (action.payload && action.payload != null) {
        state.stock.data = action.payload;
      } else {
        state.stock.data = undefined;
      }
      state.stock.network.code = ServiceStatus.succeeded;
    });

    // Fetch Purchase
    builder.addCase(onFetchAssistantPurchase.pending, (state, action) => {
      state.purchase.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchAssistantPurchase.rejected, (state, action) => {
      state.purchase.network.error = action.error.message;
      state.purchase.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchAssistantPurchase.fulfilled, (state, action) => {
      // state.purchase.data = action.payload;
      if (action.payload && action.payload != null) {
        state.purchase.data = action.payload;
      } else {
        state.purchase.data = undefined;
      }
      state.purchase.network.code = ServiceStatus.succeeded;
    });

    // Fetch Sell Out
    builder.addCase(onFetchAssistantSellOut.pending, (state, action) => {
      state.sellOut.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchAssistantSellOut.rejected, (state, action) => {
      state.sellOut.network.error = action.error.message;
      state.sellOut.network.code = ServiceStatus.failed;
    });
    builder.addCase(onFetchAssistantSellOut.fulfilled, (state, action) => {
      // state.sellOut.data = action.payload;
      if (action.payload && action.payload != null) {
        state.sellOut.data = action.payload;
      } else {
        state.sellOut.data = undefined;
      }
      state.sellOut.network.code = ServiceStatus.succeeded;
    });
  },
});

export const onFetchAssistantSalePerformance = createAsyncThunk('dashboard/assistant/sale_performance', async (param: any = {}, thunkAPI) => {
  try {
    // const apiData = await service.getSalePerformance(
    //   param?.init?.type,
    //   param?.init?.searchId,
    //   param?.init?.regionId
    // );
    const apiData = await service.getAssistantSalePerformance(param?.filter?.type, param?.filter?.searchId, param?.unit);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchAssistantStock = createAsyncThunk('dashboard/assistant/stock', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getAssistantDashBoardStock(param?.filter?.type, param?.filter?.searchId, param?.filter?.period, param?.unit);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchAssistantPurchase = createAsyncThunk('dashboard/assistant/purchase', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getAssistantDashBoardPurchase(param?.filter?.type, param?.filter?.searchId, param?.unit);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchAssistantSellOut = createAsyncThunk('dashboard/assistant/sellOut', async (param: any = {}, thunkAPI) => {
  try {
    const apiData = await service.getAssistantDashboardSellOut(param?.filter?.type, param?.filter?.searchId, param?.unit);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const { reset, patch } = dashboardAssistantSlice.actions;

export default dashboardAssistantSlice.reducer;
