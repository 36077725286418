import axios from 'axios';
import qs from 'qs';

export class ManagementApi {
  async getFilter(params: any = {}) {
    let path = `/api/ManagementView/GetListCustomerManagementFilter?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getCustomer(params: any = {}) {
    let path = `/api/ManagementView/ListOfCustomerEvent?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getSearchCustomer(params: any = {}) {
    let path = `/api/ManagementView/SearchCustomerEvent?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getActivityHistory(params: any = {}) {
    let path = `/api/ManagementView/GetHistoryActivity?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getActivityNext(params: any = {}) {
    let path = `/api/ManagementView/GetNextActivity?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getEActivity(params: any = {}) {
    let path = `/api/ManagementView/ListCustomerInEactivity?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getDetailCustomer(params: any = {}) {
    let path = `/api/ManagementView/GetCustomer?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getDetailH2H(params: any = {}) {
    let path = `/api/ManagementView/GetCustomerDetailH2H?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }

  async getViewSaleManagerByCustomer(params: any = {}) {
    let path = `/api/ManagementView/ViewSaleManagerByCustomer?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const response = await axios.get(path);
        return response.data;
      }
    } catch (error) {
      console.error(`Error fetch overview`, error);
      throw error;
    }
  }
}
