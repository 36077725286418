import { Transform } from 'class-transformer';

export function NumberTransform(options: { toClassOnly?: boolean; toPlainOnly?: boolean } = {}) {
  return Transform((value: any) => {
    if (options.toClassOnly) {
      return Number(value.value);
    } else if (options.toPlainOnly) {
      return value.value + '';
    }
    return value;
  }, options);
}
