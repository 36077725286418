import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { NotificationModel } from '@/models/classes/notification';
import { SearchNotifyModel, HistoryNotifyModel, NotifyOptionsModel, NotifyDraftModel } from '@/models/classes/communication';
import { StatusOptionModel, TypeOptionModel } from '@/models/classes/communication/option';
import { SuccessModel } from '@/models/classes/success';

const service = new Service.CommunicationService();

// Interface
interface CommunicationState {
  notify: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SearchNotifyModel;
    options: TypeOptionModel[];
    total: number;
  };
  history: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: HistoryNotifyModel;
    options: StatusOptionModel[];
    total: number;
  };
  form: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    options?: NotifyOptionsModel;
    submit?: NotifyDraftModel;
  };
  detail: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: NotificationModel;
  };
  action: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: SuccessModel;
  };
}

// Initialize State
const initialState: CommunicationState = {
  notify: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    options: [],
    total: 0,
  },
  history: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    options: [],
    total: 0,
  },
  form: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    options: undefined,
    submit: undefined,
  },
  detail: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  action: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'Communication',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(onFetchSearchNotify.pending, (state) => {
      state.notify.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchSearchNotify.fulfilled, (state, action) => {
      state.notify.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.notify.data = action.payload.data;
        state.notify.total = action.payload.total;
        if (state.notify.options.length == 0 && action.payload.data.notifyType.length > 0) {
          state.notify.options = action.payload.data.notifyType;
        }
      }
    });
    builder.addCase(onFetchSearchNotify.rejected, (state, action) => {
      state.notify.network.code = ServiceStatus.failed;
      state.notify.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Load More
    builder.addCase(onLoadMoreSearchNotify.pending, (state) => {});
    builder.addCase(onLoadMoreSearchNotify.fulfilled, (state, action) => {
      if (action.payload != null && state.notify.data) {
        state.notify.data.notify.push(...action.payload.data.notify);
        state.notify.total = action.payload.total;
      }
    });
    builder.addCase(onLoadMoreSearchNotify.rejected, (state, action) => {});

    // Fetch
    builder.addCase(onfetchSearchHistory.pending, (state) => {
      state.history.network.code = ServiceStatus.loading;
    });
    builder.addCase(onfetchSearchHistory.fulfilled, (state, action) => {
      state.history.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.history.data = action.payload.data;
        state.history.total = action.payload.total;
        if (state.history.options.length == 0 && action.payload.data.notifyStatus.length > 0) {
          state.history.options = action.payload.data.notifyStatus;
        }
      }
    });
    builder.addCase(onfetchSearchHistory.rejected, (state, action) => {
      state.history.network.code = ServiceStatus.failed;
      state.history.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onLoadMoreSearchHistory.pending, (state) => {});
    builder.addCase(onLoadMoreSearchHistory.fulfilled, (state, action) => {
      if (action.payload != null && state.history.data) {
        state.history.data.notify.push(...action.payload.data.notify);
        state.history.total = action.payload.total;
      }
    });
    builder.addCase(onLoadMoreSearchHistory.rejected, (state, action) => {});

    // Fetch
    builder.addCase(onGetNotify.pending, (state) => {
      state.form.network.code = ServiceStatus.loading;
    });
    builder.addCase(onGetNotify.fulfilled, (state, action) => {
      state.form.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.form.options = action.payload;
      }
    });
    builder.addCase(onGetNotify.rejected, (state, action) => {
      state.form.network.code = ServiceStatus.failed;
      state.form.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    // Fetch
    builder.addCase(onSaveDraft.pending, (state) => {
      state.history.network.code = ServiceStatus.loading;
    });
    builder.addCase(onSaveDraft.fulfilled, (state, action) => {
      state.history.network.code = ServiceStatus.succeeded;
      // if (action.payload != null) {
      //   state.form.submit = action.payload;
      // }
    });
    builder.addCase(onSaveDraft.rejected, (state, action) => {
      state.history.network.code = ServiceStatus.failed;
      state.history.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchViewNotify.pending, (state) => {
      state.detail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchViewNotify.fulfilled, (state, action) => {
      state.detail.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.detail.data = action.payload;
      }
    });
    builder.addCase(onFetchViewNotify.rejected, (state, action) => {
      state.detail.network.code = ServiceStatus.failed;
      state.detail.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onFetchViewHistoryNotify.pending, (state) => {
      state.detail.network.code = ServiceStatus.loading;
    });
    builder.addCase(onFetchViewHistoryNotify.fulfilled, (state, action) => {
      state.detail.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.detail.data = action.payload;
      }
    });
    builder.addCase(onFetchViewHistoryNotify.rejected, (state, action) => {
      state.detail.network.code = ServiceStatus.failed;
      state.detail.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onRestore.pending, (state) => {
      state.action.network.code = ServiceStatus.loading;
    });
    builder.addCase(onRestore.fulfilled, (state, action) => {
      state.action.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.action.data = action.payload;
      }
    });
    builder.addCase(onRestore.rejected, (state, action) => {
      state.action.network.code = ServiceStatus.failed;
      state.action.network.error = (action.payload as string) || 'Failed to fetch data';
    });

    builder.addCase(onSend.pending, (state) => {
      state.action.network.code = ServiceStatus.loading;
    });
    builder.addCase(onSend.fulfilled, (state, action) => {
      state.action.network.code = ServiceStatus.succeeded;
      if (action.payload != null && action.payload) {
        state.action.data = action.payload;
      }
    });
    builder.addCase(onSend.rejected, (state, action) => {
      state.action.network.code = ServiceStatus.failed;
      state.action.network.error = (action.payload as string) || 'Failed to fetch data';
    });
  },
});

// Service
export const onFetchSearchNotify = createAsyncThunk('notification/fetch/search', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchSearch(param.notifyType, param.limit, param.offset);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onLoadMoreSearchNotify = createAsyncThunk('notification/loadmore/search', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchSearch(param.notifyType, param.limit, param.offset);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onfetchSearchHistory = createAsyncThunk('notification/fetch/history', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchHistory(param.searchText, param.notifyStatus, param.limit, param.offset);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onLoadMoreSearchHistory = createAsyncThunk('notification/loadmore/history', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.fetchHistory(param.searchText, param.notifyStatus, param.limit, param.offset);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onGetNotify = createAsyncThunk('notification/get/notify', async (param: any, thunkAPI) => {
  try {
    const apiData = await service.getNotify();
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onSaveDraft = createAsyncThunk('notification/save/draft', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.saveDraftNotify(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchViewNotify = createAsyncThunk('notification/view/notify', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.viewNotify(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchViewHistoryNotify = createAsyncThunk('notification/view/history/notify', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.viewHistoryNotify(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onRestore = createAsyncThunk('notification/restore', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.restore(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onSend = createAsyncThunk('notification/send', async (params: any = {}, thunkAPI) => {
  try {
    const apiData = await service.send(params);
    return apiData;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
