import axios from 'axios';
import { OfflineCustomer } from '@/services/offline/customer';
import { OfflineSurvey } from '@/services/offline/survey';
import { OfflineVisit } from '@/services/offline/visit';

const customer = new OfflineCustomer();
const survey = new OfflineSurvey();
const visit = new OfflineVisit();

export class VisitApi {
  async getVisitCustomer(params: any = {}) {
    let path = `/api/CallPlan/GetVisitCustomer?customerPlanId=${params.customerPlanId}`;
    if (!isNaN(params?.lat) && !isNaN(params?.lng)) {
      path += `&lat=${params?.lat}&lng=${params?.lng}`
    }
    try {
      if (window.isOffline) {
        let data = await customer.getCustomerDetailOnPlan(params.customerPlanId);
        if (data) {
          console.log('window.isOffline customer', data);
          return data;
        } else {
          return null;
        }
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getVisitCustomerOffPlan(params: any = {}) {
    let path = `/api/CallPlan/GetVisitCustomerOffPlan?customerId=${params.customerId}&lat=${params?.lat}&lng=${params?.lng}`;
    // if (!isNaN(params?.lat) && !isNaN(params?.lng)) {
    //   path += `&lat=${params?.lat}&lng=${params?.lng}`
    // }
    try {
      if (window.isOffline) {
        return await customer.getCustomerDetail(params.customerId);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getEmployeePreVisit(params: any) {
    let path = `/api/CallPlan/GetEmployeePreVisit?customerId=${params.customerId}`;
    try {
      if (window.isOffline) {
        return await visit.findPreVisit(params.customerId);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getOutRangeVisitReason(params: any) {
    let path = `/api/CallPlan/OutRangeVisitReason?channelId=${params.channelId}`;
    try {
      if (window.isOffline) {
        // return await visit.findoutRangeVisit(params.customerId);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async getListSurvey(params: any) {
    let config = {
      method: 'get',
      url: `/api/Survey/GetListSurvey?customerId=${params.customerId}`,
    };

    try {
      if (window.isOffline) {
        return await survey.fetchSurvey(params.customerId);
      } else {
        const res = await axios(config);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postAddEmployeePreVisit(payload: any) {
    try {
      if (window.isOffline) {
        return await visit.storePrevisit(payload);
      } else {
        const url = `/api/CallPlan/AddEmployeePreVisit`;
        const res = await axios.post(url, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postUpdateEmployeePreVisit(payload: any) {
    try {
      if (window.isOffline) {
        return await visit.updatePrevisit(payload);
      } else {
        const url = `/api/CallPlan/UpdateEmployeePreVisit`;
        const res = await axios.post(url, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postCheckInEmployeeVisit(payload: any) {
    try {
      if (window.isOffline) {
        return await visit.storeCheckIn(payload, true);
      } else {
        const url = `/api/CallPlan/CheckInEmployeeVisit`;
        const res = await axios.post(url, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postCheckInEmployeeVisitOffPlan(payload: any) {
    try {
      if (window.isOffline) {
        return await visit.storeCheckIn(payload, false);
      } else {
        const url = `/api/CallPlan/CheckInOffPlan`;
        const res = await axios.post(url, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postCheckOutEmployeeVisit(payload: any) {
    try {
      if (window.isOffline) {
        return await visit.storeCheckOut(payload, true);
      } else {
        const url = `/api/CallPlan/CheckOutEmployeeVisit`;
        const res = await axios.post(url, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

  async postSurveyManagement(payload: any) {
    try {
      if (window.isOffline) {
        let store = await survey.storeSurveyLog(payload);
        return store;
      } else {
        const url = `/api/Survey/GetSurveyManagement`;
        const res = await axios.post(url, payload);
        return res.data;
      }
    } catch (error) {
      throw error;
    }
  }

}
