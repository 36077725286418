import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';

import Service from '@/services';
import { OverviewModel } from '@/models/classes/overview';
import { TodayActivityModel } from '@/models/classes/overview/today_activity';
import { TodayEActivityModel } from '@/models/classes/overview/today_e_activity';

const service = new Service.OverviewService();

// Interface
interface OverviewState {
  fetch: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: OverviewModel;
  };
  todayActivity: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: TodayActivityModel;
  };
  todayEActivity: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: TodayEActivityModel;
  };
}

// Initialize State
const initialState: OverviewState = {
  fetch: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  todayActivity: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  todayEActivity: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
};

// Reducer
export const slice = createSlice({
  name: 'Overview',
  initialState,
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  extraReducers: (builder) => {
    // Fetch User
    builder.addCase(fetchOverview.pending, (state) => {
      if (!state.fetch.data) {
        state.fetch.network.code = ServiceStatus.loading;
      }
    });
    builder.addCase(fetchOverview.fulfilled, (state, action) => {
      state.fetch.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.fetch.data = action.payload;
      }
    });
    builder.addCase(fetchOverview.rejected, (state, action) => {
      if (!state.fetch.data) {
        state.fetch.network.code = ServiceStatus.failed;
        state.fetch.network.error = (action.payload as string) || 'Failed to fetch data';
      }
    });

    builder.addCase(onFetchGetTodayActivity.pending, (state) => {
      if (!state.todayActivity.data) {
        state.todayActivity.network.code = ServiceStatus.loading;
      }
    });
    builder.addCase(onFetchGetTodayActivity.fulfilled, (state, action) => {
      state.todayActivity.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.todayActivity.data = action.payload;
      }
    });
    builder.addCase(onFetchGetTodayActivity.rejected, (state, action) => {
      if (!state.fetch.data) {
        state.todayActivity.network.code = ServiceStatus.failed;
        state.todayActivity.network.error = (action.payload as string) || 'Failed to fetch data';
      }
    });

    builder.addCase(onFetchGetTodayEActivity.pending, (state) => {
      if (!state.todayEActivity.data) {
        state.todayEActivity.network.code = ServiceStatus.loading;
      }
    });
    builder.addCase(onFetchGetTodayEActivity.fulfilled, (state, action) => {
      state.todayEActivity.network.code = ServiceStatus.succeeded;
      if (action.payload != null) {
        state.todayEActivity.data = action.payload;
      }
    });
    builder.addCase(onFetchGetTodayEActivity.rejected, (state, action) => {
      if (!state.fetch.data) {
        state.todayEActivity.network.code = ServiceStatus.failed;
        state.todayEActivity.network.error = (action.payload as string) || 'Failed to fetch data';
      }
    });
  },
});

// Service
export const fetchOverview = createAsyncThunk('overview/fetch', async (_, thunkAPI) => {
  try {
    const res = await service.fetchOverview();
    return res;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetTodayActivity = createAsyncThunk('overview/fetch/todayActivty', async (_, thunkAPI) => {
  try {
    const res = await service.fetchGetTodayActivity();
    return res;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

export const onFetchGetTodayEActivity = createAsyncThunk('overview/fetch/todayEActivty', async (_, thunkAPI) => {
  try {
    const res = await service.fetchGetTodayEActivity();
    return res;
  } catch (error: any) {
    // Handle any errors and return an error action
    return thunkAPI.rejectWithValue(error.response.data.message);
  }
});

// Actions
export const { reset, patch } = slice.actions;

// Export
export default slice.reducer;
