import { Expose, Type } from 'class-transformer';
import { ChannelModel } from './channel';
import { CompanyModel } from './company';
import { SalesModel } from './sales';

export class OrganizeModel {
  constructor() {
    this.isShowPopup = false;
    this.channels = [];
    this.companies = [];
    this.sales = [];
  }

  @Expose({ name: 'ShowPopUp' })
  isShowPopup: boolean;

  @Expose({ name: 'channelOptions' })
  @Type(() => ChannelModel)
  channels: ChannelModel[];

  @Expose({ name: 'companyOptions' })
  @Type(() => CompanyModel)
  companies: CompanyModel[];

  @Expose({ name: 'saleOrgOptions' })
  @Type(() => SalesModel)
  sales: SalesModel[];
}
